/* Produits dans les panels */
.panel-cart-prices-line {
  padding: 0.6rem 0;

  .price {
    text-align: right;
  }
}

.panel-price {
  color: $red;
  display: block;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  text-align: right;
}

.panel-product-additionnal,
.panel-product {
  background: $white;
  border-top: 0.1rem solid $grey-light;
  padding: 2rem 1.5rem;

  @media (min-width: $md-width) {
    padding: 2rem;
  }

  .panel-product-img-ctn-add,
  .panel-product-img-ctn {
    order: 2;
    padding: 0;
    position: relative;
    width: 7.5rem;

    @media (min-width: $md-width) {
      order: 1;
      padding: 0 1.5rem 0 0;
    }
  }

  .panel-product-infos-add,
  .panel-product-infos {
    color: $blue-dark;
    flex-basis: 0;
    flex-grow: 1.8;
    margin-top: 0.2rem;
    max-width: 100%;
    order: 1;
    padding: 0 1.5rem 0 0;

    @media (min-width: $md-width) {
      order: 2;
      padding: 0 5% 0 0;
    }
  }

  .panel-product-name-add,
  .panel-product-name {
    color: inherit;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.1;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  .panel-product-attributes-add,
  .panel-product-attributes {
    font-size: 1.3rem;
    margin-top: 0.5rem;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
    }
  }

  .panel-product-topfeatures-add,
  .panel-product-topfeatures {
    margin-top: 1.2rem;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  }

  .panel-product-prices-add,
  .panel-product-prices {
    background: $grey-lighter;
    border-radius: 0.2rem;
    flex-basis: 100%;
    order: 4;
    padding: 1.7rem 5rem 1.7rem 5rem;
    padding-right: 1.5rem;
    position: relative;
    text-align: right;

    @media (min-width: $md-width) {
      background: transparent;
      flex-basis: 0;
      flex-grow: 1;
      order: 3;
      padding: 0 1.5rem 0 0;
    }

    .panel-product-current-price-add,
    .panel-product-current-price {
      float: right;
      margin-left: 1.5rem;
      margin-right: 6.5rem;
      @media (min-width: $md-width) {
        float: none;
      }
    }

    &.ope30-active {
      .panel-product-current-price {
        margin-right: 0rem;
      }
    }

    .panel-product-old-price-add,
    .panel-product-old-price {
      color: $grey-medium;
      display: inline-block;
      font-size: 1.3rem;
      margin-top: 0.5rem;

      @media (min-width: $md-width) {
        display: block;
      }
    }

    .panel-product-ope30-add,
    .panel-product-ope30 {
      display: none;
      float: right;
      width: 100%;

      &-txt {
        font-size: 1.2rem;
        text-align: right;
      }

      &-value {
        color: $yellow;
        font-size: 1.8rem;
        font-weight: 900;
      }
    }

    &.ope30-active {
      color: $white;
      margin-top: 1rem;
      padding: 1.4rem 1.5rem;
      text-align: right;

      > * {
        position: relative;
        z-index: 100;
      }

      .panel-product-current-price {
        //color: $blue-dark;
        color: $white;
      }

      .panel-product-ope30 {
        margin-top: 1rem;
        position: initial;
        z-index: 1;

        img {
          background: none;
          border: none;
          left: 1px;
          max-height: 40px;
          position: absolute;
          top: 1px;
          z-index: 1;
        }
      }
    }

    .panel-product-remove-ctn {
      @include poa(1.9rem, 1.5rem);
      color: $grey-light;
      cursor: pointer;
      font-size: 1.3rem;
      z-index: 3;

      @media (min-width: $md-width) {
        right: 2.5rem;
        top: 0.2rem;
      }
    }
  }

  .panel-product-availability-ctn-add,
  .panel-product-availability-ctn {
    flex-basis: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    order: 3;
    padding: 1rem 0 1.2rem 0;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
      order: 4;
      padding: 1rem 0 0 7.5rem;
    }
  }
}
