.payment-choice {
  padding: 0.5rem;
  width: 100%;

  .payment_module {
    height: 100%;
  }

  @media (min-width: $xs-width) {
    padding: 0.5rem 1rem;
  }
  @media (min-width: $md-width) {
    padding: 1.5rem;
    width: 33.33%;
  }

  .payment_module a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    em {
      font-size: 0.9rem;
    }
  }

  &-inner {
    align-items: center;
    justify-content: center;
    background: $grey-lighter;
    border: 0.1rem solid $grey-lighter;
    border-radius: 0.3rem;
    color: $black;
    display: flex;
    font-size: 1.2rem;
    height: 100%;
    line-height: 1.6;
    padding: 1rem;
    text-align: center;
    transition: border 0.3s ease 0s;

    @media (min-width: $md-width) {
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;
      padding: 1.5rem;
    }

    & > div {
      order: -1;

      @media (min-width: $md-width) {
        margin-top: 0.5rem;
        order: 2;
      }
    }
  }

  div#payment-method {
    border-top: 0;
  }

  .payment-choice-inner em {
    line-height: 1.3;
  }

  &.checked .payment-choice-inner {
    border-color: $grey-light;
  }

  &-img {
    // display: block;
    // margin-left: auto;
    // max-width: 6rem;
    // order: 2;

    @media (min-width: $md-width) {
      margin: 0 auto 0 auto;
      width: 100%;
      order: -1;
    }
  }

  .custom-radio {
    margin-right: 1rem;

    @media (min-width: $md-width) {
      margin: 0;
    }
  }
}

#hipay_payment_button img {
  margin-bottom: 5px;
  max-width: 80%;
}

.payment-method {
  & + & {
    border-top: 0.1rem solid $grey-light;
    margin: 0 -1.5rem;
    padding: 1.5rem;

    @media (min-width: $md-width) {
      margin: 0 -10rem;
      padding: 3rem 10rem 0 10rem;
    }
  }

  &.cgv-block {
    padding-bottom: 0;

    label {
      font-size: 1.2rem;
    }

    a {
      text-decoration: underline;
    }

    .button {
      margin-top: 1rem;
    }
  }

  &-title {
    color: $black;
    font-weight: 700;
  }

  &-list {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 1rem;

    @media (min-width: $sm-width) {
      padding-bottom: 0;
    }

    &.large .payment-choice {
      min-width: 100%;

      @media (min-width: $md-width) {
        min-width: 50%;
      }
    }
  }
}

.stack-validate {
  @include pof(null, 0, 0, 0);
  background: $white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  transform: translateY(101%);
  transition: transform 0.3s ease 0s;
  z-index: 12;

  @media (min-width: $md-width) {
    display: none;
  }

  &.open {
    transform: translateY(0);
  }
}

.be2bill-redirect-skin {
  padding: 10rem;
  text-align: center;
}

// ****** ORDER HIPAY ********

.module-hipay_enterprise-redirect {
  h1 {
    margin-bottom: 10px;
  }

  .card-number-wrapper,
  .name-wrapper,
  .expiry-wrapper,
  .cvc-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 10px;

    input, select {
      margin-left: 5px;
    }
  }

  .hipay-cart-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: end;
    align-content: center;
  }

  #credit-card-group {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .expiry-wrapper div {
    display: flex;
  }

  .block-pay button {
    display: block;
    margin: auto;
  }

  .hipay-cart {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

#credit-card-group {
  margin-bottom: 20px;
}

.hipay-field-error {
  line-height: 11px !important;
}

.hipayHF-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 0 16px 0;
}

#hipayHF-container {
  font-family: "Roboto", sans-serif;
  background-color: white;
}

#hipayHF-form {
  margin: auto;
  width: 100%;
  max-width: 520px;
}

.hipayHF-field-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  height: 50px;
  margin: 0 10px;
}

.hipayHF-field-container-half {
  width: 50%;
}

.ie9 .hipayHF-field-container-half {
  width: 100%;
  margin-bottom: 16px;
}

.hipayHF-field {
  z-index: 2;
  opacity: 0.001;
  -webkit-transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;

  height: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  margin-bottom: 7px;
  color: #32325d;
  background-color: transparent;
}

@media screen and (max-width: 359px) {
  .hipayHF-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .hipayHF-field-container {
    margin-bottom: 16px;
  }

  .hipayHF-field-container-half {
    width: 100%;
  }
}

.hipayHF-field.HiPayField--focused,
.hipayHF-field:not(.HiPayField--empty) {
  opacity: 1;
}

.hipayHF-field.HiPayField--disabled {
  background-color: #f3f3f3;
}

.hipayHF-label {
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  color: #cfd7df;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  cursor: text;
  -webkit-transition-property: color, -webkit-transform;
  transition-property: color, -webkit-transform;
  -o-transition-property: color, transform;
  transition-property: color, transform;
  transition-property: color, transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-align: left;
  margin-bottom: 0;
}

.HiPayField--focused + .hipayHF-label,
.hipayHF-field:not(.HiPayField--empty) + .hipayHF-label {
  color: #aab7c4;
  -webkit-transform: scale(0.85) translateY(-25px);
  -ms-transform: scale(0.85) translateY(-25px);
  transform: scale(0.85) translateY(-25px);
  cursor: default;
}

.HiPayField--focused + .hipayHF-label {
  color: #00ade9 !important;
}

.HiPayField--focused + .hipayHF-baseline {
  color: #00ade9 !important;
}

.HiPayField--invalid + .hipayHF-label {
  color: #d50000 !important;
}

.hipayHF-baseline {
  position: absolute;
  width: 100%;
  height: 0px;
  left: 0;
  bottom: 0;
  border-bottom: solid 1px #cfd7df;
  /*background-color: #cfd7df;*/
  -webkit-transition: border-bottom 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: border-bottom 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: border-bottom 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.HiPayField--focused + .hipayHF-label + .hipayHF-baseline {
  border-bottom: solid 1px #00ade9;
}

.HiPayField--invalid + .hipayHF-label + .hipayHF-baseline {
  border-bottom: solid 1px #d50000;
}

.HiPayField--disabled + .hipayHF-label + .hipayHF-baseline {
  border-bottom: dotted 1px #cfd7df;
}

#hipayHF-help-cvc {
  font-size: 14px;
  padding: .75rem 1.25rem;
  margin-top: 25px;
  border-radius: .25rem;
  color: #856404;
  background-color: #fff3cd;
  border: solid 1px #ffeeba;
  display: none;
}

#hipayHF-help-cvc.visible {
  display: block;
}

.hipay-form-17 {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 2.875rem;
}

.hipay-form-17 input, .hipay-form-17 select {
  background-color: #fdfdfd !important;
}

.hipay-form-17 input:disabled, .hipay-form-17 select:disabled {
  background-color: #eee !important;
}

.error-input-hp {
  border-color: #ff4c4c !important;
}

.error-text-hp {
  color: #ff4c4c;
  font-size: 15px;
}

.input-hp {
  border: 1px solid rgba(0, 0, 0, .15);
}

.card-img {
  margin-bottom: 1.05em;
}

.hipay-form-17 .option_payment label {
  font-size: .9375rem;
  color: #7a7a7a;
  display: inline-block !important;
}

.hipay-form-17 .option_payment.saved-card {
  margin-left: 40px;
}

#save-credit-card {
  margin-left: 15px;
}

.group-card {
  margin-top: 15px;
}

.one-click {
  margin: 10px;
  padding: 10px 0;
}

a.tooltips {
  position: relative;
  text-align: right;
  display: block;
  font-size: 0.8em;
}

a.tooltips span {
  position: absolute;
  width: 340px;
  color: #fff;
  background: #000;
  text-align: left;
  visibility: hidden;
  border-radius: 6px;
  padding: 3px;
}

a.tooltips span:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid #000;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

a:hover.tooltips span {
  visibility: visible;
  opacity: 0.8;
  bottom: 30px;
  left: 50%;
  margin-left: -76px;
  z-index: 999;
}

.card-js .expiry-month {
  text-indent: 30px;
  width: 35%;
  padding-left: 0px !important;
}

.card-js .expiry-year {
  width: 30%;
  margin-left: 2%;
  text-indent: 15%;
  border-left: 1px solid #d9d9d9 !important;
}

@-moz-document url-prefix() {
  .card-js .expiry-month {
    text-indent: 15px;
  }

  .card-js .expiry-year {
    text-indent: 5%;
  }
}

.card-js input:focus, .card-js select:focus {
  border-color: #66afe9 !important;
}

.card-js select option[hidden] {
  color: #c8c8c8 !important;
}

.hipay-form-16-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #01025c;
  font-size: 18px;
  line-height: normal;
}

.module-hipay_enterprise-redirect {
  h5 {
    color: #000;
    font-size: 16px;
    margin: 10px 10px;

    .price {
      color: #cb1f43;
      font-size: 1.8rem;
      font-weight: 900;
      margin-top: -0.2rem;
    }
  }

  .loading {
    margin: 40px auto 30px;
  }
}

#pay-button {
  background: #00ca40;
  padding-left: 80px;
  padding-right: 80px;
  margin: 40px auto 30px;
}

@media screen and (max-width: 1200px) {
  .card-js .expiry-month {
    text-indent: 30px;
    width: 60%;
    padding-left: 0px !important;
  }

  .card-js .expiry-year {
    width: 38%;
    margin-left: 2%;
    text-indent: 15%;
  }

  @-moz-document url-prefix() {
    .card-js .expiry-month {
      text-indent: 15px;
    }

    .card-js .expiry-year {
      text-indent: 5%;
    }
  }
}

.payment-return {
  margin: 30px auto 60px;

  .warning {
    color: $red;
    font-size: 16px;
  }

  .button {
    margin: 30px 15px 0;
  }
}
