.order-confirm {
  &-content {
    padding: 1.5rem;

    @media (min-width: $md-width) {
      padding: 3rem 1.5rem;
      text-align: left;
    }

    .success-info {
      border-radius: 0.3rem;
      padding: 8rem 20% 1.5rem 20%;
      text-align: center;

      @media (min-width: $xs-width) {
        padding: 3rem 3rem 3rem 9rem;
        text-align: left;
      }

      .circle-animate {
        @include poa(1.5rem, 0, 0, 50%);
        transform: translateX(-50%) scale(1);

        @media (min-width: $xs-width) {
          @include poa(50%, 0, 0, 2rem);
          transform: translateY(-50%) scale(1);
        }
      }

      .txt {
        display: block;
      }
    }
  }

  .module-return {
    color: $black;
    font-size: 1.2rem;
    line-height: 1.6;
    padding: 1rem 0.5rem;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
      padding: 2rem;
    }
  }

  .account-block {
    padding: 1rem 2rem;

    .fieldset-group-desc {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }
  }

  .advconstructor-home {
    border-radius: 0.3rem;
    height: auto;
    margin-bottom: 2rem;
    padding: 3rem 7rem;
    text-align: center;

    &_content {
      font-size: 1.2rem;

      a {
        font-size: 1.4rem;
        margin-top: 2rem;
      }
    }
  }

  .fieldset-ctn.payment {
    margin: 2rem 0;
  }
}

.oney_field_error {
  color: $orange;
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 0.3rem;
  text-align: right;
  width: 100%;
}

.cart-resume {
  margin: 2rem 0 2.5rem 0;

  @media (min-width: $md-width) {
    margin-bottom: 3rem;
  }

  .fieldset-ctn {
    border-radius: 0.3rem 0.3rem 0 0;
    margin-bottom: 0;

    @media (min-width: $md-width) {
      border-radius: 0.3rem 0.3rem 0 0.3rem;
    }
  }

  .shopping-cart-summary {
    border: 0.1rem solid $grey-light;
    border-radius: 0 0 0.3rem 0.3rem;
    border-top: 0;
    box-shadow: none;
    margin-top: -0.8rem;
    position: static;

    @media (min-width: $md-width) {
      margin: 0 0 0 50%;
      width: 50%;
    }

    &-inner {
      border-top: 0;

      @media (min-width: $sm-width) {
        border: 0;
      }
    }
  }

  .shopping-cart {
    &-product {
      border: 0;
      margin: 0;
      padding: 0.5rem;

      @media (min-width: $md-width) {
        padding: 1.5rem;
      }

      &-infos {
        margin-top: 0;
      }

      &-availability-ctn {
        @media (min-width: $md-width) {
          padding-top: 0;
        }
      }

      &-prices {
        padding-right: 1rem;

        @media (min-width: $md-width) {
          padding-right: 0;
        }
      }

      &.service-product {
        align-items: center;
        padding: 0.5rem;

        @media (min-width: $md-width) {
          padding: 0 1.5rem;
        }
      }
    }
  }
}

.shipping-resume {
  padding: 0.5rem;

  @media (min-width: $xs-width) {
    padding: 2rem;
  }

  .carrier-item {
    margin: 0 -0.75rem;

    @media (min-width: $xs-width) {
      margin: 0 -1.5rem;
    }
  }

  .addresses-choice {
    margin: 1.5rem 0 0 0;

    @media (min-width: $xs-width) {
      margin: 3rem 0 0 0;
    }

    .address-choice-inner {
      background: $grey-lighter;
      border: 0;
    }
  }
}
