/* Filtres actifs */
.enabled-filters {
  display: none;
  margin-bottom: 2rem;
  min-height: 4rem;
  text-align: left;

  .enabled-filters-ctn {
    background: $grey-lighter;
    margin: 0 -1.5rem;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;

    @media (min-width: $sm-width) {
      margin: 0;
    }
  }

  .title {
    @include heading($black, 1.6, 1, 700, false);
    margin-right: 2rem;
  }

  .clear-all {
    float: none;
    font-size: 1.2rem;
    margin-top: 0.2rem;

    &-mobile {
      float: right;
    }

    &::before {
      content: "\e901";
      font-family: "icomoon";
      margin-right: 0.6rem;
    }
  }

  .enabled-filters-list {
    display: inline-block;

    .enabled-filters-item {
      display: inline-block;
      margin: 0 1rem 1rem 0;

      &-ctn {
        background-color: $grey-light;
        border-radius: 0.2rem;
        color: $grey-medium;
        padding: 0.6rem 1.2rem;
        padding-right: 3rem;
        position: relative;

        @media (min-width: $sm-width) {
          background-color: $grey-lighter;
          font-size: 1.2rem;
          padding-top: 0.5rem;
        }

        .icon-close::before {
          content: "\e905";
          font-family: icomoon;
          font-size: 0.8rem;
          font-weight: normal;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.enabled-filters-mobile .enabled-filters-list .enabled-filters-item-ctn {
  background: $grey-light;
}

.product-sort-btn {
  .custom-select {
    span {
      border: 0;

      &::after {
        content: "";
      }
    }
  }
}

.button.open-filters {
  padding: 1.2rem;

  .icon {
    margin-right: 1rem;
  }
}

.filters-ctn,
.enabled-filters .enabled-filters-list {
  .filter-hidden {
    display: none;
  }
}

/* displayFilter */
.filter-ctn {
  margin-bottom: 3rem;

  .filter-title {
    @include heading($black, 1.6, 0, 700, false);
    display: block;

    .popover-trigger {
      color: $grey-medium;
      cursor: pointer;
      display: inline-block;
      font-size: 1.4rem;
      margin-left: 1rem;
      position: relative;
      top: 0.2rem;
    }
  }

  .layered_close {
    @media (min-width: $md-width) {
      display: none;
    }
  }

  .filter-description {
    color: $grey-medium;
    font-size: 1.2rem;
    margin: 0.3rem 0 0 0;
  }

  .filter-values {
    margin-top: 0.5rem;
    max-height: 20rem;
    overflow-x: auto;
    padding: 0 0.2rem;

    .filter-value-ctn {
      padding: 0.4rem 0;
    }

    .layered-see-all {
      font-size: 1.2rem;
      margin-top: 1rem;
      padding: 0.8rem 5.5rem;

      .bubbles {
        margin-left: 1rem;
        position: relative;
        top: 0.1rem;
      }
    }
  }
}

.panel-filters {
  background: $white;

  .panel-head {
    .panel-title {
      color: $blue-dark;
      text-align: center;
    }

    .panel-close {
      font-size: 1rem;
      left: 1.5rem;
      right: auto;

      @media (min-width: $md-width) {
        left: 7rem;
      }

      .icon::before {
        color: $blue-dark;
        content: "\e90c";
        margin-top: 0.3rem;
      }
    }

    .reset-filters {
      @include poa(50%, 1.5rem);
      font-size: 1.2rem;
      transform: translateY(-50%);
    }
  }

  .filters-ctn {
    padding: 0;
  }

  .filter-ctn {
    border-bottom: 0.1rem solid $grey-light;
    margin-bottom: 0;
    padding: 0 1.5rem;

    @media (min-width: $md-width) {
      padding: 0 7rem;
    }
  }

  .filter-header {
    cursor: pointer;
    display: block;
    padding: 1.5rem 0;
    position: relative;
  }

  .filter-title {
    font-size: 1.4rem;
  }

  .layered_close {
    @include poa(1.9rem, 0);
    display: block;
    font-size: 1rem;
    font-weight: 400;

    a:hover {
      text-decoration: none;
    }
  }

  .filter-values {
    display: none;
    margin-bottom: 1.5rem;
    max-height: unset;
    overflow: visible;
  }

  .filter-ctn.open {
    .layered_close .icon::before {
      content: "\e90a";
    }

    .filter-values {
      display: block;
    }
  }

  .apply-filters {
    @include animated(all);
    @include pof(null, -101%, 0);
    background: $white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
    display: none;
    padding: 1rem 3rem;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;

    @media (min-width: $xs-width) {
      right: -81%;
      width: 80%;
    }
    @media (min-width: $sm-width) {
      right: -61%;
      width: 60%;
    }
    @media (min-width: $md-width) {
      right: -74rem;
      width: 73rem;
    }

    .button {
      text-align: center;
      width: 100%;
    }
  }

  &.open {
    .apply-filters {
      right: 0;
    }
  }

  .panel-recommendations {
    display: none;
  }
}

.products-list.stacked {
  padding-top: 6rem;
}

.filter-stack {
  @include poa(0, 0, null, 0);
  z-index: 10;

  &.stack {
    background: $white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    padding: 1rem 1.5rem;
    position: fixed;
  }

  .productsSortForm {
    margin-bottom: 0;
  }
}

#layered_price_range {
  @media (min-width: $md-width) {
    font-size: 0;
    position: absolute;
    top: 30px;
    width: 100%;

    .min-value-range,
    .max-value-range {
      font-size: 1.4rem;
      position: absolute;
    }

    .min-value-range {
      left: 0;
    }

    .max-value-range {
      right: 0;
    }
  }
}

.layered_slider_container {
  height: 40px;
  margin-top: 15px;
  padding: 0 8px;
  position: relative;

  .ui-slider-handle {
    background: $blue-dark;
    border: 0 !important;
    border-radius: 50%;
    height: 14px;
    top: -4px;
    width: 14px;
  }

  .ui-slider-range {
    background: $blue-dark;
  }

  .ui-slider {
    background: #e6e6e6;
  }

  .ui-slider-horizontal {
    border: 0;
    height: 7px;
  }
}
