/* Product details */
.product-pictos {
  border-top: 0.1rem solid $grey-light;
  margin: 1.5rem -1.5rem 0 -1.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem;

  @media (min-width: $lg-width) {
    margin: 1.5rem -3rem 0 -3rem;
    padding: 1.5rem 3rem 0 3rem;
  }

  .picto-ctn {
    display: block;
    text-align: center;
    text-decoration: none;
  }

  .picto-desc {
    color: $grey;
    display: block;
    font-size: 1.2rem;
  }
}
