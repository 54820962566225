@import "_matrices.scss";

.advcms {
  font-family: $muli;

  .advcms-ctn {
    display: block;

    @media (min-width: $md-width) {
      display: flex;
    }
  }

  .padded {
    margin: 0;
    max-width: 100%;
    padding: 0 15px;

    @media (min-width: $sm-width) {
      padding: 0 30px;
    }
    @media (min-width: $md-width) {
      padding: 0 10%;
    }
    @media (min-width: 1800px) {
      padding: 0 20%;
    }
    @media (min-width: 2500px) {
      margin: 0 auto;
      max-width: 1230px;
      padding: 0;
    }
  }

  &.advcms-cms .padded {
    padding: 0 15px;

    @media (min-width: $sm-width) {
      padding: 0 30px;
    }
    @media (min-width: $xl-width) {
      padding: 0 15%;
    }
    @media (min-width: 2500px) {
      margin: 0 auto;
      max-width: 1230px;
      padding: 0;
    }
  }

  .block-white {
    background-color: $white;
    color: $black;
  }

  .block-grey {
    background-color: $grey-lighter;
    color: $blue-dark;
  }

  .block-vert {
    background-color: #81c0b8;
    color: $white;
  }

  .block-blue {
    background-color: $blue;
    color: $white;

    .button:hover {
      background-color: $white;
      color: $blue-dark;
    }
  }

  .block-orange {
    background: rgb(243, 147, 0);
    background:
      -moz-linear-gradient(
        45deg,
        rgba(243, 147, 0, 1) 0%,
        rgba(254, 186, 1, 1) 29%
      );
    background:
      -webkit-linear-gradient(
        45deg,
        rgba(243, 147, 0, 1) 0%,
        rgba(254, 186, 1, 1) 29%
      );
    background:
      linear-gradient(
        45deg,
        rgba(243, 147, 0, 1) 0%,
        rgba(254, 186, 1, 1) 29%
      );
    color: $blue-dark;
    font-size: 2rem;
  }

  &-header {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.advcms-content {
  flex-basis: 100%;

  .ctn {
    padding: 0 15px;
    width: 100%;
  }

  .advcms-header {
    padding-top: 1.5rem;

    .bcrumb {
      color: inherit;
      display: inline-block;
      padding: 15px;

      .icon {
        font-family: "icomoon";
        font-size: 10px;
      }

      span {
        font-size: 12px;
      }
    }

    .advcms-title {
      font-weight: 900;
      padding-bottom: 20px;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 25px;
      }
      @media (min-width: $sm-width) {
        font-size: 30px;
        padding-bottom: 25px;
      }
      @media (min-width: $md-width) {
        font-size: 40px;
        margin: 0;
        padding-bottom: 37px;
      }
    }

    .advcms-excerpt-ctn {
      background: $white;
      color: $black;
      font-size: 14px;
      padding-top: 20px;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 15px;
      }
      @media (min-width: $sm-width) {
        font-size: 18px;
        padding-top: 25px;
      }
      @media (min-width: $md-width) {
        font-size: 20px;
        padding-top: 35px;
      }

      .advcms-excerpt {
        padding: 0;

        @media (min-width: $md-width) {
          margin-bottom: 30px;
          padding: 0 10%;
        }
      }
    }
  }
}
