.address {
  &-choice {
    margin-bottom: 1.5rem;

    @media (min-width: $xs-width) {
      margin-bottom: 2rem;
    }
    @media (min-width: $sm-width) {
      margin-bottom: 1.5rem;
    }
    @media (min-width: $md-width) {
      margin-bottom: 3rem;
    }

    &-inner {
      border: 0.1rem solid $grey-light;
      border-radius: 0.3rem;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.2rem;
      height: 100%;
      padding: 1rem 1.5rem;
      transition: border 0.3s ease 0s;

      @media (min-width: $sm-width) {
        font-size: 1.4rem;
        padding: 2rem 3rem;
      }

      label {
        font-weight: bold;
      }

      &:hover {
        border-color: $black;
      }
    }

    &-infos {
      flex-basis: 100%;
      margin-top: 0.5rem;
      order: 1;
      padding-left: 3rem;

      .update {
        font-size: 1.2rem;
        margin-top: 1rem;
        text-decoration: underline;

        a {
          margin-right: 0.5rem;
        }
      }
    }

    .custom-radio.checked + .address-choice-infos {
      color: $black;
    }

    &.add-address-link {
      .address-choice-inner {
        align-items: center;
        font-weight: 700;
        line-height: 1.2;
        padding: 2rem 30%;
        text-align: center;
      }

      a {
        text-decoration: none;
      }

      .icon {
        display: block;
        font-size: 2.5rem;
        margin: 0 auto 0.5rem auto;
      }
    }
  }
}

.address-invoice-form {
  margin-top: 2rem;

  @media (min-width: $md-width) {
    margin-top: 5rem;
  }

  + .submit {
    margin-top: 2rem;
    padding: 0;
  }
}

.addresses {
  &-choice {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 5rem;
    }
  }

  &-resume {
    &-title {
      display: block;
      font-weight: 700;
      margin-bottom: 0.5rem;

      @media (min-width: $md-width) {
        margin-bottom: 1.5rem;
      }
    }

    .address-choice-inner {
      color: $grey-medium;
      padding: 0;
      border: 0;
      height: auto;
    }

    .address-choice-infos {
      margin: 0;
      padding: 0;

      .alias {
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
}
