.menu-account {
  background-color: $grey-lighter;
  border: 1px solid $grey-light;
  font-family: $muli;
  font-weight: bold;

  @media (min-width: $md-width) {
    margin: 0;
    width: auto;
  }
  @media (min-width: $md-width) {
    border: 0;
    border-right: 1px solid $grey-light;
    flex-basis: 350px;
    padding: 20px 30px;
  }
  @media (min-width: $lg-width) {
    flex-basis: 410px;
    padding: 40px 50px 40px 45px;
  }

  h2 {
    color: $blue-dark;
    display: block;
    font-size: 1.6rem;
    margin-bottom: 13px;
    margin-top: 13px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    @media (min-width: $md-width) {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  ul {
    margin-bottom: 5rem;

    li {
      a {
        border-top: 1px solid $grey-light;
        color: $blue-dark;
        display: block;
        font-size: 1.6rem;
        padding: 13px 45px 13px 30px;
        position: relative;

        @media (min-width: $md-width) {
          border-top: 0;
          display: inline-block;
          font-size: 1.6rem;
          padding-left: 0;
          padding-right: 0;

          &::after {
            content: none;
          }
        }

        &:hover {
          color: $black;
          text-decoration: underline;
        }

        &::after {
          color: $grey-medium;
          content: "\e920";
          font-family: "icomoon";
          font-size: 1.2rem;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);

          @media (min-width: $md-width) {
            display: none;
          }
        }

        &.logout {
          color: $grey-medium;

          &::after {
            content: "\e905";
          }
        }

        &.active {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }

  &.isAccountHome {
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  &:not(.isAccountHome) {
    display: none;

    @media (min-width: $md-width) {
      display: block;
    }
  }
}
