.product {
  .color {
    &_pick {
      img {
        @media(min-width: $md-width) {
          height: 56px;
          width: 56px;
        }
      }
    }
  }

  .more-color {
    height: 32px;
    line-height: 32px;
    width: 44px;

    @media(min-width: $md-width) {
      height: 40px;
      line-height: 40px;
      width: 55px;
    }
  }
}

.panel-colors {
  background-color: $white;

  .panel-head {
    height: 85px;
  }

  .panel-head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 45px;

    &,
    & .panel-title {
      background-color: $blue-dark;
    }

    .panel-title,
    .panel-close {
      color: #fff;
      padding: 0;
    }

    .panel-close {
      position: static;
    }
  }

  .panel-content {
    padding: 45px;
  }

  .panel-color-item {
    border: 1px solid $grey-light;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 10px 30px 10px 25px;
  }

  .attribute-title {
    font-weight: 700;
    margin-left: 15px;

    @media(min-width: $md-width) {
      margin-left: 30px;
    }
  }

  .panel-color-pick {
    img {
      @media(min-width: $md-width) {
        height: 180px;
        width: 180px;
      }
    }
  }

  .panel-color-price {
    font-size: 18px;
    font-weight: 700;
  }
}
