.matrices {
  .faq {
    padding: 8px 0;

    @media (min-width: $sm-width) {
      padding: 15px 0;
    }

    &:first-child {
      margin-top: 30px;
    }

    .padded {
      padding: 0 15px;

      @media (min-width: $sm-width) {
        padding: 0 10%;
      }
    }

    .matrice-text-ctn {
      border: 1px solid $grey-light;
      padding: 0;
    }

    .matrice-title {
      color: $blue-dark;
      cursor: pointer;
      font-size: 15px;
      margin-bottom: 0;
      padding: 15px 45px 15px 15px;
      position: relative;

      @media (min-width: $sm-width) {
        font-size: 18px;
        padding: 30px 45px 30px 25px;
      }

      &::after {
        color: $blue-dark;
        content: "\e915";
        font-family: "icomoon";
        font-size: 5px;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.open::after {
        content: "\e90a";
        font-size: 10px;
      }
    }

    .matrice-content {
      color: $black;
      display: none;
      line-height: 24px;
      padding: 15px 25px 25px 25px;

      h3 {
        font-size: 16px;
        font-weight: 900;
        margin: 30px 0 5px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      ul {
        padding-left: 30px;
      }
    }
  }
}
