.blocksocial {
  .blocksocial-list {
    padding: 0 1.5rem;

    @media (min-width: $xs-width) {
      padding: 0;
    }
  }

  .blocksocial-item {
    display: inline-block;
    margin: 0 1.5rem 1rem 0;

    a {
      @include animated(background);
      @include size(4.5rem);
      background: $blue-dark;
      border-radius: 50%;
      color: $white;
      display: block;
      font-size: 2.2rem;
      line-height: 5rem;
      text-align: center;

      &:hover {
        background: $blue;
        text-decoration: none;
      }
    }
  }
}
