@import "panel/delivery-delay-click-test";
@import "panel/delivery-delay";
@import "../../_modules/netreviews/product";
@import "../../_pages/product/attributes-choose";

.product-ctn-img-ctn {
  padding: 25px 0;
}

.product-ctn-img {
  padding-bottom: 67.4%;
}

.product-ctn {
  font-size: 1.3rem;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  position: relative;

  &-inner {
    @include animated(box-shadow);
    border: 0.1rem solid $grey-light;
    border-radius: 0.2rem;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;

    &:hover {
      box-shadow: 0 0 10rem rgba(0, 0, 0, 0.1);
    }
  }

  &-infos {
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;

    @media (min-width: $sm-width) {
      height: 33rem;
      margin-bottom: 0;
    }
    @media (min-width: $md-width) {
      height: 30rem;
    }
    @media (min-width: $xl-width) {
      height: 32rem;
    }
  }

  &-filigrane {
    @include poa(0, null, null, 0);
    max-width: 100%;
    width: 100%;
    z-index: 1;

    .lazy-ctn {
      padding-bottom: 11.22%;
    }
  }

  &-flashsale {
    background: transparent;
    border: 0;
    text-align: center;
    width: 100%;

    .flashsale-content {
      align-items: center;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      color: $red-soldes;
      display: inline-flex;
      justify-content: center;
      padding: 0.7rem;
    }

    .countdown-row {
      font-weight: bold;
      padding: 0;

      .countdown-section {
        margin-left: 6px;
      }
    }

    .countdown-row {
      clear: both;
      padding: 0 2px;
      text-align: center;
      width: 100%;
    }
  }

  &-img-ctn {
    overflow: hidden;
    position: relative;
  }

  &-img {
    display: block;
  }

  &-watermark {
    @include poa(null, null, 0, 0);
    max-width: 7rem;

    @media (min-width: $sm-width) {
      max-width: 10rem;
    }
  }

  &-name {
    color: $blue-dark;
    font-family: $muli;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }

  &-size {
    color: $blue-dark;
    font-size: 1.3rem;
    margin-top: 3px;
  }

  .av_category_stars {
    margin: 0;

    .ratingText {
      left: 7.5rem;
    }
  }

  &-details {
    padding: 0 1.5rem;
  }

  .product-availability {
    font-weight: normal;
    margin-bottom: 0.2rem;
    margin-top: 3rem;
    padding: 0;
  }

  .product-desc {
    line-height: 1.8rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &-footer {
    border-top: 1px solid $grey-light;
    margin-top: auto;
    padding: 1.2rem 0;
    width: 100%;

    &.discount {
      padding: 0.5rem 0;
    }
  }

  &-tag {
    background-color: $blue-dark;
    border-radius: 2rem;
    color: $white;
    display: inline-block;
    padding: 0.5rem 1rem;
  }

  &-flag {
    padding-right: 5rem;
  }

  &-price {
    padding-left: 0;
    text-align: right;

    .old-price {
      display: block;
      font-size: 1.1rem;

      @media (min-width: $sm-width) {
        font-size: 1.3rem;
      }

      @-moz-document url-prefix() {
        margin-bottom: 3px;
      }
    }

    .current-price {
      color: $red;
      display: block;
      font-size: 2rem;
      font-weight: 900;
      white-space: nowrap;

      @media (min-width: $sm-width) {
        font-size: 2.4rem;
      }
    }
  }
}

.panel .product-ctn-watermark {
  max-width: 8rem;
}

.delivery {
  &-delay {
    &,
    &-stock {
      color: $green;
    }

    &,
    &-product {
      text-decoration: underline;
    }
  }

  &-store.icon {
    color: $red;
  }
}

.fake-slide {
  flex-wrap: nowrap;
  margin: 0 -1.5rem;
  overflow-x: auto;

  @media (min-width: $sm-width) {
    flex-wrap: wrap;
    overflow: visible;
  }

  .product-ctn {
    min-width: 70%;
    padding-bottom: 0;

    @media (min-width: $xs-width) {
      min-width: 55%;
    }
    @media (min-width: 650px) {
      min-width: 40%;
    }
    @media (min-width: $sm-width) {
      min-width: unset;
    }
  }
}
