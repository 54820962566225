.storedetails {
  .breadcrumb {
    @extend %breadcrumb;
  }

  .map-container {
    overflow: hidden;
    position: relative;

    .destination-infos {
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
      color: $black;
      font-size: 13px;
      left: 140px;
      line-height: 38px;
      margin: 0 auto;
      padding: 0 10px;
      position: absolute;
      text-align: center;
      top: -50px;
      transition: top linear 0.5s;
      width: 280px;
      z-index: 1;

      &.open {
        top: 10px;
      }
    }

    #map {
      height: 30rem;
      width: 100%;

      @media (min-width: $md-width) {
        height: 100%;
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  .bordered {
    border-bottom: 1px solid $grey-light;
    margin: 0 -0.75rem;
    padding: 0 2rem;

    @media (min-width: $xs-width) {
      margin: 0 -1.5rem;
      padding: 0 3rem;
    }
    @media (min-width: $sm-width) {
      padding: 0 5rem;
    }
  }
}

.store-details-ctn {
  .store-event {
    margin: 0 -0.75rem;

    @media (min-width: $xs-width) {
      margin: 0;
    }
  }

  .store-title {
    color: $blue-dark;
    font-size: 2rem;
    font-weight: 900;

    @media (min-width: $lg-width) {
      font-size: 2.4rem;
    }
  }

  .store-block-inner {
    border: 0;
    line-height: 2rem;
    padding: 1.5rem 0;
  }

  .store-block-subtitle {
    font-weight: 900;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .store-block-hours .schedule-line {
    line-height: 1.4rem;
    margin-right: 0;
  }

  .store-block-address {
    .button {
      @media (min-width: $md-width) {
        width: auto;
      }
    }
  }

  .store-address {
    color: $black;
    font-size: 12px;
  }

  .store-phone {
    font-size: 16px;
  }

  .button {
    font-size: 1.3rem;
    margin: 1.5rem 0 0 0;
    padding: 1rem 0.5rem;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
      padding: 1.5rem 2rem;
    }

    .icon {
      margin-left: 0.5rem;
    }

    &-clickandrdv {
      margin-top: 5px;
      padding: 1.5rem 1rem;
    }
  }
}

.store-information-ctn {
  padding: 2rem 0;

  @media (min-width: $md-width) {
    padding: 3rem 0;
  }
  @media (min-width: $lg-width) {
    padding: 3rem 15%;
  }

  .store-block-subtitle {
    margin-bottom: 0.5rem;

    @media (min-width: $md-width) {
      margin-bottom: 1.5rem;
    }
  }

  .store-block-services-item {

    justify-content: space-between;

    @media screen and (min-width: $md-width) {
      flex-basis: 25%;
      min-width: 25%;
    }
  }

  .direction-mode-ctn {
    text-align: center;

    .direction-mode-label {
      color: #666;
      display: inline-block;
      font-size: 18px;
      padding: 0 15px 8px;

      &.active {
        color: $green;
      }
    }

    input[type="radio"] {
      display: none;
    }
  }

  .direction-form-line {
    border-bottom: 1px solid $grey-light;
    border-top: 1px solid $grey-light;
    margin-bottom: 0;
    padding-left: 33px;
    position: relative;

    .icon {
      font-size: 13px;
      left: 10px;
      position: absolute;
      top: 15px;

      &.icon-store {
        font-size: 16px;
        top: 55px;
      }

      &.icon-triple-dot {
        font-size: 30px;
        left: 14px;
        letter-spacing: -1px;
        top: 23px;
        transform: rotate(90deg);
      }
    }

    label {
      padding: 10px 0 5px 20px;
    }

    .form-control {
      background: $grey-lighter;
      border-bottom: 1px solid $grey-light;
      border-left: 0;
      border-right: 0;
      padding: 0 20px;
    }
  }
}

.store-footer {
  background: $grey-lighter;
  padding: 5rem 0 3rem;

  &-title {
    color: $grey-medium;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .store-image {
    text-align: center;

    img {
      width: 100%;
    }
  }

  .store-promo {
    background: $yellow;
    color: $blue-dark;
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 40px;
    padding: 25px 0;
    text-align: center;

    p {
      margin-bottom: 1rem;

      &:first-child {
        font-size: 1.8rem;
      }

      &:last-child {
        display: block;
        font-size: 1.4rem;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    p strong {
      border-color: inherit;
      border-radius: 0.8rem;
      border-style: solid;
      border-width: 0.1rem;
      display: inline-block;
      padding: 0 0.5rem;

      @media screen and (min-width: $md-width) {
        padding: 0.4rem 1.2rem;
      }
    }
  }

  .store-jobs {
    background: $blue-dark;
    color: $white;
    margin-top: 40px;
    padding: 30px 40px 30px 110px;

    .icon {
      color: $yellow;
      font-size: 5.4rem;
      left: -50px;
      position: absolute;
      top: 0;
    }

    &-title {
      font-size: 2.4rem;
      font-weight: 900;
      margin-bottom: 5px;
    }

    &-subtitle {
      font-size: 1.6rem;
    }

    .button {
      background: $white;
      color: $blue-dark;
    }
  }

  .owl-dots {
    bottom: 1rem;
  }
}