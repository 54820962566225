.reassurance-footer {
  background: $blue-dark;
  color: $white;

  .advreassurance-item {
    min-width: calc(100% / 6);

    &:hover {
      .advreassurance-link {
        color: $white;
      }

      .icon {
        transform: translateY(0.5rem);
      }
    }
  }

  .advreassurance-title,
  .advreassurance-subtitle {
    color: $white;
  }
}
