.pagenotfound {
  &-ctn {
    margin-top: 1rem;
    margin-bottom: 6rem;

    @media (min-width: $sm-width) {
      margin-top: 1rem;
    }

    .img-404 {
      display: inline-block;
      max-width: 100%;
    }

    .button {
      display: inline-block;
      margin-top: 1rem;
    }
  }
}
