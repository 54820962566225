.advmanufacturer {
  &-category {
    .advmanufacturer-item {
      margin-bottom: 3rem;
      min-width: 17rem;

      @media (min-width: $md-width) {
        min-width: 16.66667%;
      }
    }

    .advmanufacturer-link {
      padding-bottom: 47.27%;
      background: $white;
      display: block;

      .advmanufacturer-img {
        @include animated(transform);
        max-width: 100%;
      }

      &:hover .advmanufacturer-img {
        transform: scale(0.9);
      }
    }
  }
}
