.index {
  h1 {
    margin-top: 2rem;
    padding: 0 1.5rem;
  }

  .links-top-category {
    margin-bottom: 1.5rem;

    &::after {
      border-color: $white;
    }
  }
}

.home {
  &-light {
    background-color: $grey-lighter;
    padding: 3rem 0;
  }

  &_small-title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-discount {
    margin-top: 3rem;
  }

  &-reinsurance {
    .icon {
      color: $grey-light;
    }
  }

  &-manufacturers {
    margin-top: 3rem;
    padding: 0 3rem;

    img {
      filter: grayscale(100%);
      opacity: 0.5;
    }

    .owl-carousel {
      .owl-nav .owl-next {
        right: -1.5rem;
      }

      .owl-nav .owl-prev {
        left: -1.5rem;
      }

      .owl-nav .owl-prev,
      .owl-nav .owl-next {
        top: 50%;
        transform: translateY(-50%);

        &:not(.disabled) {
          opacity: 1;
        }

        .icon {
          font-size: 2rem;
          opacity: 1;
          transition: 0.3s;

          &:hover {
            color: $black;
          }
        }
      }

      &:hover {
        .owl-nav .owl-next {
          right: -1.5rem;
        }

        .owl-nav .owl-prev {
          left: -1.5rem;
        }
      }
    }
  }
}

.home-brands {
  margin-top: 3rem;
  padding: 0 1.5rem;

  @media (min-width: $md-width) {
    padding: 0 3rem;
  }

  &__item {
    margin-bottom: 2rem;
    padding: 0 2rem;
  }

  &__img {
    @include animated();
    filter: grayscale(1);
    width: auto;
    opacity: 0.5;
    display: block;
  }

  &__link {
    &:hover .home-brands__img {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
