.page-heading.account {
  .inner {
    background: $blue;
    color: $white;
    margin-bottom: 3rem;
    padding: 1.5rem;

    @media (min-width: $md-width) {
      margin-bottom: 4.5rem;
    }
  }

  .page-heading-title,
  .page-heading-desc {
    color: inherit;
    a {
      font-weight: bold ;
    }
  }

  .page-heading-desc {
    padding: 0;

    @media (min-width: $sm-width) {
      padding: 0 5rem;
    }
    @media (min-width: $md-width) {
      padding: 0 10rem;
    }
  }
}
