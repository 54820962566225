#oneypayment {
  a.continue {
    color: #01025c;
    font-size: 16px;
  }

  #a_mentions_legales {
    color: #999;
  }

  .order_info {
    background: #a19cb7;
    border: 1px solid #ccc;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    padding: 20px;

    p {
      margin: 0;
    }

    .price {
      color: #22205f;
      font-weight: bold;
    }
  }

  .form_hint {
    color: #000;
    font-size: 14px;
    margin-top: 20px;
  }

  .address_title {
    background: #22205f;
    color: #fff;
    font-size: 16px;
    height: 55px;
    line-height: 54px;
    margin-bottom: 20px;
    text-transform: uppercase;

    span {
      color: #fff;
    }
  }

  h3.title {
    font-size: 22px;
    font-style: italic;
    font-weight: normal;
    margin-top: 22px;
    padding: 20px;
  }

  .radio-inline {
    div.radio {
      span {
        top: 2px;
      }
    }
  }

  .checkbox {
    div.radio {
      span {
        top: 2px;
      }
    }
  }

  p.order_confirm {
    color: #22205f;
    font-size: 19px;
  }

  .oney_more_btn {
    clear: both;
    display: block;
  }

  .oney_more {
    clear: both;
    color: #22205f;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  }

  .oney_more_details {
    display: none;
    font-size: 15px;
    margin-top: 20px;

    .title {
      color: #22205f;
      float: left;
      text-transform: uppercase;
    }

    .oney_less {
      color: #22205f;
      float: right;
      margin-left: 10px;
      text-transform: none;
    }

    .desc {
      clear: both;
      display: block;
    }

    ul {
      list-style-type: none;
    }

    li.total {
      color: #22205f;
      text-transform: uppercase;
    }

    li {
      span {
        display: inline-block;
        width: 150px;
      }
    }
  }
}

.oney_shoppingcart {
  float: left;
  margin-bottom: 40px;
  margin-left: 20px;
  width: 50%;

  .oney_bg {
    //background: url('~img/OneyBuleExtSprite_small.png') no-repeat scroll 0 0;
    display: block;
    height: 90px;
    margin-top: -20px;
    width: 130px;
  }

  .oney_bg.OPC3X {
    background-position: 0 0;
  }

  .oney_bg.OPC3XNOFEES {
    background-position: -130px 0;
  }

  .oney_bg.OPC4X {
    background-position: -5px -92px;
  }

  .oney_bg.OPC4XNOFEES {
    background-position: -135px -95px;
  }

  a.oneysim {
    color: #22205f;
    margin-left: 20px;
  }
}

#module-hipay_enterprise-decline,
#module-hipay_enterprise-exception {
  .payment-return {
    width: 60%;
  }
}

#module-oney-payment {
  h1.page-heading {
    background-position: 15px bottom;
    padding: 0 15px 10px 15px;
  }

  p.small.hint {
    color: #999;
    float: right;
    font-size: 14px;
    font-style: italic;
  }

  .fancybox-skin {
    padding: 0 0 20px 0 !important;
  }
}

#module-oney-payment-payment {
  h1.page-heading {
    color: #000;
    font-size: 30px;
    font-style: italic;
    text-shadow: -1px 0 0 #fff;
  }
}

.oney-link {
  color: $black;
  font-weight: 900;
  text-decoration: underline;

  .icon {
    margin-left: 2px;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  .display-panel-oney {
    color: #000;
    font-size: 12px;
    text-decoration: underline;
  }
}
