@import "panel-product-add-conf-pack";
@import "product";

/* displayNav */
.header-cart {
  @media (min-width: $sm-width) {
    min-width: 70px;
  }
}

.panel {
  .panel-accessory {
    color: $orange;
    font-weight: 700;
    line-height: 1.2;
    padding: 1.5rem 0;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

/* Panel recap */
.panel-cart {
  .panel-content {
    padding-bottom: 9rem;

    @media (min-width: $md-width) {
      padding-bottom: 0;
    }
  }

  .panel-extrablock {
    color: $blue-dark;

    .extrablock-title {
      display: block;
      font-weight: 700;
      margin-bottom: 0.3rem;
    }

    .extrablock-link {
      color: inherit;
      display: inline-block;
      font-weight: 700;
      margin-bottom: 0.3rem;
      text-decoration: underline;
    }
  }

  .facilities {
    background: transparent;
    margin: 1.5rem 0 -1.5rem 0;
    padding: 0;

    @media (min-width: $md-width) {
      padding: 1.5rem;
    }
  }

  .panel-buttons {
    @include pof(null, -101%, 0, null);
    background: $white;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.3);
    padding: 1rem 1.5rem;
    transition: all 0.3s ease 0s;
    width: 100%;

    @media (min-width: $xs-width) {
      width: 80%;
    }
    @media (min-width: $sm-width) {
      width: 60%;
    }
    @media (min-width: $md-width) {
      background: transparent;
      box-shadow: none;
      padding: 1.5rem 10.5rem 1.5rem 9.5rem;
      position: relative;
      right: 0;
      width: 100%;
    }
  }

  .panel-recommendations {
    display: none;
  }

  &.open .panel-buttons {
    right: 0;
  }
}

.panel-cart-add {
  .panel-head {
    .panel-title {
      padding: 0;
    }

    .panel-close {
      color: $white;
    }
  }

  .panel-product {
    border: 0;

    .panel-product-prices {
      padding-right: 1.5rem;
    }
  }

  .panel-accessory {
    padding: 1.5rem;

    @media (min-width: $md-width) {
      padding: 1.5rem 10.5rem 1.5rem 9.5rem;
    }
  }

  .panel-facilities .facilities {
    background: $white;
    border: 0.1rem solid $grey-light;
    margin: 0;
  }

  .panel-recommendations {
    display: none;
    position: relative;

    &::before {
      @media (min-width: $md-width) {
        @include poa(null, 0, 0, 0);
        background: $white;
        content: "";
        height: 5rem;
        z-index: 2;
      }
    }

    @media (min-width: $md-width) {
      padding: 2.5rem 10.5rem 2.5rem 9.5rem;
    }

    .panel-recommendations-title {
      @include heading($grey-medium, 2.4, 3, true, false);
      font-weight: 900;
      text-align: center;
    }

    .products-list {
      overflow-x: scroll;
      z-index: 1;
    }

    .product-ctn {
      margin-bottom: 1rem;
      max-width: 50%;
      min-width: 75%;

      @media (min-width: $md-width) {
        min-width: 50%;
      }

      .product-name {
        display: inline-block;
        min-height: 5.5rem;
      }

      .product-desc {
        display: none;
      }

      footer {
        margin-top: 1.5rem;

        .flag-ctn {
          display: none;
        }

        .product-price {
          min-width: 100%;
        }
      }

      .compare {
        display: none;
      }
    }

    .panel-recommendations-arrows {
      @include poa(50%, 3rem, null, 3rem);
    }

    .panel-arrow {
      @include poa();
      @include size(4.5rem);
      border: 0.1rem solid $grey-medium;
      border-radius: 0.2rem;
      color: $grey-medium;
      cursor: pointer;
      display: none;
      font-size: 1.2rem;
      line-height: 4.5rem;
      opacity: 0.4;
      text-align: center;

      @media (min-width: $md-width) {
        display: block;
      }
    }

    .panel-arrow-left {
      left: 0;
    }

    .panel-arrow-right {
      right: 0;
    }
  }
}
