.product-compare {
  &-main {
    overflow: auto;
  }

  &-wrap {
    margin: 0 0 5rem 0;
    text-align: center;
  }

  &-header {
    border-bottom: 0.1rem solid $grey-light;
    border-top: 0.1rem solid $grey-light;
    color: #666;
    margin-bottom: 4.5rem;
    padding: 25px;

    &-title {
      font-size: 3.5rem;
      font-weight: 700;
    }

    &-txt {
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 86rem;
    }
  }

  &-tiles {
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @media (min-width: $sm-width) {
      justify-content: center;
    }
  }

  &-tile {
    border: 0.1rem solid transparent;
    border-radius: 0.3rem;
    overflow: hidden;
    text-align: center;
    width: calc(100% / 5);
    min-width: 150px;

    &-head {
      align-self: center;
    }

    &-title {
      color: $black;
      font-size: 1.4rem;
      font-weight: 700;
      margin-top: 15rem;
      padding: 1.5rem;
      text-align: left;
    }

    &-body {
      padding: 1.5rem 1.5rem 8rem 1.5rem;
      position: relative;

      &.current {
        box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.2);
      }
    }

    .price_reduc {
      &::before {
        @media (max-width: $sm-width + 1) {
          position: static;
          white-space: nowrap;
          width: auto;
        }
      }
    }
  }

  &-box-img {
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
  }

  &-logo {
    max-width: 6rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }

  &-img {
    display: block;
    height: auto;
    width: 100%;
  }

  &-head {
    text-align: left;
  }

  &-title {
    color: $blue-dark;
    font-weight: 700;
    line-height: 1.2;
    min-height: 3.4rem;
  }

  &-box-price {
    padding: 1rem;
    text-align: right;
  }

  &-old-price {
    color: #666;
    display: inline-block;
    font-size: 1.3rem;
    margin-right: 1rem;
    position: relative;

    @media (min-width: $xl-width) {
      font-size: 1.4rem;
      margin-right: 2rem;
    }

    &::before {
      background-color: #666;
      content: "";
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  &-price {
    color: $red;
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 900;

    @media (min-width: $xl-width) {
      font-size: 1.8rem;
    }
  }

  &-add-cart-btn {
    background-color: $blue-dark;
    margin-bottom: 1.5rem;
    padding: 1rem;
    width: 100%;

    @media (min-width: $lg-width) {
      height: 5rem;
      padding: 0 1.5rem;
    }
  }

  &-credit {
    &-btn {
      background-color: $oney-primary-color;
      color: $white;
      height: 5rem;
      margin-bottom: 1.5rem;
      padding: 0 1.5rem 0.1rem;
      width: 100%;

      @media (max-width: $lg-width) {
        height: 10rem;
        padding: 1rem;
      }
    }

    &-box {
      display: block;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 700;
    }

    &-nb {
      background-color: $white;
      border-radius: 50%;
      color: $black;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 700;
      height: 2.5rem;
      line-height: 2.1rem;
      width: 2.5rem;
    }

    &-price {
      font-size: 1.8rem;
      font-weight: 900;

      @media (max-width: $lg-width) {
        display: block;
      }
    }

    &-txt {
      font-size: 1.2rem;
      text-decoration: underline;
    }
  }

  &-availability {
    color: $green;
    font-size: 12px;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  &-desc {
    color: $black;
    font-size: 14px;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  &-card {
    &-box {
      @include poa(null, 1.5rem, 1.5rem, 1.5rem);
      border: 0.1rem solid $black;
      border-radius: 0.3rem;
      padding: 1.5rem;
    }

    &-current {
      border: none;
    }
  }

  &-table {
    margin-top: 2rem;
    display: inline-block;

    @media (min-width: $sm-width) {
      width: 100%;
    }

    &-row {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      @media (min-width: $sm-width) {
        justify-content: center;
      }

      &:nth-child(odd) {
        background-color: $grey-lighter;
      }
    }

    &-cell {
      align-items: center;
      display: flex;
      padding: 1rem 1.5rem;
      text-align: left;
      width: calc(100% / 5);
      min-width: 150px;
    }

    &-head {
      color: $black;
      font-size: 1.4rem;
      font-weight: 700;
    }

    &-txt {
      color: $black;
      font-size: 1.4rem;
      font-weight: 400;
    }

    &-grade {
      border: 0.2rem solid $blue-dark;
      border-radius: 0.6rem;
      color: $blue-dark;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0.2rem 1.2rem 0.4rem 1.2rem;
    }

    &-graph {
      align-items: center;
      display: flex;
    }

    &-note {
      color: $blue-dark;
      font-size: 1.4rem;
      font-weight: 700;
      width: 3.6rem;
    }

    &-stick {
      background-color: #ececec;
      border-radius: 0.3rem;
      height: 0.6rem;
      margin-left: 1rem;
      margin-top: 0.2rem;
      overflow: hidden;
      position: relative;
      width: 12rem;

      @media (max-width: $lg-width) {
        width: 8rem;
      }

      &::after {
        background-color: $blue-dark;
        border-radius: 0.3rem;
        content: "";
        height: 100%;
        position: absolute;
      }

      &.stick-0::after {
        width: 0%;
      }

      &.stick-0-5::after {
        width: 10%;
      }

      &.stick-1::after {
        width: 20%;
      }

      &.stick-1-5::after {
        width: 30%;
      }

      &.stick-2::after {
        width: 40%;
      }

      &.stick-2-5::after {
        width: 50%;
      }

      &.stick-3::after {
        width: 60%;
      }

      &.stick-3-5::after {
        width: 70%;
      }

      &.stick-4::after {
        width: 80%;
      }

      &.stick-4-5::after {
        width: 90%;
      }

      &.stick-5::after {
        width: 100%;
      }
    }
  }
}
