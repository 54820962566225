.panel-cms,
.panel-paypal {
  background: $white;

  .panel-block {
    line-height: 1.3;

    @media (min-width: $md-width) {
      padding-top: 4.5rem;
    }
  }

  strong {
    display: block;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  .ctn {
    width: 100%;
  }
}
