.auth {
  margin-bottom: 3rem;

  &-content {
    margin-top: -1rem;
    overflow: hidden;
    padding: 0;

    @media (min-width: $sm-width) {
      padding: 0 1.5rem;
    }
  }

  input[name="email"] {
    text-transform: lowercase;
  }

  .login-process {
    margin-top: 1rem;

    @media (min-width: $sm-width) {
      margin-top: 5rem;
    }
  }

  .create,
  .login {
    display: none;

    .button {
      margin-bottom: 5px;
      margin-top: 3rem;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
        margin-top: 2px;
      }
    }
  }

  .lost_password {
    font-size: 1.2rem;
    text-align: right;

    @media (min-width: $md-width) {
      margin: -1rem 0 2rem 0;
    }
  }

  .details-appartement {
    display: none;

    &.shown {
      display: block;
    }
  }

  .submit {
    padding: 0 1.5rem;

    @media (min-width: $md-width) {
      padding: 1.5rem 5rem;
    }
    @media (min-width: $lg-width) {
      padding: 1.5rem 10rem;
    }
  }

  .button {
    width: 100%;

    @media (min-width: $md-width) {
      margin-left: 14.5rem;
      width: 55%;
    }
  }

  .cap-address-hint {
    @include poa(-9rem, null, null, 0);
    max-width: 90%;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 0.3s ease 0.2s;
    z-index: 2;

    @media (min-width: $md-width) {
      left: 14.5rem;
      max-width: 50%;
    }

    &::before {
      @include poa(null, null, 1rem, -1rem);
      @include size(2rem);
      background: $blue;
      content: "";
      transform: rotate(45deg);
    }

    &.active {
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .cap-address-error {
    color: $orange;
    font-size: 1.2rem;
    font-style: italic;
    min-width: 100%;
    position: relative;
    top: 0.4rem;

    @media (min-width: $md-width) {
      padding-left: 14.5rem;
    }
  }

  .phone-alert {
    background: $green-light;
  }
}

.address .ac_results,
.authentication .ac_results {
  background: $grey-lighter;
  border: 1px solid $grey-medium;
  border-radius: 0 0 0.3rem 0.3rem;
  box-shadow: none;
  margin-top: 0.5rem;
  padding: 0.8rem 0;
  z-index: 6;

  li.ac_odd,
  li.ac_even {
    background: transparent;
    padding: 0.7rem 1.5rem 0.9rem 1.5rem;

    &.ac_over {
      background: $grey-light;
      color: $grey-medium;
    }
  }
}
