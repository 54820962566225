.newsletter {
  .newsletter-ctn {
    padding: 0.3rem 1.5rem;

    @media (min-width: $xs-width) {
      padding: 0.3rem 0;
    }

    @media (min-width: $md-width) {
      margin: 0;
      padding: 0.3rem 20% 0.3rem 0;

      .form-group {
        margin-bottom: 1.5rem;
      }
    }

    form {
      position: relative;
    }

    .newsletter-input {
      border: 0.1rem solid $grey;
      border-radius: 0;
      height: 5rem;
      padding: 0 40% 0 1.5rem;
    }

    .newsletter-submit {
      @include poa(0, 0, 0);
      padding: 0;
      width: 40%;
    }
  }
}
