.fieldset {
  &-ctn {
    border: 0.1rem solid $grey-light;
    border-radius: 0.3rem;
    margin-bottom: -0.1rem;
    overflow: hidden;

    @media (min-width: $sm-width) {
      margin-bottom: 2.5rem;
    }
  }

  &-header {
    background: $grey-lighter;
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    padding-right: 1.5rem;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
    }

    &-note {
      color: $black;
      display: block;
      font-size: 1.2rem;
      font-weight: 400;

      @media (min-width: $md-width) {
        display: inline;
        font-size: 1.4rem;
        margin-left: 2rem;
      }
    }

    &-back {
      align-self: center;
      background: $blue-dark;
      border-radius: 0.3rem;
      color: $white;
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0.6rem 0.8rem;

      @media (min-width: $sm-width) {
        font-size: 1.4rem;
        padding: 0.8rem 2.5rem;
      }

      &:hover {
        background: $blue;
        color: $white;
        text-decoration: none;
      }
    }

    &-step {
      align-self: center;
      border-right: 0.1rem solid $grey-light;
      padding: 1.2rem 1.8rem;

      @media (min-width: $sm-width) {
        padding: 2.3rem 3rem;
      }
    }

    &-title {
      align-self: center;
      flex-grow: 2;
      padding: 0.6rem 1.8rem;

      @media (min-width: $md-width) {
        padding: 2.3rem 3rem;
      }
    }
  }

  &-group {
    border-top: 0.1rem solid $grey-light;
    padding: 1rem;

    &.no-border {
      border: 0;
    }

    &-title {
      color: $black;
      display: inline-block;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    &-desc {
      display: block;
      font-size: 1.1rem;
      margin-top: -1.5rem;

      @media (min-width: $md-width) {
        font-size: 1.3rem;
      }
    }
  }

  &-inner {
    margin: 0 -1rem;
    padding: 0.5rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 1.5rem 5rem;
    }
    @media (min-width: $lg-width) {
      padding: 1.5rem 10rem;
    }
  }
}
