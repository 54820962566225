.trigger {
  cursor: pointer;
}

.btn-unstyled {
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: $muli;
  line-height: 1;
}

.button {
  background: $blue-dark;
  border: 0;
  border-radius: 0.3rem;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $muli;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  overflow: hidden;
  padding: 1.7rem 2.5rem;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease 0s;

  @media (min-width: $sm-width) {
    font-size: 1.6rem;
    padding: 1.5rem 3rem 1.6rem 3rem;
  }

  &:hover {
    background-color: $blue;
    color: $white;
    text-decoration: none;
  }

  &.button2 {
    background: $white;
    border-color: $white;
    color: $black;

    &:hover {
      background-color: $black;
      border-color: $white;
      color: $white;
    }
  }

  &.button7 {
    background: $green;
    border: 1px solid $green;
    color: $white;

    &:hover {
      background-color: darken($green, 10);
      border-color: darken($green, 10);
    }
  }

  &.button3,
  &.button--disabled {
    background: $grey;
    border-color: $grey;
    color: $white;
  }

  &.button--disabled {
    &:hover {
      background: $grey;
      border-color: $grey;
      color: $white;
    }
  }

  &.button3 {
    &:hover {
      background-color: $white;
      border-color: $grey;
      color: $grey;
    }
  }

  &.button4 {
    &:hover {
      background-color: $white;
      border-color: $blue;
      color: $blue;
    }
  }

  &.button5 {
    background: transparent;
    border: 1px solid $grey;
    color: $white;

    &:hover {
      background-color: $grey;
      border-color: $grey;
      color: $white !important;
    }
  }

  &.button6 {
    background: $grey-light;
    border: 1px solid $grey-light;
    color: $grey;

    &:hover {
      background-color: $grey;
      border-color: $grey;
      color: $white;
    }
  }

  &.button8 {
    background: $white;
    border-color: $white;
    color: $blue-dark;

    &:hover {
      background-color: $grey-lighter;
      border-color: $grey-lighter;
    }
  }

  &.button9 {
    background: $white;
    border: 1px solid $blue-dark;
    color: $blue-dark;

    &:hover {
      background-color: $blue-dark;
      border-color: $blue-dark;
      color: $white;
    }
  }

  &.button-seemore {
    background: $grey-light;
    border-color: $grey-light;
    color: $grey-medium;
    padding: 1.4rem 3rem;

    @media (min-width: $sm-width) {
      padding: 1.5rem 5rem;
    }

    span {
      font-size: 1.2rem;
      padding-left: 0.5rem;
      position: relative;
      top: -0.1rem;
    }
  }

  &.button-fw {
    text-align: center;
    width: 100%;
  }

  &.button-sm {
    height: auto;
    line-height: 1.2rem;
    padding: 1.5rem 1.3rem;
  }

  &.button-xs {
    font-size: 1.2rem;
    height: auto;
    line-height: 1.2rem;
    padding: 1rem 2.3rem;
  }

  &.button-arrow {
    padding-right: 4rem;
    position: relative;

    &::after {
      @include animated();
      @include poa(50%, 1rem);
      content: "\e920";
      font-family: icomoon;
      font-weight: normal;
      transform: translateY(-50%);
    }

    &:hover {
      &::after {
        right: 1.5rem;
      }
    }
  }

  .icon {
    margin-left: 0.5rem;
    position: relative;
    top: 0.1rem;
  }
}

.button-link {
  color: $grey;
  font-weight: 700;
}
