.fancybox-overlay {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;
}

.fancybox-wrap {
  background-color: $white;
  font-size: 16px;
  left: 50% !important;
  padding: 30px 40px;
  transform: translateX(-50%);
  z-index: 100001;

  @media (max-width: 480px) {
    padding: 20px;
    width: 80% !important;
  }

  .fancybox-inner {
    height: auto !important;
    max-width: 450px;
    overflow: visible !important;
    width: 100% !important;
  }

  .fancybox-close {
    &:before {
      content: "\e905";
      font-family: "icomoon";
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: color .3s ease-in-out;
    }

    &:hover:before {
      color: $blue-dark;
    }
  }
}
