// Margin and Padding
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);

    @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    /* stylelint-disable */
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length;
                }
                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length;
                }
                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length;
                }
                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length;
                }
                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length;
                }
            }
        }

        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size, $length in $spacers {
            @if $size != 0 {
                .m#{$infix}-n#{$size} {
                    margin: -$length;
                }
                .mt#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-top: -$length;
                }
                .mr#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-right: -$length;
                }
                .mb#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-bottom: -$length;
                }
                .ml#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-left: -$length;
                }
            }
        }

        // Some special margin utils
        .m#{$infix}-auto {
            margin: auto;
        }
        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-top: auto;
        }
        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-right: auto;
        }
        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-bottom: auto;
        }
        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-left: auto;
        }
    }
}
