.pagination {
  margin: 3rem 0 8rem 0;
  overflow: auto;

  &-item {
    display: block;

    &.pagination_previous,
    &.pagination_next {
      display: block;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.current .pagination-link {
      background: $grey-light;
      color: $black;
    }

    &:hover .pagination-link {
      background: $grey-light;
      text-decoration: none;
    }
  }

  &-link {
    @include animated(background);
    background: $grey-lighter;
    border-radius: 0.2rem;
    color: $grey-medium;
    display: block;
    font-size: 1.3rem;
    font-weight: 700;
    margin-left: 1rem;
    padding: 0.5rem 1rem;

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
      padding: 1rem 1.5rem;
    }
  }
}
