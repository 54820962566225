.breadcrumb {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  overflow: auto;

  @media (min-width: $md-width) {
    margin-bottom: 0.5rem;
  }

  .icon {
    font-size: 1rem;
    padding: 0.3rem 0.8rem 0 0;

    + .ariane {
      display: flex;
    }
  }

  .navigation-pipe {
    padding: 0 0.4rem;
  }

  a {
    color: inherit;
  }
}
