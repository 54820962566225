/* Bloc filtres style 1 */
.advconstructor-style1 {
  margin-bottom: 2.5rem;
  min-width: 47%;
  text-align: center;

  @media (min-width: $sm-width) {
    min-width: unset;
    text-align: left;
  }

  .advconstructor-style1-img-ctn {
    height: 4rem;
    margin: 0 0 0.5rem 0;
    text-align: center;
    width: 3rem;

    @media (min-width: $sm-width) {
      margin: 0;
      text-align: left;
    }

    .advconstructor-style1-img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .content-ctn {
    background-color: #fff;
    flex-direction: column;
    height: 100%;
    padding: 1rem;

    @media (min-width: $sm-width) {
      flex-direction: row;
      padding: 2rem;
    }

    .content .title {
      font-weight: bold;
    }

    .content .subtitle {
      font-size: 1.2rem;
    }
  }

  &.without-img .content-ctn {
    text-align: center;
  }
}

/* Bloc filtres style 2 */
.advconstructor-style2 {
  margin-bottom: 1.5rem;
  text-align: left;

  @media (min-width: $sm-width) {
    margin-bottom: 3rem;
  }

  .advconstructor-style2-bg {
    @include animated(transform, 5s);
    @include poa(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .advconstructor-style2-content {
    height: 100%;
    overflow: hidden;
    position: relative;

    .content-ctn {
      background-repeat: no-repeat;
      color: #fff;
      flex-direction: column;
      height: 100%;
      padding: 2rem 1.5rem;
      position: relative;
      text-decoration: none;

      @media (min-width: $xs-width) {
        padding: 3rem 2.5rem;
      }
      @media (min-width: $sm-width) {
        padding: 4rem 3.2rem;
      }
      @media (min-width: $md-width) {
        padding: 3rem;
      }

      .overtitle {
        font-size: 1.3rem;
        letter-spacing: -0.02rem;
        text-transform: uppercase;

        @media (min-width: $xs-width) {
          font-size: 1.4rem;
          letter-spacing: 0.15rem;
        }
        @media (min-width: $md-width) {
          height: 4rem;
        }
        @media (min-width: $lg-width) {
          height: 3.5rem;
        }
        @media (min-width: $lg-width) {
          height: auto;
        }
      }

      .title {
        @include heading($white, 2.5, 0, 900, false);
        line-height: 3rem;
        text-decoration: none;

        @media (min-width: $xs-width) {
          font-size: 2.8rem;
          line-height: 3.8rem;
          margin-top: 1rem;
        }
        @media (min-width: $md-width) {
          font-size: 2.5rem;
          line-height: 3rem;
          margin-top: 1rem;
        }
        @media (min-width: $lg-width) {
          font-size: 2.8rem;
          line-height: 3.8rem;
          margin-top: 1rem;
        }
      }

      .label-btn {
        color: $blue-dark;
        margin-top: 2.5rem;
        width: 75%;

        @media (min-width: $xs-width) {
          margin-top: 4rem;
          width: auto;
        }
      }

      &:hover .button-arrow {
        background-color: #fff;

        &:hover {
          background-color: #fff;
          color: $blue-dark;
        }
      }
    }
  }

  &:last-child {
    @media (min-width: $sm-width) {
      flex-basis: 100%;
      max-width: 100%;
    }
    @media (min-width: $md-width) {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
  }

  &:hover {
    .advconstructor-style2-bg {
      transform: scale(1.2);
    }
  }
}

/* Bloc filtres style 3 */
.advconstructor-style3 {
  margin-bottom: 2rem;
  min-width: 90%;

  @media (min-width: $xs-width) {
    min-width: 70%;
  }
  @media (min-width: $sm-width) {
    min-width: 33.33333%;
  }
  @media (min-width: $lg-width) {
    min-width: 25%;
  }

  .content-ctn {
    @include animated(border-color);
    border: 0.1rem solid $grey-light;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    text-decoration: none;

    &:hover {
      border-color: $grey-medium;
    }
  }

  .advconstructor-style3-content {
    .title {
      @include heading($blue-dark, 2, 0, 900, false);
      margin-top: 1rem;

      @media (min-width: $sm-width) {
        line-height: 1.1;
        padding: 1rem 3rem 3rem 3rem;
      }
    }

    .content {
      text-align: left;
    }

    .advconstructor-style3-img {
      margin: 0 auto;
      max-width: 8rem;
    }
  }

  .advconstructor-style3-btn {
    color: $blue-dark;
    text-align: left;

    .button {
      background: transparent;
      color: $blue-dark;
      line-height: 1;
      padding: 1.5rem 4rem 1.7rem 0;
    }
  }

  &.full-img {
    min-width: 70%;

    @media (min-width: $xs-width) {
      min-width: 33.33333%;
    }
    @media (min-width: $sm-width) {
      min-width: 33.33333%;
    }
    @media (min-width: $lg-width) {
      min-width: 25%;
    }

    .advconstructor-style3-head {
      margin: -2rem -2rem 2rem -2rem;
      padding: 0;

      .advconstructor-style3-img {
        margin: 0;
        max-width: 100%;
      }
    }

    .advconstructor-style3-content {
      margin-bottom: 2rem;
    }
  }
}

.multiline {
  .advconstructor-style1:last-child {
    margin-right: auto;

    @media (min-width: $lg-width) {
      margin-right: 0;
    }
  }

  .advmanufacturer-item:last-child,
  .advconstructor-style3:last-child {
    margin-right: auto;
  }
}
