.page-heading {
  .page-heading-desc {
    a {
      font-weight: bold ;
      color: #000;
    }
  }

  .inner {
    background: $grey-lighter;
    padding: 1.5rem 0;

    @media (min-width: $md-width) {
      padding: 1rem 0;
    }
  }

  &-title {
    @include heading($blue-dark, 2, 0, 900, false);

    @media (min-width: $sm-width) {
      font-size: 3rem;
    }
    @media (min-width: $sm-width) {
      font-size: 4rem;
      margin: 1.5rem 0 1rem 0;
    }
  }

  &-desc {
    color: $black;
    line-height: 1.4;
    margin-bottom: 2rem;

    @media (min-width: $sm-width) {
      font-size: 1.55rem;
      line-height: 2.3rem;
    }
  }
}
