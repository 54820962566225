.panel-product {
  .ope30-active {
    .panel-product-current-price {
      display: inline-block;
      font-size: 1.5rem;
    }

    .panel-product-ope30 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .panel-product-ope30-txt-haut {
      font-size: 10px;
      display: inline-block;
    }

    .panel-product-ope30-txt {
      font-size: 10px;
    }

    .panel-product-ope30-value {
      color: $red;
      margin-left: 5px;
      font-size: 1.7rem;
    }
  }
}
