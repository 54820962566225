.advblocklinks {
  font-family: $muli;
  font-weight: 700;

  .advblocklink {
    ul {
      display: block;
      margin-bottom: 0;
      padding: 0 0 15px 0;
    }

    .advblocklink-link {
      display: inline-block;
      font-size: 12px;
      padding: 3px 15px;

      @media (min-width: $xs-width) {
        padding: 3px 0;
      }
      @media (min-width: $lg-width) {
        font-size: 14px;
        padding: 3px 0;
      }

      &:hover {
        color: $black;
      }
    }
  }
}
