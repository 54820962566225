.product-images-tools {
  @media (max-width: $sm-width) {
    flex-direction: column;
    align-content: center;
  }

  .product-tools-right {
    .video-list {
      #video_list_frame {

        li {
          transition: opacity 0.3s ease 0s;
          border-left: 0.1rem solid #dadada;
          height: 3em;

          &:first-of-type {
            border-left: none;
          }

          a {

            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: center;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            speak: none;
            text-transform: none;
            text-decoration: none;
            color: #656565;
            height: 100%;
            padding: 0.3em;
            transform: scaleY(0.9);

            &::before {
              font-family: "icomoon" !important;
              font-size: 0.6em;
              content: "\e915";
              transform: rotate(-90deg);
              display: block;
              margin-bottom: 1em;
            }

            &:hover {
              color: #494949;
            }
          }
        }
      }
    }
  }
}

.owl-carousel {
  .owl-dots {
    display: none;
  }

  .owl-item {
    .item-video {
      /* height: 100%; */
    }

    .owl-video-play-icon {
      background: url(~img/owl.video.play.png) no-repeat;
      margin-left: unset;
      margin-top: unset;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }

  .owl-video-tn {
    background-size: cover;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
  }

  .owl-video-frame {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
