/* page Creation de compte */
.authentication {

    .gdpr_consent {
        .fieldset-inner {
            .no-label {
                padding-left: 14.5rem;

                label {
                    color: $black;
                }

                input[name="psgdpr-consent"] {
                    appearance: auto;
                    -webkit-appearance: auto;
                }
            }
        }

    }
}

/* page Contactez-nous */
#contact {
    .gdpr_consent {
        .fieldset-inner {
            .checkbox {
                margin-left: 4.5rem;

                input {
                    border: 1px solid #dadada;
                    border-radius: 0.3rem;
                    color: #989898;
                }
            }
        }
    }
}

/* page Mon Compte > RGPD */
#module-psgdpr-gdpr {
    .account-content {
        &.gdpr-content {
            margin-bottom: 3rem;

            h3 {
                font-size: 2rem;
                margin-bottom: 1rem;
            }

            .gdpr-content-access {

                margin-bottom: 4rem;

                .gdpr-content-link {
                    display: flex;
                    justify-content: center;
                    margin-top: 2rem;

                    a {
                        &.psgdprgetdatabtn16 {
                            height: 2rem;
                            padding: 2rem;
                            display: flex;
                            align-items: center;
                            border-radius: 2rem;
                            background-color: $blue;
                            color: $yellow;
                            margin: auto 2rem;
                        }
                    }
                }
            }

        }
    }
}