.store-map-container {
  position: relative;

  .ajax-loader-ctn {
    @include poa(50%, 50%);
    display: none;
    opacity: 0;
    transform: translate(50%, -50%);
    transition: all ease-in-out 0.2s;
    z-index: 2;

    &.show {
      display: block;
      opacity: 1;
    }
  }

  .map {
    height: 250px;
    margin-bottom: 26px;
    width: 100%;

    @media (min-width: $md-width) {
      height: 430px;
      margin-right: 20px;
    }
  }
}

.stores-filters {
  &-column {
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: $sm-width) {
      text-align: left;
    }
  }

  &-title {
    color: $black;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &-button {
    @media (min-width: $sm-width) {
      display: none;
    }
  }

  &-list {
    display: none;
    margin-top: 2rem;
    padding: 0 1rem;

    @media (min-width: $sm-width) {
      display: block;
      padding: 0;
    }
  }

  &-item {
    padding: 0.3rem 0;
  }
}

.stores-title {
  font-size: 1.8rem;
  font-weight: 900;
  margin-bottom: 1rem;

  @media (min-width: $sm-width) {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.store-block {
  border: 1px solid #d6d4d4;
  border-radius: 4px;
  margin-bottom: 30px;

  &-template {
    display: none;
  }

  &-header {
    background: #efefef;

    @media (min-width: $sm-width) {
      border-bottom: 0.1rem solid $grey-light;
    }
  }

  &-title {
    color: $blue-dark;
    display: inline-block;
    font-weight: 900;
    padding: 0.5rem 1rem;

    @media (min-width: $sm-width) {
      font-size: 1.7rem;
      padding: 0.4rem 2.5rem;
    }
  }

  &-link {
    border-left: 0.1rem solid #d6d4d4;
    display: inline-block;
    font-size: 12px;
    padding: 1.5rem;

    @media (min-width: $sm-width) {
      padding: 1.2rem 1.5rem;
    }

    .label {
      display: none;
      font-size: 1.4rem;
      font-weight: 700;
      padding-right: 1rem;

      @media (min-width: $sm-width) {
        display: inline-block;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-inner {
    border-bottom: 0.1rem solid $grey-light;
    font-size: 1.2rem;
    line-height: 2.5rem;
    padding: 1.5rem 0;

    @media (min-width: $sm-width) {
      border: 0;
    }
    @media (min-width: $md-width) {
      padding: 20px 0;
    }
  }

  &-services {
    display: flex;
    flex-wrap: wrap;

    &-item {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      height: 3.5rem;
      min-width: 50%;
      padding: 0 1.5rem;
      position: relative;

      @media (min-width: $md-width) {
        height: 3rem;
        min-width: 100%;
      }
      @media (min-width: $lg-width) {
        height: 3.5rem;
        min-width: 50%;
      }
    }

    .icon {
      &::before {
        font-family: "icomoon" !important;
        font-size: 18px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    span {
      line-height: 1;
      padding-left: 0.7rem;
    }

    .bg-free-parking::before {
      content: "\e91d";
    }

    .bg-calendar::before {
      content: "\e91c";
    }

    .bg-hours::before {
      content: "\e91b";
    }

    .bg-bus::before {
      content: "\e913";
    }

    .bg-destocking-space::before {
      content: "\e914";
    }
  }

  &-address {
    color: $black;
    line-height: 1.6;

    .postcode,
    .city {
      display: inline-block;
    }
  }

  &-subtitle {
    color: $grey;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.1rem;
    padding: 0 2rem;
    position: relative;
    text-transform: uppercase;

    @media (min-width: $sm-width) {
      cursor: initial;
    }
    @media (min-width: $md-width) {
      padding: 0;
    }

    .icon {
      @include poa(50%, 2rem);
      font-size: 1rem;
      transform: translateY(-50%);

      @media (min-width: $sm-width) {
        display: none;
      }
    }

    &.open .icon::before {
      content: "\e90a";
    }
  }

  &-content {
    display: none;
    margin-top: 1.5rem;
    padding: 0 2rem;

    @media (min-width: $sm-width) {
      display: block;
    }
    @media (min-width: $md-width) {
      padding: 0;
    }
  }

  &-hours {
    border: 0;

    .schedule-line {
      color: $black;
      line-height: 1.4rem;
      margin: 0 -0.5rem;
      padding: 0.5rem 0.5rem;

      @media (min-width: $md-width) {
        margin-right: 20px;
      }

      &.current {
        background: $green-light;
      }
    }

    .day {
      display: inline-block;
      text-align: left;
      vertical-align: top;
      width: 50%;
    }

    .hours {
      display: inline-block;
      text-align: right;
      width: 50%;

      @media (min-width: $md-width) {
        text-align: left;
      }
    }
  }

  &-image {
    padding: 2rem 2rem 1rem;

    @media (min-width: $md-width) {
      border-right: 0.1rem solid #d6d4d4;
      height: 100%;
      padding: 2rem 2.5rem 1rem 2.5rem;
    }

    img {
      margin-bottom: 5px;
      width: 100%;
    }
  }

  &-map-infos {
    margin-top: 20px;

    .store-complement-link {
      color: $blue-dark;
      display: block;

      .icon {
        display: inline-block;
        font-size: 1.6rem;
        position: relative;
        text-decoration: none;
        top: 0.2rem;
        width: 2.2rem;
      }

      &.box-click-and-test {
        margin: 0;
      }
    }
  }

  .info-window {
    display: none;
  }
}

.gm-style .gm-style-iw-c {
  max-height: 400px !important;
}

.gm-style .gm-style-iw-d {
  max-height: 400px !important;
}

.info-window {
  padding: 5px;
  position: relative;
  width: 400px;
}

.title-mag {
  color: $blue-dark;
  float: left;
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  width: 380px;
}

.img-mag {
  float: left;
  height: 50px;
  margin-right: 10px;
  width: 78px;
}

.txt-mag {
  float: left;
  font-size: 14px;
  margin: 0;
  min-height: 50px;
  width: 290px;

  .address1 {
    display: block;
  }
}

.iti-tel {
  float: left;
  margin: 5px 0;
  width: 100%;

  .tel-mag {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
    text-decoration: none;
  }
}

.horaire {
  strong {
    display: block;
    font-size: 14px;
    font-weight: bold;
  }

  .schedule-line {
    font-size: 12px;

    strong {
      display: inline;
      font-weight: normal;
    }
  }
}

.link-fiche-mag {
  background: #130c5c;
  border-radius: 4px;
  clear: both;
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
