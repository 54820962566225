.carriers-list {
  margin-bottom: 1.5rem;

  @media (min-width: $md-width) {
    margin-bottom: 2.5rem;
  }

  @media (min-width: $lg-width) {
    padding: 20px;
  }

  .delivery_options {
    margin: 1.5rem 0;

    @media (min-width: $md-width) {
      margin-bottom: 4rem;
    }

    .option {
      & + .option {
        margin-top: 50px;
      }
    }
  }
}

.carriers-cart-content {
  .fieldset-group-title {
    font-size: 1.6rem;
  }

  .fieldset-group-desc {
    font-size: 14px;
  }
}

.carrier-item {
  color: $black;
  margin-right: 0;
  padding: 1rem 0;

  &-img img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  &-infos {
    @media (min-width: $md-width) {
      margin-top: -0.2rem;
    }

    &-name {
      display: block;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 5px;

      @media (min-width: $md-width) {
        font-size: 1.6rem;
      }
    }

    &-desc {
      color: $grey-medium;
      font-size: 1.2rem;

      @media (min-width: $md-width) {
        font-size: 1.4rem;
      }
    }
  }

  &-price {
    font-size: 1.4rem;
    font-weight: 700;
    padding-left: 0.5rem;
    text-align: right;
  }

  &-flag {
    color: $blue-dark;
    white-space: nowrap;

    &.old {
      position: relative;

      &::before {
        content: "";
        border-top: 1px solid $blue-dark;
        transform-origin: center;
        transform: rotate(-15deg) translateY(-50%);
        position: absolute;
        inset: 50% -10% auto auto;
        width: 120%;
        display: block;
      }
    }

    &.free {
      color: $green;
      margin-left: 15px;
    }
  }
}

.carrier-item-services {
  display: flex;
  flex-direction: column;
}

.service {
  &-content {
    .custom-select {
      flex-grow: inherit;
    }
  }

  &-error {
    color: $red;
    margin-top: 5px;
  }
}

.clearance {
  margin-bottom: 20px;

  @media (min-width: $lg-width) {
    padding-left: 50px;
  }

  .service-option {
    border-top: 1px solid $grey-light;
    margin-top: 30px;
    padding-top: 10px;
  }

  .service-name {
    color: $green;
    margin-bottom: 5px;
    display: block;
  }
}

.assembly {
  background-color: $grey-lighter;
  padding: 30px;

  @media (min-width: $lg-width) {
    padding-right: 80px;
    padding-left: 80px;
  }

  &-ctn {
    margin: 0 -3rem;

    &::after {
      content: "";
      border-bottom: 1px solid $grey-light;
      margin-top: 30px;
      width: 100%;
      display: block;
    }
  }

  &-quantity {
    border: 1px solid $grey-light;
    border-radius: 4px;
    width: 45px;
    height: 39px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }

  &-price {
    color: $blue-dark;
  }

  &-total {
    margin-top: 30px;

    @media (min-width: $lg-width) {
      margin-right: -50px;
    }
  }

  .service {
    &-option {
      &-content {
        p {
          margin-bottom: 5px;
        }
      }
    }
  }
}
