.page-heading.order {
  margin-bottom: 0.5rem;

  @media (min-width: $sm-width) {
    margin-bottom: 4rem;
  }

  .inner {
    @media (min-width: $md-width) {
      padding: 3rem 0 2rem 0;
    }
  }

  .breadcrumb {
    @include poa(50%, null, null, 1.5rem);
    transform: translateY(-50%);

    @media (min-width: $xl-width) {
      position: static;
      transform: translateY(0);
    }

    &-label {
      display: none;

      @media (min-width: $xs-width) {
        display: inline;
      }
    }
  }

  .page-heading-title {
    margin: 0;
  }
}
