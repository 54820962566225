%form-notif {
  @include poa(null, 1.5rem, 1.6rem, null);
  content: "";
  font-family: "icomoon";
  font-size: 1.2rem;
  z-index: 3;
}

.form-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  position: relative;

  @media (min-width: $md-width) {
    margin-bottom: 2rem;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  label {
    color: $grey-medium;
    margin-bottom: 0.5rem;
    width: 100%;

    @media (min-width: $md-width) {
      width: 14.5rem;
    }

    sup {
      color: $orange;
    }
  }

  &.no-label {
    @media (min-width: $md-width) {
      padding-left: 14.5rem;
    }
  }

  .form-control {
    border: 1px solid $grey-light;
    border-radius: 0.3rem;
    color: lighten($grey-medium, 20);
    flex-grow: 2;
    font-size: 1.4rem;
    height: 4.5rem;
    padding: 0 1.5rem;
    position: relative;
    width: 100%;

    @media (min-width: $xs-width) {
      max-width: 100%;
      width: auto;
    }

    &[type="password"] {
      letter-spacing: 0.7rem;
    }

    &:focus {
      border-color: $grey-medium;
    }
  }

  textarea.form-control {
    height: auto;
    padding: 1.5rem;
  }

  .toggle-passwd {
    @include poa(null, 1.5rem, 1rem, null);
    cursor: pointer;
    font-size: 2rem;
    transition: right 0.2s ease 0s;
  }

  &.form-ok,
  &.form-error {
    .toggle-passwd {
      color: $grey-medium;
      right: 4rem;
    }
  }

  &.form-error,
  & .form-error {
    &::before {
      @extend %form-notif;
      color: $orange;
      content: "\e905";
    }

    input {
      border-color: $orange;
    }
  }

  &.form-ok,
  & .form-ok {
    &::before {
      @extend %form-notif;
      color: $green;
      content: "\e911";
    }

    input {
      border-color: $green;
    }
  }
}
