.custom-checkbox {
  display: flex;
  position: relative;

  input {
    @include poa(0, null, null, 0);
    @include size(2rem);
    cursor: pointer;
    margin: 0;
    opacity: 0;
    z-index: 3;
  }

  label {
    color: $grey-medium;
    cursor: pointer;
    padding: 0 1rem 0 3rem;
    width: 100%;

    @media (min-width: $md-width) {
      padding-right: 3rem;
    }
  }

  &::before {
    @include poa(0, null, null, 0);
    @include size(1.5rem);
    background: $white;
    border: 1px solid $grey-medium;
    content: "";
    cursor: pointer;
    z-index: 1;
  }

  &::after {
    @include animated(opacity);
    @include poa(0, null, null, 0);
    @include size(1.5rem);
    color: $black;
    content: "\e911";
    cursor: pointer;
    font-family: "icomoon";
    font-size: 0.8rem;
    line-height: 1.5rem;
    opacity: 0;
    text-align: center;
    z-index: 2;
  }

  &.disabled{
    &::before {
      border: 1px solid #999999;
    }
    &::after{
      color: #999999;
    }
  }

  &.checked {
    label {
      color: $black;
    }

    &::before {
      border-color: $black;
    }
  }

  &.checked::after {
    opacity: 1;
  }
}
