.shopping-cart-extra {
  background: $orange;
  border-radius: 0.3rem;
  color: $white;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 1.5rem 0.75rem 0 0;
  padding: 1rem 1rem 1rem 4rem;
  position: relative;

  @media (min-width: $sm-width) {
    margin: 2.5rem 0 0 0;
  }

  > .icon {
    @include poa(0.7rem, null, null, 1rem);
    font-size: 1.8rem;
  }

  &-reminder {
    display: block;
    margin-bottom: 1rem;

    @media (min-width: $lg-width) {
      display: inline-block;
      margin: 0;
    }
  }

  &-link {
    color: $white;
    margin-bottom: 1rem;

    @media (min-width: $lg-width) {
      float: right;
    }

    .icon {
      font-size: 1rem;
      margin-left: 0.7rem;
      position: relative;
      text-decoration: none;
      top: 0.1rem;
    }

    &:hover {
      color: $white;
    }
  }
}
