.advconstructor-listing-img {
  margin-bottom: 6rem;
  position: relative;

  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .advconstructor-content-ctn {
    padding-bottom: 152%;
    display: block;
    width: 100%;
  }
}

.category-gooddeal .advconstructor-listing-img {
  @media (min-width: $md-width) {
    max-width: 25%;
  }
}
