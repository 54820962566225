.panel-compare {
  background: $white;
  display: none;
  right: -86%;
  width: 85%;

  @media (min-width: $xl-width) {
    display: block;
    right: -99%;
    width: 98%;
  }
  @media (min-width: 1650px) {
    right: -161rem;
    width: 160rem;
  }
}

.product-ctn {
  &-compare {
    @include poa(null, 3rem, 0, 3rem);
    display: none;

    @media (min-width: $xl-width) {
      display: block;
    }

    .add_to_compare {
      color: $grey;
      display: block;
      font-size: 1.2rem;
      outline: none;
      padding: 0.4rem 0.7rem 0.4rem 2.5rem;
      position: relative;
      text-decoration: none;

      &::before {
        @include poa(50%, null, null, 0);
        @include size($heightCheckbox);
        background-color: $white;
        border: 0.1rem solid $grey-light;
        content: "";
        cursor: pointer;
        display: block;
        transform: translateY(-50%);
        z-index: 1;
      }

      &::after {
        @include animated(opacity);
        @include poa(50%, null, null, 0.25rem);
        color: $grey;
        content: "\e911";
        cursor: pointer;
        display: block;
        font-family: "icomoon";
        font-size: 8px;
        line-height: 1;
        opacity: 0;
        transform: translateY(-50%);
        width: 1rem;
        z-index: 2;
      }

      &.checked::after {
        opacity: 1;
      }
    }
  }
}

.comparator {
  display: none;
  @include pof(null, 0, -100px, 0);
  z-index: 30;
  background:$white;
  margin-bottom: 0;
  padding: 10px 0;
  box-shadow:0px 0px 20px 0px rgba(0, 0, 0, 0.2);


  @media (min-width: $xl-width) {
    display: block;
  }

  .title {
    @include small-title;
    margin-right: 15px;

    + p {
      color: $black;
      font-size: 1.4rem;
      margin-top: 2px;
    }
  }

  form {
    border-left:1px solid $grey-light;
    padding-left: 30px;
    .clear_compare {
      @include link-style;
    }

    .button {
      padding-bottom: 15px;
      padding-top: 15px;

      &[disabled] {
        opacity:.2;
        pointer-events: none;
      }
    }
  }

  &.open {
    bottom:0;
  }
}

.comparison {
  .product-ctn {
    margin-bottom: 0;

    &-flag {
      display: none;
    }

    .cmp_remove {
      @include poa(1.5rem, 1.5rem);
      color: $blue-dark;
      font-size: 2rem;
      z-index: 2;

      .icon {
        margin-left: 0.5rem;
      }
    }

    &-compare {
      display: none;
    }
  }

  .comparison-features-title {
    @include heading($white, 1.8, 0, 700, false);
    background: $blue;
    padding: 2rem 1.5rem;
  }

  .feature-line {
    background: $grey-lighter;
    padding: 1.5rem 0;

    &.feature-line1 {
      background: $white;
    }
  }

  .feature-value {
    padding: 0 3rem;
  }

  .feature-name {
    font-weight: 700;
    padding-left: 1.5rem;
  }

  .product-compare-table-cell {
    width: calc(100% / 4);
  }

  .product-compare-table-row {
    justify-content: left;
  }
}
