.stores-landing {
  .breadcrumb {
    @extend %breadcrumb;
    background: none;
    padding-top: 1rem;
    width: 100%;
  }

  &-header {
    border: 0;
    padding-top: 0;
  }

  &-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 13rem;
    position: relative;

    @media (min-width: $sm-width) {
      min-height: 20rem;
    }
    @media (min-width: $md-width) {
      @include poa(0, 50%, 0, 0);
    }

    .icon-decoration {
      @include poa(null, 50%, 0);
      color: $yellow;
      font-size: 5rem;
      transform: translate(50%, 50%);

      @media (min-width: $sm-width) {
        font-size: 7rem;
      }
      @media (min-width: $md-width) {
        @include poa(50%, 0, auto);
        font-size: 10rem;
        transform: translate(50%, -50%);
      }
    }
  }

  &-title {
    background: url("~img/store-landing-title.png") center center no-repeat;
    background-size: 25rem;
    padding: 5rem 0;
    text-align: center;

    @media (min-width: $md-width) {
      font-size: 3rem;
      margin: 3rem 0;
      padding: 3rem 2rem;
    }
    @media (min-width: $lg-width) {
      background-size: auto;
      font-size: 4rem;
      padding: 6.5rem 6rem 5.5rem 6rem;
    }
  }

  &-text {
    @include animated();
    border: 0.1rem solid $white;
    border-radius: 0.4rem;
    color: $white;
    display: block;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    padding: 1.5rem;
    text-align: center;
    text-decoration: none;

    &:hover {
      background: $white;
      color: inherit;
    }
  }

  &-input {
    @media (min-width: $sm-width) {
      padding: 0 6rem;
    }
  }

  &-intro {
    padding: 0 1.5rem 2rem 1.5rem;

    .advconstructor-left,
    .advconstructor-right {
      padding: 2rem 0.75rem 0 0.75rem;

      @media (min-width: $xs-width) {
        padding: 2rem 1.5rem 0 1.5rem;
      }
      @media (min-width: $md-width) {
        padding: 6.5rem 6rem 5.5rem 6rem;
      }
    }
  }

  &-cms.advcms-list {
    padding: 2rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 6rem 1.5rem;
    }
  }
}
