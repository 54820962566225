/* Conteneur des panels */
.panels {
  .panel-overlay {
    @include pof(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease 0s;
    z-index: 200;
  }

  &.open .panel-overlay {
    opacity: 1;
    pointer-events: auto;
  }
}

/* styles globaux panels */
.panel {
  @include pof(0, -101%, 0);
  background: $grey-lighter;
  flex-direction: column;
  line-height: 1;
  overflow-x: auto;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 201;

  @media (min-width: $xs-width) {
    right: -81%;
    width: 80%;
  }
  @media (min-width: $sm-width) {
    right: -61%;
    width: 60%;
  }
  @media (min-width: $md-width) {
    right: -74rem;
    width: 73rem;
  }

  .panel-head {
    background: $grey-lighter;
    position: relative;

    @media (min-width: $md-width) {
      background: $white;
      padding: 2rem 2rem 0 2rem;
    }

    .panel-title {
      background: $grey-lighter;
      border-radius: 0.3rem;
      color: $grey-medium;
      display: block;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 1.5rem;
      position: relative;
      z-index: 2;

      @media (min-width: $md-width) {
        background: $white;
        font-size: 2rem;
        padding: 5rem 7.5rem 3rem 5rem;
      }
    }

    .panel-close {
      @include poa(1.5rem, 2.5rem);
      color: $grey-medium;
      cursor: pointer;
      font-size: 1.3rem;
      z-index: 3;

      @media (min-width: $md-width) {
        @include poa(5.2rem, 4.5rem);
      }

      &::after {
        @include poa(-10px, -10px, -10px, -10px);
        content: "";
        padding: 10px;
        pointer-events: all;
      }
    }

    .panel-header-price {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 400;
      padding-left: 2.5rem;

      @media (min-width: $md-width) {
        padding-left: 2.5rem;
      }
    }
  }

  .panel-message {
    display: none;
    padding: 1rem 3rem;
  }

  .panel-block {
    border-top: 0.1rem solid $grey-light;
    color: $black;
    padding: 2rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 1.5rem 7.5rem 1.5rem 7.5rem;
    }

    &.white {
      background: $white;
    }

    &.grey {
      background: $grey-lighter;
    }

    iframe {
      background-color: $grey-lighter;
    }
  }

  .panel-subblock {
    margin-bottom: 3.5rem;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .panel-buttons {
    background: $white;
    border: 0;
    font-size: 0;
    padding: 1rem 1.5rem;
    text-align: center;
    @media (min-width: $md-width) {
      padding: 1.5rem 10.5rem 1.5rem 9.5rem;
    }

    .button {
      font-size: 1.4rem;
      margin: 0.7rem 5%;
      width: 40%;
      @media (max-width: $md-width) {
        padding: 1.7rem .2rem;
      }
    }
  }

  &.open {
    right: 0;
  }
}

.stores-header-input {
  .form-control {
    border: 0;
    border-radius: 0.4rem;
    color: $blue-dark;
    font-family: "Muli";
    font-weight: 700;
    height: 50px;
    padding: 0 3rem 0 1rem;
    width: 100%;

    @media (min-width: $xs-width) {
      padding: 0 3.5rem 0 2rem;
    }
  }

  .icon {
    @include poa(50%, 1rem);
    color: $blue-dark;
    font-size: 20px;
    transform: translateY(-50%);

    @media (min-width: $xs-width) {
      right: 1.5rem;
    }
  }
}
