@import "../_pages/category/common";

.category-gooddeal .links-top-category {
  background: $grey-lighter;
  margin: 0;

  @media (min-width: $sm-width) {
    padding: 5rem 0 7rem 0;
  }
}

/******************************************************   */

/* Catégorie LISTING */

/*****************************************************   */

/* Filtres */
.filters-ctn {
  padding-top: 4rem;

  .custom-radio {
    &::after,
    &::before {
      font-size: 1.4rem;
    }

    label {
      padding: 0 0 0 0.5rem;
    }
  }

  .custom-checkbox {
    &::after,
    &::before {
      @include size(1.4rem);
      font-size: 0.7rem;
      top: 0.2rem;
    }

    &::after {
      line-height: 1.5rem;
    }

    label {
      padding: 0 0 0 2rem;
    }
  }
}

.products-list {
  position: relative;

  .productsSortForm {
    align-items: flex-end;
    margin-bottom: 2rem;
    text-align: left;

    span {
      font-weight: 700;
      height: 3.8rem;
      line-height: 3.6rem;
      padding-left: 4.5rem;

      &::before {
        @include poa(50%, null, null, 1rem);
        content: "\e908";
        font-family: "icomoon";
        font-size: 1.3rem;
        line-height: 1;
        transform: translateY(-50%);
      }
    }

    .custom-select {
      margin-bottom: 0;
    }
  }

  .see-more-products {
    background-color: $grey-lighter;
    height: auto;
    margin-bottom: 4.5em;

    &:hover {
      background-color: $grey-light;
    }
  }
}

.category-netlinking {
  li {
    padding: 0.3rem 0;
  }
}
