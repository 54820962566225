.panel-delivery-delay,
.panel-clickandtest {
  background: $white;

  .panel-head {
    padding: 0;
  }

  .panel-content {
    height: 100%;
  }

  .panel-subtitle {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 15px;
  }

  .panel-content {
    border-bottom: 0;

    .stores-header-input {
      background: $blue-dark;
      margin-bottom: 30px;
      margin-top: 0;
      padding: 25px 15px;
      @media (min-width: $md-width) {
        padding: 25px 50px;
      }

      .form {
        position: relative;

        button {
          background: 0;
          border: 0;
          height: 20px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);

          .icon {
            right: 0;
          }
        }
      }
    }
  }

  .panel-recommendations {
    display: none;
  }
}
