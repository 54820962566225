@import 'lg-variables';
@import 'lg-mixins';

$thumbnails-margin: 5px;

.lg-outer {
  .lg-thumb-outer {
    background-color: $lg-thumb-bg;
    float: left;
    max-height: 350px;
    overflow: hidden;
    width: 100%;

    &.lg-grab {
      .lg-thumb-item {
        @include grab-cursor;
      }
    }

    &.lg-grabbing {
      .lg-thumb-item {
        @include grabbing-cursor;
      }
    }

    &.lg-dragging {
      .lg-thumb {
        @include transition-duration(0s !important);
      }
    }

    &.lg-rebuilding-thumbnails {
      .lg-thumb {
        @include transition-duration(0s !important);
      }
    }

    &.lg-thumb-align-middle {
      text-align: center;
    }

    &.lg-thumb-align-left {
      text-align: left;
    }

    &.lg-thumb-align-right {
      text-align: right;
    }
  }

  &.lg-single-item {
    .lg-thumb-outer {
      display: none;
    }
  }

  .lg-thumb {
    display: inline-block;
    height: 100%;
    margin-bottom: -$thumbnails-margin;
    padding: 5px 0;
    vertical-align: middle;
    @media (min-width: 768px) {
      padding: 10px 0;
    }
  }

  .lg-thumb-item {
    background-color: $white;
    cursor: pointer;
    float: left;
    height: 100%;
    margin-bottom: $thumbnails-margin;
    overflow: hidden;
    will-change: border-color;
    @media (min-width: 768px) {
      border: 2px solid #fff;
      @include transition(border-color 0.25s ease);
    }

    &.active,
    &:hover {
      border-color: $lg-theme-highlight;
    }

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &.lg-can-toggle {
    .lg-item {
      padding-bottom: 0;
    }
  }

  .lg-toggle-thumb {
    display: none;
  }

  &.lg-animate-thumb {
    .lg-thumb {
      @include transition-timing-function(
          cubic-bezier(0.215, 0.61, 0.355, 1)
      );
    }
  }
}
