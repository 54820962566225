.custom-radio {
  align-items: center;
  color: $grey-medium;
  display: flex;
  min-height: 2rem;
  padding-left: 2rem;
  position: relative;
  z-index: 3;

  &::before,
  &::after {
    font-family: "icomoon";
    font-size: 2rem;
    transform: translateY(-50%);
  }

  &::before {
    @include poa(50%, null, null, 0);
    content: "\e949";
    z-index: 2;
  }

  &::after {
    @include poa(50%, null, null, 0);
    content: "\e94a";
    opacity: 0;
    transition: opacity 0.3s ease 0s;
    z-index: 1;
  }

  input {
    @include poa(0, null, null, 0);
    @include size(2rem);
    cursor: pointer;
    margin: 0;
    opacity: 0;
    z-index: 3;
  }

  label {
    cursor: pointer;
    font-weight: 700;
    padding: 0.4rem 1rem 0.2rem 1rem;
    width: auto;

    @media (min-width: $md-width) {
      padding-right: 3rem;
    }
  }

  &.checked {
    color: $black;

    &::after {
      opacity: 1;
    }

    label,
    label a {
      color: $black;
    }
  }
}
