.radio-box {
  cursor: pointer;

  > label {
    display: block;
  }

  > input {
    cursor: pointer;
    height: 49px;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .filename {
    background: #f2eceb;
    border: 0;
    color: #bba3a2;
    display: inline-block;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    margin-right: 10px;
    max-width: 60%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .action {
    color: #bba3a2;
    display: inline-block;
    font-family: "fjalla";
    text-transform: uppercase;
    vertical-align: middle;

    &::after {
      color: #ceb5b4;
      content: "\e925";
      display: inline-block;
      font-family: "icomoon";
      font-size: 20px;
      margin-left: 6px;
      position: relative;
      top: 3px;
    }
  }
}
