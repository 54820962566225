a {
  color: inherit;
  text-decoration: none;
}

.imgr,
.img-responsive {
  height: auto;
  max-width: 100%;
}

.hidden,
.unvisible {
  display: none;
}

.noborder {
  border: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.barre {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.bg-grey {
  background-color: $grey-lighter;
}

.bg-white {
  background-color: $white;
}

.small-ctn {
  margin: auto;
  max-width: 870px;
}

.ci-image, img[ci-src], .lazy-img {
  opacity: 0;
  transition: 0.5s;
}

.ci-image-loaded,
.ci-image-loaded[ci-src],
.ci-image.lazyloaded {
  opacity: 1;
}

.lazy-ctn {
  background-color: $grey-lighter;
  display: block;
  line-height: 0;
  overflow: hidden;
  position: relative;
}

.lazy-img {
  @include poa(0, 0);
  height: 100%;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
