.advconstructor-landing {
  &-title {
    @include heading($blue-dark, 1.8, 1, 900, false);

    @media (min-width: $md-width) {
      font-size: 2.5rem;
    }
  }

  &-content {
    font-size: 1.3rem;
    line-height: 1.4;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }
}
