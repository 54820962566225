a,
a:focus {
  outline: 0;
}

.advcms-nav {
  background-color: $grey-lighter;
  border-right: 1px solid $grey-light;
  flex-basis: 280px;
  flex-shrink: 0;
  font-family: $muli;

  @media (min-width: $md-width) {
    flex-basis: 350px;
    padding: 20px 30px 0;
  }
  @media (min-width: $lg-width) {
    flex-basis: 410px;
    padding: 40px 50px 0 45px;
  }

  .advcms-nav-category {
    margin-bottom: 30px;
  }

  .advcms-nav-title {
    color: $grey;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 900;
    position: relative;
    text-decoration: none;

    .icon {
      @include poa(2px, -30px);
      @include size(30px);
      font-size: 12px;
      line-height: 30px;
      text-align: center;
    }

    &.open .icon::before {
      content: "\e90a";
    }
  }

  a.advcms-nav-title:hover {
    ext-decoration: underline;
  }

  .advcms-nav-list {
    display: none;
    list-style: none;
    margin-top: 10px;
    padding: 0;

    li {
      padding: 10px 0;
      position: relative;

      a {
        color: $black;
        display: block;
        font-size: 16px;
        padding: 5px 0;
        text-decoration: none;

        &.open {
          font-weight: 700;
          text-decoration: underline;
        }

        &:not(.open):hover {
          color: $grey;
        }

        .icon {
          @include poa(20px, -20px);
          font-size: 12px;
          text-decoration: none;
        }

        &.open .icon {
          content: "\e90a";
        }
      }

      .advcms-subnav-list {
        display: none;
        margin-left: 18px;
        margin-top: 15px;
        padding-bottom: 30px;
        padding-left: 0;

        li {
          list-style: disc;
          padding: 5px 0;

          a {
            display: block;
            font-size: 13px;
            line-height: 16px;

            &.current {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
