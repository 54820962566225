.panel-delivery-delay {
  .panel-content {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: -46px;
    padding-top: 46px;

    @media (min-width: $md-width) {
      margin-top: -100px;
      padding-top: 100px;
    }
  }

  .delivery-delay-response {
    margin-top: 20px;

    &::before {
      flex-basis: 18%;
      width: 18%;
      text-align: right;
      margin-right: 2rem;
      margin-top: -10px;
    }
  }

  .delivery-delay-response,
  .picto-desc-delivery {
    &::before {
      font-size: 55px;
      color: $blue-dark;
      display: block;
    }
  }
}

.delivery-delay {
  &-icon {
    cursor: pointer;
  }

  &-info {
    color: $black;
    font-size: 1.6rem;
    padding: 1rem 5rem;
  }

  &-panel-pictos {
    margin-top: auto;
    padding: 6rem 5rem 5rem 6rem;

    @media (max-width: 998px) {
      margin: auto 0 -7% -10%;
    }
  }

  &-response {
    display: inline-flex;
    font-weight: bold;
    width: 100%;
  }

  &-desc {
    flex: 1;
  }

  &-result-second,
  &-result {
    color: $green;
    font-size: 1.9rem;
    font-weight: 900;
    padding: 0;
    text-align: left;
  }

  &-picto {
    float: right;
  }

  &-success {
    color: $black;
    font-size: 1.6rem;
    font-weight: 100;
    padding: 2.4rem 0 0 2rem;
    text-align: left;
  }

  &-button {
    margin: 1rem 15rem;
    position: absolute;
    z-index: 2;
    @media (max-width: $xs-width) {
      margin: 1rem 7rem;
    }
  }
}

.desc-delivery {
  line-height: 2rem;
  margin-top: 20px;
  display: block;

  @media (max-width: 998px) {
    font-size: 85%;
  }
}

.picto {
  &-margin-delivery {
    color: $blue-dark;
    margin: 2rem 2rem 2rem 3rem;

    @media (max-width: 998px) {
      margin: 2rem 2rem 2rem 0.6rem;
      width: 20%;
    }
  }

  &-desc-delivery {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 0.5rem;
  }
}

.info-delivery-delay {
  color: $blue-dark;
  font-size: 1.7rem;
  text-align: left;
}

.force-hidden {
  display: none !important;
}

.zip-code-validation {
  margin: 1rem 0 0 0;
  padding: 0.4rem;
}
