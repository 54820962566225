.store-reviews {
  font-weight: 600;
  margin: auto;

  &-ctn {
    border-top: 1px solid $grey-light;

    &,
    header {
      background-color: #f7f7f7;
    }

    .product-block-title {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  &-title {
    line-height: 1;
    margin-left: 5px;
  }

  &-average {
    font-size: 18px;
    font-weight: 600;
    margin: 0 10px;
  }

  &-nb {
    font-size: 14px;
    margin-top: 5px;
  }

  &-link {
    color: $grey-light;
    font-size: 14px;
    margin-left: 5px;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      color: $grey-medium;
    }
  }

  .storedetails & {
    .store-reviews-average {
      margin: 0 10px 0 0;
    }
  }

  .sceau {
    max-width: 50px;
  }

  &,
  &-details {
    .stars {
      height: 20px;

      .icon {
        font-size: 2.4rem;

        &-secondary {
          color: #f4f4f4;
        }
      }
    }
  }

  &-details {
    margin: 0 auto;
    max-width: 100%;
    width: 630px;

    @media (min-width: $md-width) {
      margin: 50px auto 0;
    }

    .sceau {
      max-width: 60px;

      @media (min-width: $md-width) {
        margin-right: 50px;
        max-width: 100px;
      }
    }

    .button {
      display: block;
      margin: 0 auto;
    }

    .store-reviews-nb {
      margin-top: 0;
    }

    .product-block-content {
      display: block;
      padding: 15px;
    }
  }
}

.reviews-widget {
  color: #006ff5;
  background-color: #fff;
  font-family: "Roboto", serif;
  width: 150px;
  border: 1px solid #cfcfcf;
  padding: 5px;
  font-size: 10px;
  position: relative;
  font-weight: bold;

  &__logo {
    width: 64px;
  }

  &__rate {
    font-size: 14px;
  }

  &__stars {
    position: relative;
    margin-top: 6px;

    img {
      display: block;
    }

    img:last-of-type {
      position: absolute;
      inset: 0;
      width: 100%;
      height: auto;
    }
  }

  &__link {
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}
