/* displayAdvConstructorTopCategory */
.links-top-category {
  margin-bottom: 2rem;
  position: relative;

  @media (min-width: $md-width) {
    margin-top: 3rem;
  }

  &::after {
    bottom: 0;
    content: "";
    left: 1em;
    position: absolute;
    right: 1em;
  }

  .advconstructor-top-category,
  .advconstructor-top-category-img {
    margin-bottom: 1rem;

    @media (min-width: $sm-width) {
      margin-bottom: 3rem;
    }

    .advconstructor-content-img {
      display: block;
      max-width: 100%;
    }
  }

  .advconstructor-top-category {
    .content-ctn {
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 0.3rem;
      color: #fff;
      display: block;
      height: 100%;
      padding: 2.1rem 2rem;
      position: relative;
      text-align: center;
      text-decoration: none;

      @media (min-width: $sm-width) {
        background-position: 90% 25%;
        text-align: left;
      }

      &::before {
        @include poa(50%, 1.5rem);
        content: "\e920";
        font-family: "icomoon";
        margin-top: -0.7rem;

        @media (min-width: $sm-width) {
          display: none;
        }
      }

      .title {
        @include heading($white, 1.8, 0, 900, false);
        display: block;

        @media (min-width: $sm-width) {
          font-size: 2.4rem;
          line-height: 2.8rem;
          max-width: 25rem;
        }
      }

      .label-btn {
        display: none;
        margin-top: 1em;

        @media (min-width: $sm-width) {
          display: inline-block;
        }
      }

      &:hover .button-arrow {
        background-color: #fff;

        &:hover {
          background-color: #fff;
          color: inherit;
        }
      }
    }
  }
}
