.mattress-choose-wrap {
  .icon{
    margin: auto;
    filter: grayscale(100%) invert(100%);;
  }
  background-color: $blue;
  .mc-step-label img {
      max-width: 85px;
  }
  .mc-first-step {
    color: $white;
    padding: 2rem 0.75rem;

    &,
    .mc-head,
    .mc-figure {
      text-align: center;
    }

    .mc-figure-img {
      display: block;
      margin: 0 auto;
      max-width: 14.5rem;
      width: 100%;

      @media (min-width: $md-width) {
        max-width: 42.1rem;
      }
    }

    .mc-title {
      display: inline-block;
    }

    .mc-title-img {
      display: block;
      margin: 0 auto;
      max-width: 18rem;
      width: 100%;

      @media (min-width: $md-width) {
        max-width: 30.2rem;
      }
    }

    .mc-title-txt {
      display: block;
      font-size: 2.4rem;
      font-weight: 900;
      margin: -2rem auto 0;
      max-width: 28rem;

      @media (min-width: $md-width) {
        font-size: 4rem;
        margin: -5rem auto 0;
        max-width: none;
      }
    }

    .mc-txt {
      font-size: 1.4rem;
      margin: 2rem auto 0;
      max-width: 29rem;

      @media (min-width: $md-width) {
        font-size: 2rem;
        max-width: none;
      }
    }

    .mc-btn {
      background-color: $green;
      height: 5rem;
      margin-top: 3rem;
      max-width: 45rem;
      transition: opacity 0.3s ease;
      width: 100%;

      &:hover {
        opacity: 0.8;
      }

      @media (min-width: $md-width) {
        max-width: 34.7rem;
      }
    }

    .mc-step-box {
      margin-bottom: 7rem;

      .icon::before {
        font-size: 2.6rem;
      }
    }

    .mc-step-1,
    .mc-step-2,
    .mc-step-3,
    .mc-step-4 {
      display: flex;
      flex-direction: column;
      height: 8rem;
      justify-content: center;
      margin-top: 5rem;
      position: relative;
      z-index: 0;

      &:hover::before {
        color: $blue-dark;
      }
      &::before {
        color: #80a1d9;
        font-size: 11rem;
        font-weight: 900;
        left: 50%;
        opacity: 0.1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: color 0.3s ease;
        width: 100%;
        z-index: -1;
      }
    }

    .mc-step-1::before {
      content: "01";
    }

    .mc-step-2::before {
      content: "02";
    }

    .mc-step-3::before {
      content: "03";
    }

    .mc-step-4::before {
      content: "04";
    }

    .mc-step-txt {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1;
      margin: 1rem auto 0;
      max-width: 11rem;

      @media (min-width: $md-width) {
        font-size: 1.8rem;
        max-width: none;
      }
    }
  }

  .mc-last-step {
    text-align: center;

    .product-compare-header {
      display: none;
    }

    // !!! MOBILE ONLY
    @media (max-width: $sm-width) {
      .mc-ctn-wrap {
        background-color: $white;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }

    .mc-btn {
      color: $white;
      padding: 1rem 0 1rem 4.5rem;
      position: relative;
      text-decoration: none;

      .icon {
        @include animated(transform, 1s);
        @include poa(0.5rem, null, null, 0);
        @include size(2.8rem);
        font-size: 2.4rem;
        transform: rotate(0deg);
        transform-origin: 1.4rem 1.4rem;
      }

      &:hover {
        .icon {
          transform: rotate(-360deg);
        }
      }
    }

    .mc-ctn-bgi {
      // !!! UNTIL 1514px DESKTOP
      @media (max-width: 1514px) {
        background-image: none !important;
      }
      background-position: 96% -10%;
      background-repeat: no-repeat;
    }

    .mc-ctn-box {
      position: relative;
    }

    @media (min-width: $lg-width) {
      .mc-ctn-box {
        margin-bottom: 13rem;
      }
    }
    @media (min-width: $lg-width) {
      .mc-ctn-box::after {
        @include poa(null, 25%, 0, 25%);
        background-color: $white;
        border-radius: 50%;
        box-shadow: 0 0 6rem rgba(0, 0, 0, 1);
        content: "";
        display: block;
        height: 5rem;
        z-index: 0;
      }
    }

    .product-compare-main {
      overflow-x: auto;

      @media (min-width: $lg-width) {
        padding: 3rem 15rem 0 15rem;
      }
    }

    .product-compare-tiles {
      justify-content: center;
      margin: 0;
      display: block;

      @media (min-width: $sm-width) {
        margin: 0;
        display: flex;
      }
    }

    .product-compare-tile,
    .product-compare-table-cell {
      min-width: 100%;

      @media (min-width: $sm-width) {
        min-width: calc(100% / 4);
      }
      @media (min-width: $md-width) {
        width: calc(100% / 4);
      }
    }

    .product-compare-table,
    .product-compare-tile-head {
      display: none;

      @media (min-width: $sm-width) {
        display: block;
      }
    }

    .mc-header {
      @media (max-width: $md-width) {
        background-image: none !important;
      }

      background-position: 100% 10%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      padding: 2rem 0;

      @media (min-width: $lg-width) {
        background-size: auto 130%;
      }

      @media (min-width: $xl-width) {
        padding: 5rem 0;
        background-position: 100% 10%;
        background-size: auto 130%;
      }
    }

    .mc-title {
      color: $white;
      font-size: 1.8rem;
      font-weight: 900;
      margin-bottom: 1rem;

      @media (min-width: $lg-width) {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
    }

    .mc-subtitle {
      color: $white;
      font-size: 1.4rem;
      margin: 0 auto 1rem auto;
      max-width: 100%;

      @media (min-width: $xs-width) {
        max-width: 90%;
      }
      @media (min-width: $sm-width) {
        max-width: 65%;
      }
      @media (min-width: $lg-width) {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        max-width: 45%;
      }
    }

    .mc-choices {
      color: $white;
      font-size: 1.2rem;
      padding: 0 0 1rem 0;
      max-width: 90%;
      margin: 0 auto;

      @media (min-width: $sm-width) {
        max-width: 65%;
      }

      @media (min-width: $md-width) {
        max-width: 45%;
      }

      @media (min-width: $lg-width) {
        max-width: 60%;
        font-size: 1.6rem;
      }

      .choices-label {
        font-size: 1.4rem;
        text-decoration: underline;
        @media (min-width: $lg-width) {
          font-size: 2rem;
        }
      }

      img {
        -webkit-filter: grayscale(100%) brightness(60%);
        filter: grayscale(100%) brightness(160%);
        vertical-align: middle;
        width: 9%;
      }
    }

    .mc-nav {
      background-color: $white;
      border-bottom: 0.1rem solid #dadada;
      padding: 0 2rem;
      position: relative;
      z-index: 3;

      // !!!! MOBILE ONLY
      @media (max-width: $lg-width) {
        width: 100%;
      }
      @media (min-width: $lg-width) {
        align-items: center;
        border-bottom: none;
        border-radius: 0.4rem 0 0 0.4rem;
        border-right: 0.1rem solid #dadada;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        width: 25%;
      }
      @media (min-width: $xl-width) {
        height: 65rem;
      }
      @media (min-width: 1990px) {
        height: 60rem;
      }
    }

    .mc-nav-list {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      @media (min-width: $lg-width) {
        align-items: center;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 5rem 0;
        width: 100%;
      }
    }

    .mc-nav-list-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 11rem;
      justify-content: flex-start;
      padding: 2rem 0;
      position: relative;
      width: 25%;
      z-index: 0;

      @media (min-width: $lg-width) {
        height: auto;
        justify-content: center;
        padding: 3.5rem 0;
        width: 100%;
      }

      &::before {
        color: $yellow;
        font-size: 12rem;
        font-weight: 900;
        left: 50%;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.3s ease 0.1s;
        z-index: -1;
      }

      &:nth-child(1)::before {
        content: "01";
      }

      &:nth-child(2)::before {
        content: "02";
      }

      &:nth-child(3)::before {
        content: "03";
      }

      &:nth-child(4)::before {
        content: "04";
      }

      &:hover,
      &.is-active {
        .mc-list-txt,
        .icon::before {
          color: $blue-dark;
        }

        img {
          filter: grayscale(0) contrast(100%);
          margin-bottom: 1rem;
        }
      }

      &.is-active {
        @media (min-width: $lg-width) {
          &::before {
            opacity: 1;
          }
        }

        &::after {
          background-color: $white;
          border-bottom: 0.1rem solid #dadada;
          border-left: 0.1rem solid #dadada;
          bottom: -0.8rem;
          content: "";
          height: 1.6rem;
          left: 50%;
          margin-left: -0.8rem;
          position: absolute;
          transform: rotate(-45deg);
          transform-origin: 50% 50%;
          width: 1.6rem;

          @media (min-width: $lg-width) {
            bottom: auto;
            height: 3.2rem;
            left: auto;
            margin-top: -1.6rem;
            right: -1.7rem;
            top: 50%;
            transform: rotate(-135deg);
            width: 3.2rem;
          }
        }
      }
    }

    .mc-list-icon-box {
      align-items: center;
      display: flex;
      height: 3.6rem;
      justify-content: center;
      margin-bottom: 1rem;

      @media (min-width: $lg-width) {
        margin-bottom: 0;
      }

      img {
        max-height: 80px;
        width: auto;
        filter: grayscale(100%) contrast(50%);
        margin-bottom: 1rem;
      }
    }

    .mc-list-txt {
      color: #666;
      font-size: 1rem;
      line-height: 1;
      margin: 0 auto;
      max-width: 7rem;
      transition: color 0.3s ease;

      @media (min-width: $lg-width) {
        font-size: 1.8rem;
        font-weight: 700;
        max-width: none;
        padding: 0 1rem;
      }
    }

    .mc-step-ctn-wrap {
      background-color: $white;
      border-radius: 0 0.4rem 0.4rem 0;
      margin-bottom: 5rem;
      position: relative;
      width: 100%;
      z-index: 2;

      @media (min-width: $lg-width) {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        padding: 3rem;
        width: 75%;
      }
      @media (min-width: $xl-width) {
        height: 65rem;
      }
      @media (min-width: 1990px) {
        height: 60rem;
      }
    }

    .mc-step-ctn {
      display: none;

      &.is-active {
        display: block;
      }
    }

    .mc-ctn-title {
      color: $green;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1;
      margin: 0 auto;
      max-width: 22rem;
      padding: 3rem 1rem;
      text-align: center;

      @media (min-width: $md-width) {
        max-width: 60rem;
      }
      @media (min-width: $lg-width) {
        font-size: 2.4rem;
        font-weight: 900;
        padding: 0 1rem 4rem;
      }
    }

    .mc-step-form {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
    }

    .mc-step-label {
      border: 0.1rem solid transparent;
      cursor: pointer;
      margin: 0.1rem 0.1rem 1rem;
      padding: 1.5rem 1.5rem 3rem;
      position: relative;
      transition: border-color 0.3s ease;

      &:hover {
        border-color: $grey-light;
      }

      &::after {
        bottom: 1rem;
        color: $green;
        content: "\e92f";
        font-family: "icomoon";
        left: 50%;
        opacity: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: opacity 0.3s ease 0.1s;
      }

      img {
        filter: grayscale(100%) brightness(60%);
      }

      &.is-active {
        border-color: $grey-light;

        img {
          filter: grayscale(0) brightness(100%);
        }

        &::after {
          opacity: 1;
        }

        .mc-txt-box,
        .icon::before {
          color: $green;
        }
      }

      .mc-txt-box {
        color: #666;
      }

      .mc-txt1 {
        display: block;
        font-size: 1.1rem;
        font-weight: 700;

        @media (min-width: $lg-width) {
          font-size: 1.6rem;
        }
      }

      .mc-txt2 {
        display: block;
        font-size: 1.2rem;
        font-weight: 700;
      }

      input[type="radio"] {
        opacity: 0;
      }
    }

    .mc-desc-txt {
      color: #666;
      font-size: 1.4rem;
      font-weight: 400;
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 62rem;
      text-align: center;
    }

    .mc-desc-link {
      text-decoration: underline;
    }

    .mc-desc-btn {
      background-color: $green;
      display: none;
      margin-top: 4rem;
      min-width: 28rem;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }

      @media (min-width: $lg-width) {
        display: inline-block;
        margin-top: 2rem;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease 0s;

        &.active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

#module-ant_diagnostic_couette-result .mc-ctn-box,
#module-ant_diagnostic_oreiller-result .mc-ctn-box,
#module-ant_diagnostic-result .mc-ctn-box {
  background: $white;

  &::after {
    display: none;
  }
}

#module-ant_diagnostic_oreiller-form {
  .mc-step-label {
    max-width: 125px;

    img {
      width: auto;
      max-height: 80px;
    }
  }
}

article.product-compare-tile.ant_diagnostic_is_cross_sell{
  background-color: #f3f3f3;
}

#module-ant_diagnostic_couette-result {
  .choix-couette-results-titles{
    margin: 2.5rem 0 1.5rem;

    .product-compare-tile p{
      color: #01025c;
      font-weight: 700;
      line-height: 1.2;
      text-align: left;

      border: none;
      border-radius: 0px;
      border-bottom : 2px solid #01025c;
    }
    .main-result-title{
      width: 50%;
      padding: 0 1.5rem;
    }
    .cross-sell-result-title{
      padding: 0 1.5rem;
      &.product-compare-tile p{
        color: #01025c91;
        border-color: #01025c91;
      }
    }
  }
}
