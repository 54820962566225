.store-reviews 
{
    &,
    &-details {
      .stars {
        height: 2.5rem;
        }
    }
}

.store-block-services-item
{
    &.advreviews {
        @media (max-width: $sm-width) {
            flex-flow: row wrap;
            height: unset !important;
        }
    }
}

.product-reviews {

    order: 2;

    &.google-reviews {
        order: 1;
    }

}

.store-block {
    .line {
        .store-block-inner {
            &.client-reviews {
                display:flex;
                flex-flow: column nowrap;
            }
        }
    }
}