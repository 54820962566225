.matrices {
  padding-bottom: 30px;

  .matrice {
    padding: 40px 0;

    @media (min-width: $md-width) {
      padding: 60px 0;
    }

    &.stick {
      margin-top: -20px;
      padding-top: 0;
    }

    .matrice-title {
      font-size: 18px;
      font-weight: 900;
      margin: 0 0 20px 0;

      @media (min-width: $xs-width) {
        font-size: 20px;
      }
      @media (min-width: $sm-width) {
        font-size: 22px;
      }
      @media (min-width: $md-width) {
        font-size: 24px;
      }
    }

    .matrice-content {
      font-size: 14px;
      line-height: 1.6;
      overflow-x: auto;

      @media (min-width: $sm-width) {
        font-size: 15px;
      }
      @media (min-width: $md-width) {
        font-size: 16px;
      }

      img {
        height: auto;
        max-width: 100%;
      }

      td {
        border: 1px solid $blue-dark;
      }
    }

    .matrice-video-ctn {
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-bottom: 50%;
      }

      .matrice-video {
        @include poa(0, 0);
        @include size(100%);
      }
    }

    .matrice-action {
      border-radius: 3px;
      color: #fff;
      margin-top: 15px;
    }

    .matrice-img-ctn {
      margin-bottom: 30px;
      width: 100%;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
      }
    }

    .matrice-img-ctn img {
      height: auto;
      max-width: 100%;
    }

    &.img-right .matrice-img-ctn {
      margin-bottom: 30px;
      text-align: center;
      @media (min-width: $sm-width) {
        order: 2;
        text-align: right;
      }
    }

    &.img-left .matrice-img-ctn {
      margin-bottom: 30px;
      text-align: center;
      @media (min-width: $sm-width) {
        text-align: right;
      }
    }

    &.img-top .matrice-img-ctn {
      margin-bottom: 30px;
    }

    &.img-bottom .matrice-img-ctn {
      margin-top: 15px;
      order: 2;
    }

    &.media {
      margin-top: 0;

      .matrice-media {
        display: inline-block;
        height: auto;
        max-width: 100%;
        padding: 0 15px;
      }

      &.img-full {
        padding: 0;

        .matrice-media {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}

/* Cross sell CMS */
.matrice {
  .cross-sell-cms-title {
    color: #01025c;
    font-size: 18px;
    font-weight: 900;
    height: 5rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .product-cross-sell-ctn {
    padding-top: 5rem;
    position: relative;

    @media (min-width: $xs-width) {
      margin-bottom: 4rem;
    }
    @media (min-width: $lg-width) {
      margin-bottom: 5rem;
    }

    .title-best {
      @include heading($blue-dark, 1.5, 0, 900, false);
      @include poa(0, 1.5rem, null, 1.5rem);
      display: block;
      height: 5rem;
      text-align: center;
      z-index: 2;

      @media (min-width: $md-width) {
        font-size: 1.5rem;
      }
    }

    .product-ctn {
      &-infos {
        height: inherit;
        margin-bottom: 1.5rem;
        padding: 0 1.5rem;
      }
    }
  }
}
