.shopping-cart {
  &-summary {
    @include pof(0, 0, null, 0);
    background: $white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
    font-size: 1.2rem;
    height: calc(100% + 6rem);
    overflow-y: auto;
    transform: translateY(calc(100% - 12rem));
    transition: transform 0.3s ease 0s;
    z-index: 10;

    @media (min-width: $sm-width) {
      box-shadow: none;
      height: auto;
      position: static;
      transform: translateY(0);
    }
    @media (min-width: $lg-width) {
      font-size: 1.4rem;
    }

    &.open {
      transform: translateY(-6rem);

      @media (min-width: $sm-width) {
        transform: translateY(0);
      }
    }

    &-inner {
      @media (min-width: $sm-width) {
        border: 0.1rem solid $grey-light;
        border-radius: 0.3rem;
        margin: 0;
        overflow: hidden;
      }
    }

    &-line {
      color: $black;
      margin-top: 1.5rem;
      padding: 0 0.75rem;

      @media (min-width: $sm-width) {
        padding: 0;
      }
      @media (min-width: $lg-width) {
        padding: 0 1.5rem;
      }

      .summary-price {
        text-align: right;
      }

      &.line-taxes {
        font-style: italic;
        opacity: 0.5;
      }

      &.line-total {
        border-top: 0.1rem solid $grey-light;
        margin-top: 1.5rem;
        padding: 1.5rem 0.75rem;

        @media (min-width: $sm-width) {
          padding: 1.5rem 0;
        }
        @media (min-width: $lg-width) {
          margin-top: 2.5rem;
          padding: 2rem 1.5rem;
        }

        .summary-price {
          color: $red;
          font-size: 1.8rem;
          font-weight: 900;
          margin-top: -0.2rem;
        }
      }

      &--pack {
        margin-top: 1.5rem;

        &::before {
          border-top: 1px solid color(border);
          content: "";
          display: block;
          padding-top: 1.5rem;

          @media (min-width: $lg-width) {
            margin: 0 3rem;
          }
        }

        .line-total {
          padding-bottom: 0;
        }

        .shopping-cart-summary-line {
          margin-top: 0;

          & + .shopping-cart-summary-line {
            margin-top: 5px;
          }

          .summary-price {
            color: color(text, success);
          }
        }
      }
    }

    &-stack {
      background: $grey-lighter;
      color: $black;
      cursor: pointer;
      font-size: 1.4rem;
      padding: 2rem 1.5rem;
      position: relative;

      .price {
        color: $red;
        font-size: 1.6rem;
        font-weight: 900;
        padding-right: 3.5rem;
      }

      &::after {
        @include poa(50%, 2rem);
        content: "\e91f";
        font-family: "icomoon";
        font-size: 1.2rem;
        transform: translateY(-50%);
      }
    }

    &-products {
      color: $black;

      &-title {
        @media (min-width: $sm-width) {
          display: block;
          padding: 1.5rem 1.5rem 0 1.5rem;
        }
        @media (min-width: $lg-width) {
          display: block;
          padding: 1.5rem 3rem 0 3rem;
        }

        .summary-toggle {
          cursor: pointer;
          font-size: 1.2rem;
          font-style: italic;
          text-decoration: underline;

          .txt-open {
            display: none;
          }
        }

        &-mobile {
          background: $grey-light;
          color: $grey-medium;
          cursor: pointer;
          display: block;
          font-size: 1.6rem;
          font-weight: 700;
          padding: 1.5rem;
          position: relative;

          .icon {
            @include poa(50%, 1.5rem);
            font-size: 1.2rem;
            font-weight: 400;
            transform: translateY(-50%);
          }
        }
      }

      &.open {
        background: $grey-lighter;

        .shopping-cart-summary-product {
          display: flex;
        }

        .txt-open {
          display: block;
        }

        .txt-close {
          display: none;
        }
      }
    }

    &-product {
      border-bottom: 0.1rem solid $grey-light;
      margin-top: 1.5rem;
      padding: 0 1.5rem 1.5rem 1.5rem;

      @media (min-width: $sm-width) {
        display: none;
      }
      @media (min-width: $lg-width) {
        padding: 0 3rem 1.5rem 3rem;
      }

      &-qty {
        color: $blue-dark;
        font-weight: 700;
        width: 7%;
      }

      &-info {
        color: $blue-dark;
        font-weight: 700;
        width: 63%;
      }

      &-price {
        text-align: right;
        width: 25%;
      }

      &-availability {
        font-size: 1.2rem;
      }
    }

    &-reassurance {
      .advreassurance-cart .advreassurance-item {
        @media (min-width: $sm-width) {
          margin-bottom: 1.5rem;
          min-width: 50%;
          padding: 0 1.5rem;
        }
        @media (min-width: $lg-width) {
          margin-bottom: 3rem;
          padding: 0 3rem;
        }
      }
    }
  }

  &-discount {
    &-delete {
      display: inline-block;
      margin-right: 0.5rem;
    }

    &-desc {
      font-style: italic;
      opacity: 0.5;
    }
  }

  &-apply {
    &-toggle {
      @include heading($white, 1.4, 0, 700, false);
      background: $blue-dark;
      border: 0;
      padding: 1.7rem;
      position: relative;
      text-align: left;
      width: 100%;

      @media (min-width: $lg-width) {
        padding: 2rem 3rem 2rem 7.5rem;
      }

      &::before {
        @include poa(50%, null, null, 2.5rem);
        content: "\e92b";
        display: none;
        font-family: "icomoon";
        font-size: 3.2rem;
        font-weight: 400;
        transform: translateY(-50%);

        @media (min-width: $lg-width) {
          display: block;
        }
      }

      &::after {
        @include poa(50%, 2rem);
        content: "\e90b";
        font-family: "icomoon";
        font-size: 1rem;
        font-weight: 400;
        transform: translateY(-50%);

        @media (min-width: $lg-width) {
          right: 3rem;
        }
      }

      &.open::after {
        content: "\e90a";
      }
    }

    &-form {
      background: $grey-lighter;
      padding: 1.5rem;

      @media (min-width: $lg-width) {
        border-bottom: 0.1rem solid $grey-light;
        margin-bottom: 2rem;
        padding: 2rem 3rem;
      }

      .form-group {
        margin-bottom: 1rem;
        position: relative;
      }

      .discount_name {
        padding: 0 5rem 0 1rem;
      }

      .button {
        @include poa(0.4rem, 0.4rem, 0.4rem);
        font-size: 1.4rem;
        padding: 0.5rem 1rem 0.7rem 1rem;
      }
    }

    &-voucher {
      font-size: 1.2rem;

      @media (min-width: $lg-width) {
        font-size: 1.4rem;
      }

      &-title {
        display: block;
        font-weight: 700;
        margin-bottom: 0.5rem;

        @media (min-width: $lg-width) {
          margin-bottom: 1rem;
        }
      }

      &-code {
        cursor: pointer;
        display: inline-block;
        font-size: 1.2rem;
        text-decoration: underline;
      }

      &-name {
        display: block;
        font-size: 1.2rem;

        @media (min-width: $lg-width) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &-facilities.facilities {
    background: $white;
    margin: 0;
    padding: 0;
  }

  &-accessory {
    color: $orange;
    font-weight: 600;
    line-height: 1.1;
    margin-top: 1.5rem;
    padding: 0 1.5rem;

    @media (min-width: $lg-width) {
      padding: 0 3rem;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &-shipping-delay {
    color: $green;
    font-weight: 600;
    line-height: 1.3;
    padding: 0 1.5rem 1.5rem 1.5rem;

    @media (min-width: $sm-width) {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
    @media (min-width: $lg-width) {
      padding: 0 3rem 3rem 3rem;
    }
  }
}
