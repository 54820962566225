.reassurance-univers-home {
  background: $grey-lighter;
  color: $grey-medium;
  padding: 0 0 3rem 0;

  @media (min-width: $md-width) {
    padding: 0 0 5rem 0;
  }

  .advreassurance-title {
    font-size: 1.6rem;
  }

  .advreassurance-subtitle {
    display: block;
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }
}
