// used for configurator mostly
.color-brand {
  color: color(brand);
}

.color-brand-light {
  color: color(brand, light);
}

.color-brand-secondary {
  color: color(brand, secondary);
}

.color-highlight {
  color: color(text, highlight);
}

.color-success {
  color: color(text, success);
}
