.history {
  .account-list-item {
    @media (min-width: $md-width) {
      padding-right: 65px;
    }
  }

  .order-history-details {
    bottom: 0;
    left: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 0;

    @media (min-width: $md-width) {
      border-left: 1px solid $grey-light;
      height: 100%;
      left: auto;
      padding: 30px;
    }

    .icon-new {
      color: $black;
      display: none;
      font-size: 1.2rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @include animated();

      @media (min-width: $md-width) {
        display: block;
      }
    }

    &:hover {
      text-decoration: none;

      .icon-new {
        margin-left: 3px;
      }
    }
  }
}

/* Order détail */
.order-detail {
  .history-status-item {
    color: $blue-dark;
    font-weight: 700;
    padding: 1rem 1.5rem;
  }

  .return-checkbox-ctn {
    @include poa(0, 2rem);

    @media (min-width: $md-width) {
      left: 0;
      right: auto;
    }
  }

  .fieldset-ctn {
    margin-bottom: 1.5rem;

    @media (min-width: $md-width) {
      margin-bottom: 3rem;
    }
  }

  .cart-resume {
    margin-bottom: 0;

    @media (min-width: $sm-width) {
      margin-bottom: 3rem;
    }

    .fieldset-ctn {
      margin-bottom: 1.5rem;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
      }
    }
  }

  .account-bloc-message {
    + .account-bloc-message {
      margin-top: 2rem;
    }

    &-author {
      color: $black;
      font-weight: bold;
    }

    &-date {
      color: $grey;
      font-size: 1rem;
      margin-left: 0.3rem;
    }

    &-content {
      border: 0.1rem solid $grey-light;
      border-radius: 0.3rem;
      font-style: italic;
      margin-top: 0.5rem;
      padding: 1rem;
    }
  }
}

/* Adresses */
.addresses {
  .address-choice-infos {
    padding-left: 0;
  }
}

/* Identity */
.identity {
  .birthdate_choice {
    flex-grow: 2;
    max-width: 100%;
  }
}

/* Retours */
#order-follow {
  .follow-detail {
    .account-list-item {
      background: $white;
      margin-top: 1rem;
    }
  }

  .wait-confirmation {
    margin-top: 15px;

    p {
      color: $black;
      font-weight: 700;
    }
  }
}
