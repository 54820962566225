/* Product details */
.product-availability {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.4rem;
  padding: 1.4rem 0 1.5rem 0;
  position: relative;

  @media (min-width: $sm-width) {
    padding-bottom: 2rem;
  }

  .icon-check {
    @include poa(1.7rem, null, null, 0);
    font-size: 1rem;
  }

  .popover-trigger {
    margin: 0;
    position: relative;
  }
}
