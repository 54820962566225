/* Cross sell CMS */
.matrice {
  .cross-sell-cms-title {
    color: #01025c;
    font-size: 18px;
    font-weight: 900;
    height: 5rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .product-cross-sell-ctn {
    padding-top: 5rem;
    position: relative;

    @media (min-width: $xs-width) {
      margin-bottom: 4rem;
    }
    @media (min-width: $lg-width) {
      margin-bottom: 5rem;
    }

    .title-best {
      @include heading($blue-dark, 1.5, 0, 900, false);
      @include poa(0, 1.5rem, null, 1.5rem);
      display: block;
      height: 5rem;
      text-align: center;
      z-index: 2;

      @media (min-width: $md-width) {
        font-size: 1.5rem;
      }
    }

    .product-ctn {
      &-infos {
        height: inherit;
        margin-bottom: 1.5rem;
        padding: 0 1.5rem;
      }
    }
  }
}
