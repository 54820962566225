.shopping-cart-products {
  .delivery-delay-products {
    color: #00cb40;
    font-weight: 700;
    margin-top: 70px;

    &#delivery-delay1 {
      margin-top: 30px;
    }
  }
}

.shopping-cart-products .delivery-delay-stock {
  color: $blue-dark;
}

.shopping-cart-products {
  .shopping-cart-product {
    .delivery_delay,
    .delivery-delay {
      color: $blue-dark;
    }
  }
}

.shopping-cart-product:first-of-type {
  margin-bottom: 30px;
}

.shopping-cart {
  margin-bottom: 3rem;

  &-content {
    padding: 0 0.75rem;

    @media (min-width: $xs-width) {
      padding: 0 1.5rem;
    }
  }

  .shopping-cart-summary {
    box-shadow: none;
    height: auto;
    margin: 2rem -0.75rem 0 -0.75rem;
    position: static;
    transform: translateY(0);

    @media (min-width: $sm-width) {
      margin: 0;
    }
  }

  .shopping-cart-summary-reassurance {
    display: block;

    @media (min-width: $sm-width) {
      display: none;
    }
  }

  &-products-header {
    background: $grey-lighter;
    border: 0.1rem solid $grey-light;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    padding: 1.5rem 4.5rem 1.5rem 2.5rem;

    .prices-header {
      text-align: right;

      span:first-child {
        display: inline-block;
        margin-right: 3rem;
      }

      span:last-child {
        display: inline-block;
        width: 10.5rem;

        @media (min-width: $lg-width) {
          width: 12rem;
        }
      }
    }
  }
}

.submit {
  text-align: center;

  .button {
    width: 100%;

    @media (min-width: $md-width) {
      width: 55%;
    }
  }
}

.cart_navigation {
  border-top: 0.1rem solid $grey-light;
  padding: 1.5rem;

  @media (min-width: $lg-width) {
    padding: 1.5rem 3rem;
  }

  .button {
    margin-top: 1.5rem;
  }

  &.stack-mobile {
    @include pof(null, 0, 0, 0);
    background: $white;
    box-shadow: 0 -0.5rem 3rem rgba(0, 0, 0, 0.3);
    transform: translateY(101%);
    transition: transform 0.3s ease 0s;
    z-index: 10;

    .button {
      display: block;
      margin-top: 0;
    }

    &.open {
      transform: translateY(0);
    }
  }
}

#free-shipping-message{
  display: block;
  width: 100%;
  text-align: center;
  background-color: $yellow;
  padding: 15px;
  font-size: 16px;
  color: $blue-dark;
}