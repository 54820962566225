.contact-ctn {
  margin-bottom: 7rem;

  @media (min-width: $md-width) {
    .contact-cms {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
  }

  .advconstructor-contact {
    margin-bottom: 1.5rem;

    @media (min-width: $md-width) {
      height: 48%;
      margin-bottom: 0;
    }
  }

  .bloc-form {
    border: 1px solid $grey-light;
    height: 100%;
    margin-bottom: 3rem;
    padding: 30px 15px;

    @media (min-width: $md-width) {
      padding: 45px;
    }

    &-title {
      color: $blue-dark;
      display: block;
      font-size: 2.5rem;
      font-weight: 700;
      padding-bottom: 30px;
      text-align: center;
    }

    .submit {
      margin-top: 1.5rem;
    }
  }

  .gdpr_consent {
    border: 0;
  }
}
