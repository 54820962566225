@import "product/attributes";

.product-pictos {
  &-item {
    margin-right: 20px;
  }
}

.product-images-list {
  overflow: hidden;
  padding-bottom: 72.5%;
  position: relative;

  .owl-slider {
    @include poa(0, 0, 0, 0);
  }
}

.loader {
  margin: 0;
  padding: 0;

  .container {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 15px 20px;
    position: relative;
    width: 50%;
  }

  .Loading {
    background: #f1f1f1;
    border-radius: 4px;
    display: inline-block;
    height: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .Loading:after {
    animation: load 5s infinite;
    border-radius: 4px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 0;
  }

}

@keyframes load {
  0% {
    width: 0;
    background: #01025c;
  }

  25% {
    width: 40%;
    background: #01025c;
  }

  50% {
    width: 60%;
    background: #01025c;
  }

  75% {
    width: 75%;
    background: $green;
  }

  100% {
    width: 100%;
    background: $green;
  }
}

@keyframes pulse {
  0% {
    background: #01025c;
  }

  25% {
    background: #a0d2eb;
  }

  50% {
    background: #a0d2eb;
  }

  75% {
    background: $green;
  }

  100% {
    background: $green;
  }
}

.product-main {
  border-top: 0.1rem solid $grey-light;
  font-family: $muli;
  padding-top: 1.5rem;
  position: relative;

  .product-images {
    margin: 0 -1rem;

    @media (min-width: $sm-width) {
      padding-right: 55%;
    }
    @media (min-width: $md-width) {
      padding-right: 45%;
    }
    @media (min-width: $lg-width) {
      padding-right: 40%;
    }

    #thumbs_list_frame {
      li {
        margin: 0 5px;
      }
    }

    .product-images-tools {
      margin-bottom: 3rem;
      padding: 0 1.5rem;
      position: relative;
    }

    .product-tools-right {
      border: 0.1rem solid $grey-light;
      border-radius: 3px;
    }

    .product-tool,
    .owl-prev,
    .owl-next {
      @include animated(opacity);
      border-left: 0.1rem solid $grey-light;
      color: $grey;
      cursor: pointer;
      display: inline-block;
      font-size: 1.6rem;
      opacity: 0.5;
      padding: 1rem 1.1rem 1rem 1.3rem;
      position: relative;

      &:hover {
        opacity: 1;
      }

      a {
        text-decoration: none;
      }
    }

    .product-tool:first-child,
    .owl-prev {
      border: 0;
    }

    .fb-share-button {
      opacity: 0.5;
    }

    .product-tool {
      &.zoom {
        bottom: 70px;
        position: absolute;
        right: 0;
        z-index: 9999;
      }
    }
  }

  .product-infos {
    background: $white;
    margin: 0 -1rem;
    max-width: 440px;

    @media (min-width: $xs-width) {
      margin: 0 auto;
    }
    @media (min-width: $sm-width) {
      @include poa(0, 1.5rem);
      width: 50%;
      z-index: 21;
    }
    @media (min-width: $md-width) {
      width: 40%;
    }
    @media (min-width: $lg-width) {
      width: 34%;
    }
  }

  .product-filigrane {
    overflow: hidden;
    position: relative;

    @media (min-width: $sm-width) {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    .lazy-ctn {
      padding-bottom: 11.22%;
    }
  }

  .product-details {
    box-shadow: 0 0 6rem rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 1.5rem;

    @media (min-width: $lg-width) {
      padding: 1.5rem 3rem;
    }
  }

  .product-manufacturer img {
    max-height: 4rem;
  }

  .product-title {
    color: $blue-dark;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.1;
    margin: 0.5rem 0 1rem 0;

    @media (min-width: $sm-width) {
      font-size: 2.2rem;
      margin: 1rem 0 2rem 0;
    }

    .product-name {
      display: inline;
      font-size: inherit;
      margin: 0;
    }

    .additional-info {
      display: inline;
    }
  }

  .product-resume {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 1.5rem;
    padding-right: 0;

    p {
      margin-bottom: 0;

      strong {
        font-weight: bold;
      }
    }
  }

  .product-topfeatures-ctn {
    margin-bottom: 1.5rem;
  }

  .premium-mea {
    color: $red;
    font-size: 1.4rem;
    font-weight: 900;
    padding-left: 1.5rem;

    .phone {
      font-size: 1.8rem;
      white-space: nowrap;
    }
  }

  .product-prices {
    margin-top: 5px;

    @media (min-width: $sm-width) {
      margin-bottom: 1rem;
    }

    .started_price_message {
      display: inline-block;
      margin: 0 2rem 0.5rem 0;

      &.ope30-active {
        display: none;
      }
    }

    .old-price {
      display: inline-block;
      font-size: 1.6rem;
      margin: 0 1.5rem 0.3rem 0;
    }

    .current-price {
      color: $red;
      font-size: 3rem;
      font-weight: 900;
      white-space: nowrap;

      &.ope30-active {
        color: $blue-dark;
        font-size: 2rem;

        @media (min-width: $sm-width) {
          font-size: 2.4rem;
        }
      }
    }

    &.advcms-cms .padded {
      padding: 15px 0;
      @media (min-width: $sm-width) {

      }
      @media (min-width: $xl-width) {

      }
      @media (min-width: 2500px) {
        margin: 0 auto;
        max-width: 1230px;
        padding: 0;
      }
    }

  }

  .price-ecotax {
    color: $grey-medium;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .buttons_bottom_block {
    align-items: center;
    display: flex;
    flex-flow: row nwrap;
    justify-content: space-between;
  }

  .box-cart-product-qty {
    margin-right: 1rem;
    position: relative;
  }

  .box-cart-product-qty .cart_quantity_input {
    background: transparent;
    border: 0.1rem solid #dadada;
    border-radius: 0.3rem;
    color: #656565;
    height: 4rem;
    padding-right: 2.5rem;
    text-align: center;
    width: 7rem;
  }

  .box-cart-product-qty .cart_quantity_button {
    border-left: 0.1rem solid #dadada;
    bottom: 0;
    font-size: 0.5rem;
    left: 4.5rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.5rem;
  }

  .box-cart-product-qty .product_quantity_up, .box-cart-product-qty .product_quantity_down {
    border-bottom: 0.1rem solid #dadada;
    color: #494949;
    display: block;
    height: 50%;
    line-height: 2.2rem;
    opacity: 0.6;
  }

  .product-description {
    background: $grey-lighter;
    color: $black;
    font-size: 1.6rem;
    line-height: 2.2rem;
    min-height: 11em;

    .product-description-ctn {
      padding: 2rem 1.5rem;

      @media (min-width: $sm-width) {
        padding: 4rem 1.5rem;
      }
    }

    .product-description-content {
      font-size: 1.4rem;

      @media (min-width: $sm-width) {
        font-size: 1.6rem;
        padding-right: 55%;
      }
      @media (min-width: $md-width) {
        padding-right: 45%;
      }
      @media (min-width: $lg-width) {
        padding-right: 40%;
      }
    }
  }

  .product-notices {
    margin-top: 3rem;

    .product-notice {
      display: inline-block;
      margin-bottom: 1rem;
      width: 100%;

      @media (min-width: $sm-width) {
        padding-right: 3rem;
        width: auto;
      }
    }

    .product-notice-link {
      color: inherit;
      font-size: 1.5rem;

      @media (min-width: $sm-width) {
        font-size: 1.4rem;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}

.product-menu {
  @include poa(0, 0, null, 0);
  background: $blue-dark;
  text-align: center;
  text-transform: uppercase;
  z-index: 20;

  .product-menu-item {
    display: inline-block;
    padding: 2rem;
  }

  .product-menu-link {
    color: $white;
    font-size: 1.5rem;
    font-weight: 700;
    position: relative;
    text-decoration: none;

    &::before {
      @include poa(null, 50%, -0.2rem, 50%);
      background: $white;
      content: "";
      height: 0.15rem;
      transition: left 0.25s ease-out 0s, right 0.25s ease-out 0s;
    }

    &.active::before,
    &:hover::before {
      left: 0;
      right: 0;
    }
  }
}

.product-block {
  border-bottom: 0.1rem solid $grey-light;

  header {
    background: $white;

    @media (min-width: $sm-width) {
      border-bottom: 0.1rem solid $grey-light;
    }
  }

  &.open {
    header {
      background: $grey-lighter;
    }

    .product-block-title .icon::before {
      content: "\e90a";
    }

    .product-block-content {
      border-top: 0.1rem solid $grey-light;
      display: block;
    }
  }

  .product-block-title {
    cursor: pointer;
    font-size: 1.6rem;
    margin: 0;
    padding: 1.3rem 1.5rem 1.2rem 1.5rem;
    position: relative;
    text-transform: uppercase;

    @media (min-width: $sm-width) {
      cursor: unset;
      font-size: 2.4rem;
      padding: 3rem 1.5rem;
      text-align: center;
    }

    .icon {
      @include poa(1.5rem, 1.5rem);
      font-size: 1rem;

      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }

  .product-block-content {
    display: none;
    padding: 1.5rem 0;

    @media (min-width: $sm-width) {
      display: block;
      padding: 3.5rem 0;
    }
  }

  &:last-child {
    border: 0;
  }
}

.pack-list {
  justify-content: start;

  @media (min-width: $sm-width) {
    justify-content: center;
  }

  .product-desc {
    display: none;

    @media (min-width: $sm-width) {
      display: block;
    }
  }
}

.feature-note {
  flex-grow: 2;
  padding: 0.6rem 1rem;

  @media (min-width: $sm-width) {
    padding: 0.5rem 1.5rem 0 1.5rem;
  }

  .feature-note-text {
    background: $blue-dark;
    border-radius: 0.3rem;
    color: $white;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.4rem 0.6rem 0.5rem 0.6rem;
  }

  .feature-note-graph-ctn {
    border-radius: 2rem;
    height: 0.5rem;
    overflow: hidden;
    position: relative;
  }

  .feature-note-bg,
  .feature-note-cursor {
    @include poa(0, 0, 0, 0);
    background: $grey-light;
    border-radius: 2rem;
  }

  .feature-note-cursor {
    @include animated(right, 0.5s, ease-out);
    background: $blue-dark;
    right: 101%;
  }
}

.product-complementary {
  border-top: 0.1rem solid $grey-light;
  overflow: hidden;
  position: relative;

  @media (min-width: $lg-width) {
    border: 0;
    padding: 5.9rem 0;
  }

  .padded {
    padding: 0 1rem;

    @media (min-width: $xs-width) {
      padding: 0 1.5rem;
    }
    @media (min-width: $md-width) {
      padding: 0 15%;
    }
  }

  .lcdl-review-ctn {
    background: $blue-dark;
    color: $white;
    padding: 3rem 0;

    .ctn {
      padding: 0 1.5rem;
    }

    .lcdl-rewiew-note-ctn {
      position: relative;
      text-align: center;

      .icon-moon {
        @include poa(-1rem, 50%);
        color: $blue;
        font-size: 16rem;
        line-height: 1;
        opacity: 0.5;
        transform: translateX(50%);
        z-index: 1;

        @media (min-width: $sm-width) {
          font-size: 27rem;
          top: 0;
        }
      }

      .lcdl-rewiew-title {
        display: block;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 1.2rem;
        position: relative;
        z-index: 2;

        @media (min-width: $sm-width) {
          font-size: 4rem;
        }
        @media (min-width: $md-width) {
          font-size: 5rem;
        }

        br {
          display: none;

          @media (min-width: $xs-width) {
            display: block;
          }
        }
      }

      .lcdl-stamp {
        display: inline-block;
        max-width: 100%;
        position: relative;
        z-index: 2;
      }
    }

    .lcdl-review {
      font-size: 1.4rem;
      line-height: 1.5;
      margin-top: 2rem;
      position: relative;

      @media (min-width: $lg-width) {
        font-size: 1.6rem;
        padding-right: 15rem;
      }
    }

    .lcdl-rewiew-howto-ctn {
      color: $white;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1rem;
      position: relative;
      z-index: 2;

      .icon {
        color: inherit;
      }
    }

    .value-description p {
      font-size: 1.4rem;
    }
  }

  .featured-features {
    @media (min-width: $sm-width) {
      max-width: 100%;
    }

    .featured-features-title {
      @include heading($blue-dark, 1.6, 1.4, 700, false);
      margin-top: 0;
      padding: 0 1.5rem;

      @media (min-width: $sm-width) {
        max-width: 100%;
        padding: 0;
      }
    }

    .feature-line {
      .feature-name {
        flex-grow: 1;
        padding-bottom: 0.6rem;
        padding-top: 0.6rem;

        @media (min-width: $sm-width) {
          padding: 0.5rem 1.5rem 0 1.5rem;
        }
      }

      .feature-value {
        margin-bottom: 1rem;
        width: 100%;

        @media (min-width: $sm-width) {
          width: auto;
        }
      }
    }
  }

  .product-features {
    .product-notices {
      border: 0.1rem solid $grey-light;
      border-radius: 0.3rem;
      margin: 5rem 0;

      .product-notice {
        @include animated(background);
        border-left: 0.1rem solid $grey-light;

        &:first-child {
          border: 0;
        }

        &:hover {
          background: $grey-light;
        }
      }

      .product-notice-link {
        display: block;
        font-weight: 700;
        padding: 1.5rem;
        text-align: center;
        text-decoration: none;

        .icon {
          display: block;
          font-size: 1.6rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .feature-lines {
    margin-bottom: 1rem;

    @media (min-width: $sm-width) {
      margin-bottom: 3rem;
    }
  }

  .feature-line {
    line-height: 1.1;
    margin: 0 -1rem;
    padding: 1rem 1.5rem;

    @media (min-width: $xs-width) {
      margin: 0 -1.5rem;
    }
    @media (min-width: $sm-width) {
      margin: 0;
      padding: 1.5rem 1.5rem 1.5rem 0;
    }

    .feature-name {
      color: $black;
      flex-grow: 2;
      font-weight: bold;
      padding: 0.6rem 0;

      @media (min-width: $sm-width) {
        padding: 0 1.5rem;
      }
    }

    .feature-value {
      flex-grow: 4;
      font-size: 1.1rem;

      a {
        text-decoration: underline;
      }

      @media (min-width: $sm-width) {
        font-size: 1.4rem;
        padding: 0 2rem;
      }

      .howto {
        float: right;
        font-size: 1.2rem;
      }

      .feature-img {
        display: block;
        max-width: 5rem;
      }
    }

    &.feature-line1 {
      background: $grey-lighter;
    }

    &.feature-lcdl-note {
      background: $blue;
      color: $white;

      .feature-name {
        color: inherit;
      }

      .icon {
        color: inherit;
      }
    }
  }

  $i: 1;

  @while $i <= 10 {
    .feature-line:nth-child(#{$i}) .feature-note-cursor {
      transition-delay: 0.15s * $i;
    }
    $i: $i + 1;
  }

  .product-brand {
    padding-top: 0;

    .manufacturer-heading {
      &-title {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
        margin-top: 0;

        @media (min-width: $md-width) {
          height: 346px;
        }
      }

      &-desc {
        font-size: 1.4rem;
        margin-bottom: 30px;
        max-width: 100%;
        width: 700px;

        @media (min-width: $md-width) {
          font-size: 1.6rem;
        }
      }
    }

    .buttons-ctn {
      margin-bottom: 30px;
    }

    .manufacturer-name {
      @include heading($black, 1.8, 1, 900, false);

      @media (min-width: $sm-width) {
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
      }
    }

    .manufacturer-content {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 3rem;

      @media (min-width: $sm-width) {
        font-size: 1.6rem;
        padding-right: 15%;
      }

      &-ctn {
        padding: 1.5rem;

        @media (min-width: $sm-width) {
          padding: 3rem 0;
        }
      }
    }

    .manufacturer-link {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 1rem;
      padding: 1.2rem 2.5rem 1.2rem 1.2rem;
      width: 100%;

      @media (min-width: $sm-width) {
        margin-right: 1.5rem;
        width: auto;
      }

      span {
        display: inline-block;
        line-height: 1.5rem;
      }

      .icon {
        @include poa(50%, 1rem);
        font-size: 1.2rem;
        transform: translateY(-50%);
      }
    }
  }
}

.box-click-and-test {
  margin-bottom: 50px;
  margin-top: 30px;

  a {
    &:hover {
      text-decoration: none;
    }

    span {
      color: #45c654;
      font-weight: bold;
      margin-right: 10px;
    }

    .icon {
      font-size: 1.7rem;
      position: relative;
    }
  }

  &.default {
    text-align: center;

    a {
      background: transparentize(#45c654, 0.8);
      padding: 7px 10px 10px;

      span {
        border-bottom: 1px solid #45c654;
      }

      .icon {
        top: 2px;

        &::before {
          color: #45c654;
        }
      }
    }
  }

  &.available,
  &.notAvailable {
    text-align: left;
    width: 100%;

    a {
      padding: 12px 10px 12px 18px;

      span {
        display: block;
        font-weight: 700;
        width: 100%;

        &.available-store {
          color: $blue-dark;
          font-weight: 900;
        }
      }
    }
  }

  &.available {
    background: #f4fcf5;
    border: 1px solid transparentize(#45c654, 0.8);

    a {
      span {
        color: #45c654;
      }
    }
  }

  &.notAvailable {
    background: $white;
    border: 1px solid transparentize($orange, 0.8);

    a {
      span {
        color: $orange;
      }
    }
  }
}

.product-marketing {
  border-top: 0.1rem solid $grey-light;
  line-height: 2rem;

  h3 {
    @include heading($blue-dark, 1.6, 1, true, false);
    margin-top: 0;
  }

  .video-ctn {
    margin: 1.5rem 0 0 0;
    position: relative;

    @media (min-width: $sm-width) {
      margin: 0;
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: 50%;
    }

    .video {
      @include poa(0, 0);
      @include size(100%);
    }
  }

  iframe {
    height: auto;
    width: 100%;
  }
}

.product-add-to-cart-mobile {
  @include pof(null, 0, 0, 0);
  background: $white;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  padding: 1rem 1.5rem;
  z-index: 5;
}

.product-replacement {
  padding-top: 1.5rem;

  @media (min-width: $xs-width) {
    padding: 0 20%;
  }
  @media (min-width: $sm-width) {
    padding: 0 1.5rem;
  }

  &-title {
    @include heading($blue-dark, 2.2, 1, 700, false);
    display: block;
    text-align: center;
  }

  .product-replace-by,
  .product-replace {
    margin-top: 4.2rem;

    .product-desc {
      color: $red;
    }

    .current-price {
      font-size: 1.8rem;

      @media (min-width: $sm-width) {
        font-size: 1.8rem;
      }
      @media (min-width: $md-width) {
        font-size: 2.2rem;
      }
    }
  }

  .product-ctn-infos {
    @media (min-width: $sm-width) {
      height: 28.5rem;
      margin-bottom: 0;
    }
    @media (min-width: $md-width) {
      height: 38rem;
    }
    @media (min-width: $lg-width) {
      height: 30rem;
    }
    @media (min-width: $xl-width) {
      height: 34rem;
    }
  }

  .product-ctn-btn {
    padding: 1.5rem;
  }

  .icon-arrow-right {
    @include size(6rem);
    background: $grey-light;
    border: 0.5rem solid $white;
    border-radius: 50%;
    color: $grey;
    line-height: 5rem;
    text-align: center;
    transform: rotate(90deg) translate(-50%, 0);
    z-index: 2;

    @media (min-width: $sm-width) {
      @include poa(40%, 50%);
      transform: rotate(0) translate(50%, -50%);
    }

    &::before {
      font-size: 1.4rem;
      line-height: 1;
      opacity: 0.5;
      padding-left: 0.1rem;
    }
  }
}

.ope30 {
  margin: 0 -1.5rem;
  overflow: hidden;

  @media (min-width: $lg-width) {
    margin: 0 -3rem;
  }

  &-txt {
    color: $white;
    display: block;
    font-size: 1.2rem;
    height: 3.5rem;
    padding: 1rem 0 0 1.5rem;
    position: relative;

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
      height: 4.5rem;
      padding: 1.3rem 0 0 1.5rem;
    }
    @media (min-width: $lg-width) {
      padding: 1.3rem 0 0 3rem;
    }

    &-label {
      position: relative;
      z-index: 1;
    }

    &::before {
      @include poa(0, -1.5rem, null, null);
      @include size(3.5rem);
      content: "";
      transform: rotate(45deg);
      z-index: 1;

      @media (min-width: $sm-width) {
        @include size(4.5rem);
      }
    }
  }

  &-value {
    color: $red;
    font-size: 2.4rem;
    font-weight: 900;
    padding: 0 1.5rem 0 3.5rem;

    @media (min-width: $sm-width) {
      font-size: 2.8rem;
      padding: 0 3rem 0 4rem;
    }
  }
}

.shoptest {
  &-block {
    background-color: $blue-dark;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    text-align: center;
  }

  &-inner {
    margin: 0 auto;
    max-width: 100%;
    width: 400px;
  }

  &-title {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    @media (min-width: $md-width) {
      font-size: 3.5rem;
    }

    @media (min-width: $lg-width) {
      font-size: 4rem;
      margin-bottom: 3rem;
    }

    &::before {
      background: url("~img/icon-store-locator-fp.svg") no-repeat center;
      background-size: contain;
      content: "";
      display: block;
      height: 2em;
      margin-bottom: 1.5rem;

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
      }
    }
  }

  &-content {
    font-weight: 700;
    line-height: 1.8;
    margin: 0 auto;
    max-width: 100%;
    width: 350px;
  }

  &-link {
    margin-top: 2rem;

    @media (min-width: $lg-width) {
      margin-top: 5rem;
    }

    .icon {
      font-size: 1.5em;
      vertical-align: middle;
    }
  }

  &-img {
    &-ctn {
      line-height: 0;
      padding: 0;
      text-align: center;
    }
  }
}

.ipanel .delivery-delay-product {
  color: #01025c;
  text-decoration: underline !important;
}

.title-reco {
  color: #01025c;
  font-size: 24px;
  font-weight: 900;
  height: 5rem;
  margin-top: 3rem;
  text-align: center;
}

.paypal {
  margin-bottom: 1.5rem;
  padding-top: 0;
  text-align: center;

  @media (min-width: $lg-width) {
    margin-bottom: 3rem;
  }

  &-panel-btn,
  &-price,
  &-simulation .simResult .value,
  &-simulation .highlight {
    color: $paypal-primary-color;
  }

  &-simulation .highlight {
    font-weight: 700;
  }

  &-mensualities {
    background-color: $paypal-primary-color;
    border-radius: 50%;
    color: white;
    padding: 3px 4px;
  }
}

.payment-simulation .panel-subblock .logo {
  margin-bottom: 0;
}

.box-cart-bottom {
  margin-top: 15px;
}