.panel-exists-in-pack {
  background-color: $white;

  .panel-head {
    height: 85px;
  }

  .panel-head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 45px;

    &,
    & .panel-title {
      background-color: $blue-dark;
    }

    .panel-title,
    .panel-close {
      color: #fff;
      padding: 0;
    }

    .panel-close {
      position: static;
    }
  }

  .panel-content {
    padding: 45px 15px;

    @media (min-width: $md-width) {
      padding: 45px;
    }
  }

  .panel-product {
    border: 1px solid $grey-light;
    margin-bottom: 15px;
    padding: 10px;
  }

  .panel-product-img-ctn {
    order: -1;
    width: 93px;
    margin-right: 10px;

    @media (min-width: $md-width) {
      width: 192px;
    }
  }

  .panel-product-availability-ctn {
    margin-top: -20px;
    padding-left: 103px;

    @media (min-width: $md-width) {
      margin-top: -30px;
      padding-left: 202px;
    }
  }

  .price_reduc {
    &::before {
      font-size: 12px;
      width: auto;
      position: static;
      white-space: normal;
    }

    @media (max-width: $md-width - 1) {
      &::before {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .panel-product-prices {
    @media (max-width: $md-width - 1) {
      padding: 10px;
    }
  }

  .product-ctn-price {
    @media (max-width: $md-width - 1) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      line-height: 1.5;
    }

    .old-price {
      margin-right: 10px;

      @media (min-width: $md-width) {
        margin-bottom: 3px;
        margin-right: 0;
      }
    }
  }
}

.js-panel-pack {
  display: none;
}

.pack-panel-btn {
  margin-top: 20px;
}
