.panel-clickandtest {
  .clickandrdv {
    &-form {
      cursor: default;
      padding: 2rem;

      .store-footer-title {
        text-align: left;
      }
    }

    &-submit {
      margin-top: 15px;
      padding: 1.7rem 2rem;
      width: 100%;

      @media (min-width: $md-width) {
        margin-left: 14.5rem;
        margin-top: 30px;
        padding: 1.7rem 2.5rem;
        width: auto;
      }
    }
  }

  .alert-clickandrdv {
    .icon-check-thin {
      border: 1px solid $white;
      border-radius: 50%;
      margin-right: 15px;
      padding: 10px;
    }

    &.alert-success {
      span {
        max-width: 100%;
        width: 380px;
      }
    }
  }

  .stores-list-container {
    transition: all 0.5s ease 0s;

    &.loading {
      -webkit-filter: blur(2px);
      filter: blur(2px);
      opacity: .5;
    }
  }

  .stores-list {
    padding: 20px 15px;
    @media (min-width: $md-width) {
      padding: 30px 50px;
    }

    .store-item {
      border: 1px solid $grey-light;
      border-radius: 0.4rem;
      cursor: pointer;

      .store-title-ctn {
        background: $grey-lighter;
        border-bottom: 1px solid $grey-light;
        padding: 15px;
        @media (min-width: $md-width) {
          padding: 20px 20px 20px 30px;

        }

        .store-title {
          color: $blue-dark;
          font-size: 1.4rem;
          font-weight: 900;
          line-height: 1.3;
          @media (min-width: $md-width) {
            font-size: 1.5rem;
            line-height: 1.4;

          }
        }

        .store-link {
          margin-top: 15px;
          width: 100%;
          @media (min-width: $md-width) {
            margin: 0;
            width: auto;
          }

          &:hover {
            text-decoration: none;
          }

          span {
            font-size: 1.4rem;
            font-weight: 700;
            margin-right: 15px;
          }

          .icon {
            font-size: 1.2rem;
          }
        }
      }

      .store-infos {
        padding: 15px;
        position: relative;
        @media (min-width: $md-width) {
          padding: 20px 30px 25px;

        }

        ul {
          margin-bottom: 20px;

          li {
            color: $black;
            font-size: 1.3rem;
            @media (min-width: $md-width) {
              font-size: 1.4rem;

            }

            + li {
              margin-top: 10px;

            }
          }
        }

        .store-availability {
          border-radius: 0.4rem;
          font-weight: bold;
          padding: 10px 15px 10px 10px;
          width: 100%;
          @media (min-width: $sm-width) {
            width: auto;
          }

          .icon {
            display: inline-block;
            float: left;
            font-size: 1.6rem;
            width: 25px;
            @media (min-width: $md-width) {
              float: none;
            }
          }

          span {
            display: inline-block;
            position: relative;
            top: -1px;
            width: calc(100% - 30px);
            @media (min-width: $sm-width) {
              white-space: nowrap;
            }
          }

          &.isNotAvailable {
            background: transparentize($orange, 0.8);
            color: $orange;
          }

          &:not(.isNotAvailable) {
            background: transparentize(#45c654, 0.8);
            color: #45c654;
          }
        }

        .store-favorite {
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 15px;
          @media (min-width: $md-width) {
            position: relative;
            right: 0;
            top: 0;
          }

          span {
            @include animated();

            &:hover {
              text-decoration: none;
            }

            .icon {
              font-size: 2rem;

              &:before {
                @include animated();

              }
            }
          }

          &.isFav {
            span {
              color: $red;
              display: none;
              font-weight: 700;
              margin-right: 10px;

              @media (min-width: $md-width) {
                display: block;
              }
            }

            a {
              &:hover {
                .icon {
                  &:before {
                    content: "\e94c";
                  }
                }
              }
            }
          }

          &:not(.isFav) {
            a {
              &:hover {
                .icon {
                  &:before {
                    // content:"\e94d";
                    color: $red;
                  }
                }
              }
            }
          }
        }
      }

      + .store-item {
        margin-top: 25px;
      }

      &:hover {
        border-color: black;

        .store-favorite {
          .icon {
            &:before {
              color: $red;
              content: "\e94d";
            }
          }
        }
      }
    }
  }

  .message-info {
    margin: 20px 15px 0;

    @media (min-width: $md-width) {
      margin: 30px 50px 0;
    }

    strong {
      font-weight: 700;
    }
  }

  .button-form-contact {
    width: 100%;

    @media (min-width: $md-width) {
      width: auto;
    }
  }

  .infos-availability {
    background: transparentize($orange, 0.8);
    border-radius: 0.4rem;
    color: $orange;
    margin-left: 15px;
    margin-right: 15px;
    padding: 8px 10px 8px 45px;
    position: relative;
    @media (min-width: $md-width) {
      margin-left: 50px;
      margin-right: 50px;
    }

    .icon {
      font-size: 2.1rem;
      left: 10px;
      position: absolute;
      top: 8px;
    }

    p {
      font-weight: bold;
      line-height: 1.4;
    }
  }
}
