.color {
  &-list {
    margin: 5px 0 10px;
    overflow: auto;
  }

  &-item {
    border: 2px solid transparent;
    margin-left: 10px;

    &:first-of-type {
      margin: 0;
    }

    &:not(.hidden) {
      display: block;
    }

    &.selected {
      border-color: $blue-dark;
    }
  }
}

.more-color {
  border: 1px solid $grey-light;
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin-left: 10px;
  text-align: center;
}
