.owl-dots {
  @include poa(null, 0, 2rem, 0);
  text-align: center;

  @media screen and (min-width: $md-width) {
    bottom: 3rem;
  }
}

.owl-carousel .owl-dots .owl-dot {
  @include size(0.8rem);
  background-color: $white;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.2rem;
  opacity: 0.5;
  transition: 0.3s;

  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }
}
