.advconstructor-help {
  background: $yellow;
  font-family: $muli;
  position: relative;
  z-index: 2;

  @media (min-width: $md-width) {
    margin-top: 0;
  }

  .advconstructor-help-bg {
    @include poa(0, 50%, 0, 0);
    display: none;

    @media (min-width: $md-width) {
      display: block;
    }

    img {
      @include size(100%);
      object-fit: cover;
    }
  }

  .advconstructor-content {
    color: $blue-dark;
    padding: 3rem 0;
    position: relative;
    text-align: center;

    @media (min-width: $md-width) {
      margin-left: 50%;
      padding: 3rem 1.5rem 3rem 8%;
      text-align: left;
      width: 50%;
    }
  }

  .advconstructor-content-icon {
    @include poa(-3rem, null, null, 50%);
    color: $yellow;
    font-size: 6rem;
    margin: 0 0 0 -3rem;

    @media (min-width: $md-width) {
      @include poa(50%, null, null, -4rem);
      font-size: 80px;
      margin: -48px 0 0 0;
    }

    &::before {
      @include poa(1.5rem, 1.5rem, 1.5rem, 1.5rem);
      background: $blue-dark;
      border-radius: 50%;
      content: "";
      z-index: 1;
    }

    i {
      display: block;
      position: relative;
      z-index: 2;
    }
  }

  .advconstructor-content-title {
    display: block;
    font-size: 2rem;
    font-weight: 900;
    line-height: 2.4rem;
    padding: 0 15%;

    @media (min-width: $md-width) {
      font-size: 3rem;
      line-height: 3rem;
      padding: 0;
    }
  }

  .advconstructor-content-subtitle {
    display: block;
    font-size: 2rem;
    font-weight: 900;
    line-height: 2.4rem;
    padding: 0 15%;

    @media (min-width: $md-width) {
      font-size: 3rem;
      line-height: 3rem;
      padding: 0;
    }
  }

  .advconstructor-content-phone {
    color: inherit;
    display: block;
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 1rem 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .advconstructor-content-text {
    line-height: 2rem;

    a {
      color: inherit;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
