.advreassurance {
  &-img-ctn {
    @include animated(transform);
    display: inline-block;
    height: 5.2rem;

    img {
      max-width: 50%;
    }
  }
}
