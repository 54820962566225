.block-univers {
  overflow: hidden;
  padding: 3rem 0 4.5rem 0;

  @media (min-width: $sm-width) {
    padding: 3rem 0 0 0;
  }
}

.block-cats {
  margin: 0 -1rem;

  @media (min-width: $sm-width) {
    margin: 0 -1.5rem 0 -1.5rem;
  }

  > div {
    margin-bottom: 1px;

    @media (min-width: $sm-width) {
      margin-bottom: 3rem;
    }
  }

  &_ctn {
    margin: 0 auto;

    @media (min-width: $sm-width) {
      padding: 0 1.5rem;
    }
    @media (min-width: $md-width) {
      width: $md-ctn-width;
    }
    @media (min-width: $lg-width) {
      width: $lg-ctn-width;
    }
    @media (min-width: $xl-width) {
      width: $xl-ctn-width;
    }
  }

  &_header {
    position: relative;
    text-align: left;

    &.open {
      .icon::before {
        content: "\e90a";
      }
    }

    @media (min-width: $sm-width) {
      overflow: hidden;

      &:hover {
        .block-cats_bg {
          transform: scale(1.1);
        }
      }
    }
  }

  &_content {
    display: none;
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: $sm-width) {
      display: block;
      margin-bottom: 0;
    }
  }

  &_bg {
    display: none;

    @media (min-width: $sm-width) {
      background-size: cover;
      display: block;
      min-height: 190px;
      transition: 0.8s;
    }
    @media (min-width: $sm-width) {
      min-height: 110px;
    }
    @media (min-width: $md-width) {
      min-height: 190px;
    }
  }

  &_title {
    a {
      background-color: $grey-medium;
      color: $white;
      display: flex;
      font-size: 1.6rem;
      font-weight: 700;
      justify-content: space-between;
      padding: 1.5rem;

      @media (min-width: $sm-width) {
        @include heading($white, 2, 0, true, false);
        @include poa(0, 0, 0, 0);
        background-color: transparent;
        font-weight: 900;
        padding: 1.5rem 2rem;
        z-index: 2;

        .icon {
          display: none;
        }
      }
      @media (min-width: $md-width) {
        font-size: 2.4rem;
        z-index: 2;

        .icon {
          display: none;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    &::before {
      @media (min-width: $sm-width) {
        @include poa(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0.4);
        content: "";
        z-index: 1;
      }
    }
  }

  &_inner {
    height: 100%;
    overflow: hidden;
    text-align: center;

    @media (min-width: $sm-width) {
      border: 1px solid $grey-light;
      border-radius: 0 0 0.2rem 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.open {
      .block-cats_button,
      .block-cats_links {
        display: inline-block;
      }
    }
  }

  &_links {
    display: none;
    font-weight: 700;
    padding: 2rem;
    text-align: left;
    width: 100%;

    @media (min-width: $sm-width) {
      display: block;
    }

    a {
      color: $grey-medium;
      display: block;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        color: $grey;

        .icon {
          text-decoration: none;
        }
      }
    }

    .icon {
      color: $blue-dark;
      font-size: 0.7em;
      padding-right: 1rem;

      @media (min-width: $sm-width) {
        padding-right: 0.5rem;
      }
      @media (min-width: $md-width) {
        padding-right: 1rem;
      }
    }
  }

  &_button {
    border-radius: 0 0 0.2rem 0.2rem;
    color: $grey;
    display: none;
    margin-bottom: 3rem;
    padding: 0.8rem 1.5rem;
    transition: 0.3s;

    .icon {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    @media (min-width: $sm-width) {
      align-items: center;
      background-color: $grey-medium;
      color: $white;
      display: flex;
      font-size: 1.4rem;
      font-weight: 700;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 1.5rem 1rem 1.5rem 2rem;
      width: 100%;

      &:hover {
        background-color: $grey;
        color: $white;
      }

      .icon {
        display: block;
        font-size: 1.2rem;
      }
    }
  }

  &-no-links {
    .block-cats_button {
      border: 0;
      border-radius: 0.2rem;
      color: $white;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 1.5rem 1rem;
      width: 100%;
    }
  }
}
