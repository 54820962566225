/* Product details */
.product-additionals {
  .trigger {
    color: $grey-medium;
    display: inline-block;
    margin-bottom: 1rem;

    .icon {
      display: inline-block;
      font-size: 1.8rem;
      margin-right: 0.5rem;
      position: relative;
      top: 0.4rem;
    }

    &.open .icon::before {
      content: "\e929";
    }
  }

  .product-additional-ctn {
    display: none;
  }

  .product-additional {
    border: 0.1rem solid $grey-light;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    position: relative;

    .product-additional-img {
      padding: 0 1rem;
    }

    .product-additional-details {
      padding: 0.5rem 1rem;
    }

    .product-additional-name {
      display: block;
      font-weight: 700;
      margin-bottom: 0.3rem;
    }

    .product-additional-attributes {
      display: block;
      margin-bottom: 1rem;
    }

    .product-additional-prices {
      font-size: 1.6rem;
    }

    .product-additional-old-price {
      padding-right: 1.5rem;
    }

    .product-additional-current-price {
      color: $red;
      font-weight: 900;
    }

    .product-additional-link {
      @include poa(0.5rem, 0.5rem);
      color: inherit;
      text-decoration: none;
    }

    &.open {
      border: 0.2rem solid $green;
    }

    &.show {
      display: flex;
    }
  }
}
