.sitemap {
  .auth-content {
    margin-top: 0;

    @media (min-width: $sm-width) {
      margin-top: 2rem;
    }
  }

  .tree {
    line-height: 2.5rem;

    > .tree-list {
      margin-bottom: 3rem;

      > a {
        color: $blue-dark;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    .tree-list {
      padding-left: 1.5rem;

      ul {
        margin-bottom: 1rem;

        ul {
          font-size: 1.2rem;
          font-style: italic;
          line-height: 1.2rem;

          li {
            padding: 0.5rem 0 0.5rem 1rem;
          }
        }
      }
    }
  }
}
