.product-ctn-inner {
  .product-reviews {
    .stars {
      display: inline-block;
    }
  }
}

.product-reviews {
  .sceau {
    margin-right: 0.5rem;
    max-width: 3.5rem;
  }

  .stars {
    position: relative;

    .icon {
      color: $white;
      display: inline-block;
      font-size: 2rem;
      position: relative;
      z-index: 3;
    }
  }

  .cursor {
    @include animated(right, 0.5s, ease-out);
    @include poa(15%, 5%, 15%, 2%);
    background: $yellow-dark;
    z-index: 2;
  }

  .cursor-bg {
    @include poa(15%, 5%, 15%, 2%);
    background: $grey-light;
    z-index: 1;
  }

  .nb-reviews {
    font-size: 1.2rem;
    margin-left: 0.5rem;
    padding-bottom: 0.1rem;
  }
}

.product-reviews-list {
  background: $grey-lighter;

  header {
    position: relative;

    @media (min-width: $sm-width) {
      background: $grey-lighter;
    }
  }

  .product-block-title {
    padding-bottom: 0;

    @media (min-width: $sm-width) {
      padding-bottom: 1rem;
    }
  }

  .netreviews-link {
    color: lighten($grey-medium, 30);
    display: inline-block;
    font-size: 1.2rem;
    padding: 0 1.5rem 1rem 1.5rem;
    text-decoration: underline;

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
      margin: 0;
    }

    .sceau {
      max-width: 11rem;
      position: absolute;
      top: 50%;
      transform: translate(-120%, -50%);
    }
  }

  .reviews-average {
    padding: 1rem 0 2rem 0;

    .stars .icon {
      color: $grey-lighter;
      font-size: 3rem;
    }

    .nb-reviews {
      color: $grey;
      font-size: 1.4rem;
    }
  }

  .review-ctn {
    @include animated(all, 0.7s, cubic-bezier(0, 0.33, 0, 1));
    background: $white;
    line-height: 1.8rem;
    margin-bottom: 2rem;
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    top: 3rem;
    transform: rotate(5deg);
    z-index: -1;

    @media (min-width: $sm-width) {
      padding: 3rem 4rem;
    }

    &.open {
      opacity: 1;
      position: relative;
      top: 0;
      transform: rotate(0);
      z-index: 2;
    }

    $i: 0;

    @while $i <= 10 {
      &:nth-child(#{$i + 1}) {
        transition-delay: 0.15s * $i;
      }
      $i: $i + 1;
    }

    .stars-label {
      color: $black;
      font-weight: 700;
    }

    .review-content {
      font-size: 1.2rem;
      line-height: 1.1;
      padding: 0.4rem 1.5rem 0 0;

      @media (min-width: $sm-width) {
        font-size: 1.4rem;
      }
    }

    .stars .icon {
      font-size: 2rem;

      @media (min-width: $sm-width) {
        font-size: 2.4rem;
      }
    }

    .reviews-details {
      color: $black;
      font-size: 1.2rem;
      line-height: 1.1;
      margin-top: 1.5rem;
    }
  }
}
