
.smart-popin {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto; /* scrollbar will appear if the viewport is too tight to display all the popin content */
  z-index: 1000;

  /* design */

  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  .sp-table {
    display: table;
    height: 100%;
    width: 100%;
  }

  .sp-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 10px; /* this space is important to let the box-shadow exceed around the popin - so it has to be at least equal to the shadow radius */
  }
  &#advgdprpopin-global{
    .sp-cell{
      vertical-align: bottom;
      padding: 0;
    }
    .sp-body{
      width: 100%;
      font-size: 0;
      .sp-body-block{
        font-size: 1.4rem;
        display: inline-block;
        width: 70%;
        margin: 0;
        padding: 25px;
        @media (max-width: $sm-width) {
          width: 100%;
          text-align: center;
        }
        .button{
          &#advgdprpopin-validate-all{
            padding: 8px 12px;
            background: #fffe00 !important;
            color: black;
            border: 1px solid #111;
            &:hover{
              background: #cfcd00;
            }
          }
        }
        &.right-block-content{
          width: 30%;
          text-align: right;
          @media (max-width: $sm-width) {
            width: 100%;
            text-align: center;
          }
        }
        .underline{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

  }

  .sp-body {
    position: relative; /* to allow absolute positionning inside */
    z-index: 1; /* to ensure the popin body will be over the .sp-back layer */
    width: auto; /* by default the width of the wider element inside */
    min-width: 300px; /* standard width for xs smartphones (320px) minus 2*10px (.sp-cell margins) */
    margin: 0 auto;

    /* design */
    background-color: #ffffff;
    padding: 2em;
    -webkit-box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.25);
    width: 90%;
    @media (min-width: $xs-width) {
      width: 80%;
    }
    @media (min-width: $sm-width) {
      width: 50%;
    }
  }

  .sp-body * {
    max-width: 100%; /* this is a security to prevents wide elements such as img to stretch the popin beyond the viewport width */
  }

  .sp-body-block {
    margin: 15px;

    .button{
      &#advgdprpopin-validate-all,
      &#advgdprpopin-validate{
        background: #fffe00 !important;
        color: black;
        border: 1px solid #111;
        &:hover{
          background: #cfcd00;
        }
      }
    }
    .button{
      margin-bottom: 20px;
      @media (min-width: $lg-width) {
        margin-bottom: 0;
      }
    }
  }
}


