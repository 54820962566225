%breadcrumb {
  background: $blue-dark;
  color: $white;
  margin-bottom: 1.5em;

  a {
    color: $white;
  }

  @media (min-width: $md-width) {
    margin-top: 1rem;
    position: relative;
    z-index: 2;
  }
}

.stores {
  .breadcrumb {
    @extend %breadcrumb;
  }

  &-header {
    background: $blue-dark;
    border-top: 0.1rem solid $white;
    padding: 1.5rem 0;
    position: relative;
    text-align: center;

    @media (min-width: $sm-width) {
      text-align: left;
    }

    &-title {
      @include heading($white, 2, 1.5, 900, false);
      text-align: left;

      @media (min-width: $sm-width) {
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
      }
      @media (min-width: $md-width) {
        font-size: 3rem;
      }
      @media (min-width: $lg-width) {
        font-size: 4rem;
      }
    }

    &-input {
      margin: 0 0 1rem 0;
      padding: 0;
      position: relative;

      @media (min-width: $md-width) {
        margin: 1rem 0 0 0;
      }

      form {
        position: relative;
      }
    }

    &-text,
    &-text:hover {
      color: $white;
      margin-bottom: 1rem;

      @media (min-width: $xs-width) {
        right: 1.5rem;
      }
    }
  }

  &-mea {
    background: $grey-lighter;
    padding: 2rem 0;

    @media (min-width: $md-width) {
      padding: 6rem 0;
    }

    img {
      margin-bottom: 2rem;
      max-width: 100%;

      @media (min-width: $md-width) {
        margin-bottom: 0;
      }
    }

    &-apply {
      background: $blue-dark;
      border-radius: 0.3rem;
      color: $white;
      font-size: 1.4rem;
      line-height: 1.5;
      margin-top: 4rem;
      padding: 2.5rem;
      text-align: center;

      @media (min-width: $sm-width) {
        text-align: left;
      }
      @media (min-width: $md-width) {
        font-size: 1.6rem;
        margin-bottom: 0;
        padding: 3rem;
      }

      .icon {
        color: $yellow;
        display: block;
        font-size: 3rem;
        margin-bottom: 1rem;

        @media (min-width: $sm-width) {
          font-size: 3.5rem;
          margin-bottom: 0;
        }
        @media (min-width: $md-width) {
          font-size: 4rem;
        }
      }

      &-title {
        @include heading($white, 1.8, 0.5, 900, false);
        line-height: 1.2;

        @media (min-width: $md-width) {
          font-size: 2.4rem;
          margin-bottom: 0;
        }
      }

      .button {
        margin-top: 1.5rem;
        padding-left: 5rem;
        padding-right: 5rem;

        @media (min-width: $sm-width) {
          margin-top: 0;
        }
      }
    }
  }
}

.store-event {
  background: $yellow;
  padding: 1rem;

  @media (min-width: $md-width) {
    padding: 1.5rem 2.5rem;
  }

  p {
    color: $blue-dark;
    font-size: 1.2rem;
    font-weight: 900;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}

@import "_store-landing.scss";
@import "_store-details.scss";
@import "_stores-list.scss";
