.category {
  .breadcrumb {
    padding-top: 1.5rem;
  }

  .container-img-top {
    display: flex;

    img {
      margin: auto;
    }
  }

  .anchor-link {
    color: inherit;
    font-weight: 700;
    line-height: 1;
  }

  .heading-counter {
    position: relative;
    right: 50%;
    text-transform: uppercase;
    width: 50%;
  }

  .more {
    font-size: 1.4rem;
    line-height: 2rem;
    padding-bottom: 10.5rem;

    .more-ctn {
      padding-top: 5rem;
    }
  }

  .page-heading-desc {
    a {
      color: $blue-dark;
      text-decoration: underline;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: 700;
    }
  }

  .choose-color {
    font-weight: 700;
  }

  .more-color {
    height: 25px;
    line-height: 25px;
    width: 34px;
  }

  .product-list-color {
    margin-bottom: 15px;
  }
}

.cat-videos {
  padding-bottom: 30px;

  @media (min-width: $md-width) {
    padding-bottom: 60px;
  }

  &-item {
    margin-bottom: 30px;
  }
}

.cat-video {
  @include poa(0, 0, 0, 0);
  height: 100%;
  width: 100%;

  &-ctn {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.heading-section {
  border-bottom: 0.1rem solid $grey-light;
  border-top: 0.1rem solid $grey-light;
  margin-bottom: 1.5rem;
  padding: 1.2rem 0;
  text-align: center;

  @media (min-width: $sm-width) {
    margin-bottom: 4.5rem;
    padding: 2.5rem 0;
  }

  .ctn {
    @media (min-width: $lg-width) {
      padding: 0 32rem;
    }
  }

  h2 {
    @include heading($grey-medium, 1.8, 0, 700, true);
    line-height: 2.4rem;

    @media (min-width: $sm-width) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  h2 + p {
    margin: 0.5rem 0 2rem 0;

    @media (min-width: $lg-width) {
      margin: 1.5rem 0 0 0;
    }
  }

  .btn {
    @extend .button;

    @media (min-width: $lg-width) {
      @include poa(50%, 1.5rem);
      max-width: 30rem;
      text-transform: none;
      transform: translateY(-50%);
    }
  }
}
