$gris_fonce_ant: #494949;

#ant_tooltipInterval {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  & > span {
    background-color: $gris_fonce_ant;
    border-radius: 10px;
    color: white;
    display: block;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    min-width: 20px;
    text-align: center;
    width: 20px;
  }

  & > div {
    display: none;
  }
}

.promoOP {
  left: -2rem;
  position: absolute;
  top: -2rem;
  width: 150px;
}

.ope30OldPrice {
  color: $blue-dark;
  font-size: 16px;
  font-weight: bold;
}

.price-after-op30 {
  color: $blue-dark;
}

.price_reduc {
  position: relative;
}

.price_reduc::before {
  bottom: 2px;
  color: $blue-dark;
  content: "Après \A réduction";
  display: block;
  font-size: 10px;
  font-weight: 100;
  left: -55px;
  position: absolute;
  text-align: right;
  white-space: pre-wrap;
  width: 50px;

  @media (min-width: $sm-width) {
    left: -60px;
  }
}
