.alert {
  border: 0;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding: 1rem 1rem;

  @media (min-width: $md-width) {
    padding: 1.5rem 3rem;
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li {
    list-style: disc;
  }

  &-info {
    background: $blue;
    color: $white;
  }

  &-success {
    background: $green;
    color: $white;
  }

  &-warning {
    background: $grey-light;
    color: $grey;
  }

  &-danger {
    background: $orange;
    color: $white;
  }
}

.message-info {
  background: transparentize(#45c654, 0.8);
  border-radius: 0.4rem;
  color: #45c654;
  margin-bottom: 1.5rem;
  padding: 1rem 1rem;

  @media (min-width: $md-width) {
    padding: 1.5rem 3rem;
  }
}

.success-info {
  @include animated(padding, 0.3s, ease-in-out, 0.3s);
  background: $green;
  color: $white;
  font-size: 1.6rem;
  font-weight: 700;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;

  @media (min-width: $md-width) {
    border-radius: 0.3rem;
    padding: 3rem 7.5rem;
  }

  .txt {
    position: relative;
    z-index: 2;
  }

  .circle-animate {
    @include poa(50%, null, null, 0.5rem);
    @include size(5rem);
    background: $green;
    border-radius: 50%;
    box-shadow: 2.5rem -2.5rem 0 0.2rem $green,
    -2.5rem -2.5rem 0 0.2rem $green,
    -2.5rem 2.5rem 0 0.2rem $green,
    2.5rem 2.5rem 0 0.2rem $green,
    0 0 0 0.2rem $green;
    display: inline-block;
    font-size: 2rem;
    line-height: 5.1rem;
    text-align: center;
    transform: translateY(-50%) scale(0.5);
    z-index: 1;

    @media (min-width: $md-width) {
      left: 1.5rem;
      transform: translateY(-50%) scale(1);
    }

    .icon {
      @include animated(all, 0.2s, ease, 0.2s);
      opacity: 0;
      position: relative;
      transform: scale(0);
    }
  }

  @keyframes border {
    0% {
      box-shadow: 2.5rem -2.5rem 0 0.2rem $green,
      -2.5rem -2.5rem 0 0.2rem $green,
      -2.5rem 2.5rem 0 0.2rem $green,
      2.5rem 2.5rem 0 0.2rem $green,
      0 0 0 0.2rem $green;
    }

    25% {
      box-shadow: 0 -8rem 0 0.2rem $green,
      -2.5rem -2.5rem 0 0.2rem $green,
      -2.5rem 2.5rem 0 0.2rem $green,
      2.5rem 2.5rem 0 0.2rem $green,
      0 0 0 0.2rem $white;
    }

    50% {
      box-shadow: 0 -8rem 0 0.2rem $green,
      -8rem 0 0 0.2rem $green,
      -2.5rem 2.5rem 0 0.2rem $green,
      2.5rem 2.5rem 0 0.2rem $green,
      0 0 0 0.2rem $white;
    }

    75% {
      box-shadow: 0 -8rem 0 0.2rem $green,
      -8rem 0 0 0.2rem $green,
      0 8rem 0 0.2rem $green,
      2.5rem 2.5rem 0 0.2rem $green,
      0 0 0 0.2rem $white;
    }

    100% {
      box-shadow: 0 -8rem 0 0.2rem $green,
      -8rem 0 0 0.2rem $green,
      0 8rem 0 0.2rem $green,
      8rem 2rem 0 0.2rem $green,
      0 0 0 0.2rem $white;
    }
  }
}

.open {
  .success-info {
    padding-left: 6rem;

    @media (min-width: $md-width) {
      padding-left: 8.5rem;
    }

    .circle-animate {
      animation: border 0.2s ease-in 0.5s forwards;

      .icon {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
