.advmanufacturer {
  &-home {
    .advmanufacturer-item {
      margin-bottom: 2rem;
      min-width: 10rem;
      padding: 0;

      @media (min-width: $md-width) {
        min-width: 12rem;
      }
      @media (min-width: $lg-width) {
        min-width: unset;
      }
    }

    .advmanufacturer-link {
      .advmanufacturer-img {
        @include animated();
        filter: grayscale(1);
        max-width: 100%;
        opacity: 0.5;
      }

      &:hover .advmanufacturer-img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}
