.advcms-list {
  padding: 45px 0;

  .advcms-list-item {
    @include animated(all, 0.15s, ease-out);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0);
    margin-bottom: 15px;
    position: relative;
    top: 0;

    @media (min-width: $md-width) {
      margin-bottom: 30px;
    }

    .inner {
      border: 1px solid $grey-light;
    }

    .advcms-list-item-img {
      img {
        width: 100%;
      }
    }

    .advcms-list-content {
      color: $blue-dark;
      padding: 15px;

      @media (min-width: $md-width) {
        padding: 30px 25px;
      }

      .advcms-list-item-title {
        font-size: 15px;
        font-weight: 900;
        margin-top: 0;

        @media (min-width: $sm-width) {
          font-size: 17px;
        }
        @media (min-width: $md-width) {
          font-size: 18px;
        }

        .referal {
          color: inherit;
        }
      }

      .advcms-list-item-resume {
        font-size: 13px;

        @media (min-width: $sm-width) {
          font-size: 15px;
        }
        @media (min-width: $md-width) {
          font-size: 16px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      top: -5px;
    }
  }
}
