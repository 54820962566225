.advcms-content {
  .advcms-header {
    padding-top: 1.5rem;

    .bcrumb {
      color: inherit;
      display: inline-block;
      padding: 15px;

      .icon {
        font-family: "icomoon";
        font-size: 10px;
      }

      span {
        font-size: 12px;
      }
    }

    .advcms-title {
      font-weight: 900;
      padding-bottom: 20px;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 25px;
      }
      @media (min-width: $sm-width) {
        font-size: 30px;
        padding-bottom: 25px;
      }
      @media (min-width: $md-width) {
        font-size: 40px;
        margin: 0;
        padding-bottom: 37px;
      }
    }

    .advcms-excerpt-ctn {
      background: $white;
      color: $black;
      font-size: 14px;
      padding-top: 20px;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 15px;
      }
      @media (min-width: $sm-width) {
        font-size: 18px;
        padding-top: 25px;
      }
      @media (min-width: $md-width) {
        font-size: 20px;
        padding-top: 35px;
      }

      .advcms-excerpt {
        padding: 0;

        @media (min-width: $md-width) {
          margin-bottom: 30px;
          padding: 0 10%;
        }
      }
    }
  }
}
