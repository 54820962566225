.popover {
  font-family: $muli;
  padding-right: 2rem;
  position: relative;

  .popover-trigger {
    @include poa(50%, 0);
    cursor: pointer;
    margin-top: -0.7rem;

    &.top {
      margin-top: 0;
      top: 0.5rem;
    }

    .icon {
      color: $grey;
      content: "\e906";
      font-family: "icomoon";
      line-height: 1;
    }

    .popover-content {
      display: none;
    }
  }

  &.right {
    .popover-content {
      @include poa(null, 0.6rem, calc(100% + 0.7rem));
      transform: translateX(-100%);

      &::before {
        @include poa(null, 0, -0.5rem, auto);
        @include size(0);
        border-color: transparent $blue transparent transparent;
        border-style: solid;
        border-width: 0.6rem 0.6rem 0.6rem 0;
        content: "";
      }
    }
  }
}
