.advconstructor-home {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.5rem;
  text-decoration: none;

  .category & {
    justify-content: flex-start;
    text-align: center;

    img {
      margin-bottom: 15px;
    }
  }

  &.grey {
    background: $grey-lighter;

    .stars .icon {
      color: $grey-lighter;
    }
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: $xs-width) {
    padding: 2rem;
  }
  @media screen and (min-width: $md-width) {
    padding: 1rem;
  }
  @media screen and (min-width: $lg-width) {
    padding: 2rem;
  }

  img {
    max-width: 100%;
  }

  &_ctn {
    text-align: center;

    &-title {
      @include heading($blue-dark, 2.4, 1.5, true, false);
      font-weight: 900;
    }

    .line > div {
      margin-top: 1.5rem;

      @media screen and (min-width: $sm-width) {
        margin-top: 2rem;
      }
      @media screen and (min-width: $md-width) {
        margin-top: 3rem;
      }
    }

    .md2 {
      flex-grow: 1;
    }

    .md3 {
      flex-grow: 2;
    }

    .md5 {
      flex-grow: 3;
    }
  }

  &-dark {
    background-color: $blue-dark;

    .icon {
      color: $yellow;
      font-size: 3rem;
    }
  }

  &-dark,
  &-thanks {
    &,
    a {
      color: $white;
    }
  }

  &-thanks {
    &,
    h4 {
      color: #000;
    }

    a {
      color: #000;
    }

    img {
      align-self: flex-end;
      margin-right: -0.5rem;

      @media screen and (min-width: $xs-width) {
        margin-right: -2rem;
      }
    }
  }

  &-light {
    background-color: $white;

    &,
    a {
      color: $yellow-dark;
    }
  }

  &_title {
    @include heading($white, 2.4, 0.5, true, false);
    font-weight: 900;
    margin-top: 1rem;
  }

  &_subtitle,
  &_phone {
    font-weight: 700;
  }

  &_phone {
    display: block;
    font-size: 2.4rem;
    margin: 2rem 0 1rem;
  }

  em {
    display: block;
    font-size: 1.3rem;
    font-style: italic;
    margin: 1.5rem 0 0;
  }

  &_content {
    a {
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 0.2rem;
      display: inline-block;
      margin: 1rem 0;
      padding: 1rem 1.5rem 1.1rem;
      transition: 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        text-decoration: none;
      }
    }
  }

  &-thanks {
    background-color: #f9f3f1;
  }

  &-store {
    width: 100%;

    .advconstructor-home_content a {
      margin-top: 4.2rem;
    }
  }

  &_bg {
    background-position: center;
    background-size: cover;
    width: 100%;

    @media screen and (min-width: $xs-width) {
      display: block;
    }
  }

  &.product-reviews {
    color: #000;
  }
}

.home-constructor {
  &-tabs {
    margin-bottom: 3rem;
    position: relative;

    &::before {
      @include poa(50%, 20%, 0, 20%);
      border-radius: 50%;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
      content: "";
      z-index: 1;
    }
  }

  &-list {
    background: $grey-lighter;
    position: relative;
    z-index: 2;
  }

  &-tab {
    padding: 0 1.5rem;

    @media (min-width: $sm-width) {
      padding: 0 3rem;
    }
  }

  &-link {
    color: $grey;
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem 0 1.3rem 0;
    position: relative;

    @media (min-width: $sm-width) {
      font-size: 1.8rem;
    }

    &::before {
      @include animated();
      @include poa(null, 50%, 0, 50%);
      background: $grey-lighter;
      content: "";
      height: 0.3rem;
    }

    &.active::before {
      background: $blue-dark;
      left: 0;
      right: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-block {
    display: none;

    &.active {
      display: block;
    }
  }
}

.reviews-rate {
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 10px;
}
