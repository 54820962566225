.shopping-cart-product {
  background: $white;
  border-bottom: 0.1rem solid $grey-light;
  margin: 1rem -0.75rem 0 -0.75rem;
  padding: 0 1.5rem 2rem 1.5rem;

  @media (min-width: $xs-width) {
    padding: 0 0.75rem 2rem 0.75rem;
  }
  @media (min-width: $sm-width) {
    border: 0.1rem solid $grey-light;
    border-radius: 0.3rem;
    margin: 2.5rem 0 0 0;
    padding: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &-img-ctn {
    order: 2;
    padding: 0;
    position: relative;
    width: 7.5rem;

    @media (min-width: $md-width) {
      order: 1;
      padding: 0 2rem 0 0;
      width: 10rem;
    }
    @media (min-width: $lg-width) {
      padding: 0 3rem 0 0;
      width: 14rem;
    }
  }

  &-infos {
    color: $blue-dark;
    flex-basis: 0;
    flex-grow: 1.3;
    margin-top: 0.5rem;
    max-width: 100%;
    order: 1;

    @media (min-width: $sm-width) {
    }
    @media (min-width: $md-width) {
      order: 2;
      padding: 0 1rem 0 0;
    }
    @media (min-width: $xl-width) {
      flex-grow: 1.5;
      padding: 0 2rem 0 0;
    }
  }

  &-name {
    color: inherit;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.1;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-attributes {
    font-size: 1.2rem;
    margin-top: 0.5rem;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
      margin-top: 0.8rem;
    }
  }

  &-topfeatures {
    margin-top: 1.2rem;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  }

  &-qty {
    position: relative;

    .cart_quantity_input {
      background: transparent;
      border: 0.1rem solid $grey-light;
      border-radius: 0.3rem;
      color: $grey-medium;
      height: 4rem;
      padding-right: 2.5rem;
      text-align: center;
      width: 7rem;
    }

    .cart_quantity {
      &_button {
        @include poa(0, 0, 0, 4.5rem);
        border-left: 0.1rem solid $grey-light;
        font-size: 0.5rem;
        text-align: center;
      }

      &_up,
      &_down {
        border-bottom: 0.1rem solid $grey-light;
        color: $grey;
        display: block;
        height: 50%;
        line-height: 2.2rem;
        opacity: 0.6;
      }
    }
  }

  &-prices {
    align-items: center;
    background: $grey-lighter;
    border-radius: 0.2rem;
    flex-basis: 100%;
    order: 4;
    padding: 1rem 4rem 1rem 1rem;
    position: relative;
    text-align: right;

    @media (min-width: $md-width) {
      background: transparent;
      flex-basis: 0;
      flex-grow: 1.8;
      order: 3;
      padding: 0 2.5rem 0 0;
    }
    @media (min-width: $lg-width) {
      flex-grow: 1.4;
    }

    &-label {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 1rem;
      min-width: 100%;

      @media (min-width: $md-width) {
        display: none;
      }
    }
  }

  &-unitprice {
    font-size: 1.1rem;
    padding: 0 1rem 0 0;
    text-align: left;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
    }
    @media (min-width: $lg-width) {
      padding: 0 1.5rem 0 0;
    }

    .price {
      display: block;
      font-size: 1.4rem;
      font-weight: 900;
      line-height: 1;
      white-space: nowrap;

      @media (min-width: $md-width) {
        font-size: 1.8rem;
      }
    }
  }

  &-current-price {
    color: $red;
    display: block;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1;
    margin-left: 1rem;
    white-space: nowrap;

    @media (min-width: $md-width) {
      display: block;
      font-size: 1.8rem;
      width: 8.5rem;
    }
  }

  &-old-price {
    color: $grey-medium;
    display: inline-block;
    font-size: 1.1rem;

    @media (min-width: $md-width) {
      display: block;
      font-size: 1.3rem;
    }
  }

  &-remove-ctn {
    @include poa(65%, 1.5rem);
    color: $grey-medium;
    cursor: pointer;
    font-size: 1.2rem;
    opacity: 0.5;
    transform: translateY(0);
    z-index: 3;

    @media (min-width: $md-width) {
      right: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-availability-ctn {
    flex-basis: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    order: 3;
    padding: 1rem 0 1.2rem 0;

    @media (min-width: $md-width) {
      font-size: 1.3rem;
      order: 4;
      padding: 0 0 0 10rem;
    }
    @media (min-width: $lg-width) {
      padding: 0 0 0 14rem;
    }
  }
}
