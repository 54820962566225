.page-heading.manufacturer {
  .inner {
    padding: 0 0 15px;
  }

  .more {
    color: $blue-dark;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: $grey;
    }
  }

  .page-heading-title {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
  }
}
