.custom-select {
  display: flex;
  flex-grow: 2;
  flex-wrap: wrap;
  position: relative;

  > label {
    color: $grey-medium;
    display: inline-block;
    font-size: 1.4rem;
    width: 100%;

    @media (min-width: $md-width) {
      width: 14.5rem;
    }
  }

  &.label-in > label {
    display: none;
  }

  > span {
    background-color: $white;
    border: 1px solid $grey-light;
    border-radius: 0.3rem;
    color: $grey-medium;
    cursor: pointer;
    display: block;
    flex-grow: 2;
    font-size: 14px;
    height: 44px;
    line-height: 42px;
    max-width: 100%;
    overflow: hidden;
    padding: 0 40px 0 15px;
    position: relative;
    text-overflow: ellipsis;
    white-space: wrap;
    z-index: 1;

    &::after {
      @include animated(transform);
      @include poa(50%, 15px);
      color: $grey-medium;
      content: "\e915";
      cursor: pointer;
      display: block;
      font-family: "icomoon";
      font-size: 5px;
      font-style: normal;
      line-height: 1;
      margin-top: -3px;
      opacity: 1;
      transform-origin: 50% 50% 0;
    }

    &.open {
      border-color: $grey;

      &::after {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }

  > ul {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: none;
    margin: 5px 0 0 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 3px 0;
    position: absolute;
    z-index: 10;

    li {
      background: $white;
      cursor: pointer;
      font-size: 14px;
      padding: 12px 10px;

      input {
        height: 30px;
        width: 100%;
      }

      &:hover,
      &.selected {
        background-color: $grey-lighter;
      }
    }

    &.open {
      display: block;
    }
  }

  &.disabled > span {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.disabled > label .label {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & > select {
    display: none;
  }
}
