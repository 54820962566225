.flag {
  background: $blue-dark;
  border-radius: 3rem;
  color: $white;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  padding: 0.5rem 1.4rem 0.7rem 1.4rem;

  @media (min-width: $sm-width) {
    font-size: 1.3rem;
    padding: 0.6rem 1.2rem 0.8rem 1.2rem;
  }
}
