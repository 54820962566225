.footer-main {
  .accordion {
    .site-reviews-widget {
      margin-top: -1.5rem;
      min-height: 130px;
      padding-left: 15px;

      @media (min-width: $sm-width) {
        padding-left: 0;
      }
      @media (min-width: $md-width) {
        margin-top: -1rem;
        min-height: 120px;
      }
    }
  }
}

.site-reviews-text {
  font-weight: bold;
  padding-right: 1.5rem;

  @media (min-width: $xs-width) {
    padding-right: 0;
  }

  @media (min-width: $md-width) {
    padding-right: 20%;
  }

  a {
    text-decoration: underline;
  }
}

