.account {
  &-inner {
    flex-basis: 100%;
    margin-bottom: 10rem;
  }

  &-content {
    margin-bottom: 60px;

    &.width-limited {
      margin-left: 10px;
      margin-right: 10px;

      @media (min-width: $md-width) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .inner {
      width: 100%;
    }
  }

  &-bloc {
    strong,
    &-content-title {
      color: $black;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }

  &-info {
    color: $black;
    font-size: 1.4rem;
    margin-bottom: 30px;
    text-align: center;

    @media (min-width: $xs-width) {
      font-size: 1.5rem;
    }
    @media (min-width: $sm-width) {
      font-size: 1.7rem;
    }
    @media (min-width: $md-width) {
      font-size: 1.8rem;
    }
  }

  &-list-ctn {
    + .account-list-ctn {
      margin-top: 1.5rem;

      @media (min-width: $md-width) {
        margin-top: 3rem;
      }
    }
  }

  &-list-item {
    @include animated();
    background: $grey-lighter;
    border: 1px solid $grey-light;
    border-radius: 3px;
    display: block;
    line-height: 1.5;
    padding: 15px;
    position: relative;

    @media (min-width: $md-width) {
      padding: 20px 25px;
    }

    + .account-list-item {
      margin-top: 1.5rem;

      @media (min-width: $md-width) {
        margin-top: 3rem;
      }
    }

    > span,
    > a {
      color: $blue-dark;
      display: block;
      font-size: 1.4rem;
      font-weight: 700;
      margin-right: 30px;

      @media (min-width: $sm-width) {
        font-size: 1.5rem;
      }
      @media (min-width: $md-width) {
        display: inline-block;
        white-space: nowrap;
      }

      .account-list-value-color {
        color: $blue;
      }
    }
  }

  &-link-list {
    text-align: center;

    li {
      a {
        border: 1px solid $grey-light;
        display: block;
        padding: 30px 10px;

        .account-link-icon {
          background: $yellow;
          border-radius: 70px;
          display: inline-block;
          height: 70px;
          margin-bottom: 10px;
          position: relative;
          width: 70px;

          .icon {
            color: $black;
            font-size: 3rem;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .account-link-title {
          color: $blue-dark;
          display: block;
          font-size: 2.2rem;
          font-weight: 900;
          margin-bottom: 10px;
        }

        .account-link-info {
          color: $black;
          font-size: 1.3rem;
          letter-spacing: 0.2px;
        }

        &:hover {
          border-color: $grey-medium;
          text-decoration: none;
        }
      }
    }
  }
}

@import "_menu.scss";
@import "_pages.scss";
